import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Draft, DraftPatch, QueryParameter} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

class DraftsService extends ApiService {
  constructor(authService: AuthService) {
    super(authService);
  }

  async getDrafts(params: QueryParameter): Promise<ApiListResponse> {
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    return this.get(config(companyId).endpoints.drafts, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateDraft(companyId: string, orderId: string, data: DraftPatch): Promise<Draft> {
    return this.patch(`${config(companyId).endpoints.drafts}/${orderId}`, data)
      .then(data => {
        return Promise.resolve(data as Draft);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  async createDraft(companyId: string, data: Draft): Promise<Draft> {
    return this.post(`${config(companyId).endpoints.drafts}`, data)
      .then(data => {
        return Promise.resolve(data as Draft);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteDraft(companyId: string, draftId: string): Promise<Draft> {
    return this.delete(`${config(companyId).endpoints.drafts}/${draftId}`)
      .then(data => {
        return Promise.resolve(data as Draft);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default DraftsService;
