import React from 'react';
import {AdditionalItemType, FixingRequiredType, InstallationInfo} from 'two-core';
import {InputNumber} from 'primereact/inputnumber';
import {InputSwitch} from 'primereact/inputswitch';
import {InputTextarea} from 'primereact/inputtextarea';

interface Props {
  installInfo?: InstallationInfo;
  onInstallationInfoChange: (fieldsToUpdate: Partial<InstallationInfo>) => void;
  readOnly: boolean;
}

const fixingsRequiredOptions: FixingRequiredType[] = [
  'Timber',
  'Brick',
  'Concrete',
  'Metal',
  'Steel',
  'Tile',
  'Stucco',
  'Granite',
  'Drywall',
];

const additionalItemOptions: AdditionalItemType[] = ['Tall Ladder', 'Scaffold', 'Cherry Picker'];

export const InstallationInfoComponent = ({installInfo, onInstallationInfoChange, readOnly}: Props) => {
  const onFieldChange = (name: keyof InstallationInfo, value: string | number | string[] | null) => {
    onInstallationInfoChange({[name]: value});
  };
  const onMultiInputSwitchChange = (
    name: 'fixings_required' | 'additional_items',
    checked: boolean,
    newValue: FixingRequiredType | AdditionalItemType
  ) => {
    let values: string[] = [...(installInfo?.[name] ?? [])];
    if (checked) {
      values.push(newValue);
    } else {
      values = values.filter(value => value !== newValue);
    }
    onFieldChange(name, values);
  };

  const labelClasses = 'p-col-2';
  const valueClasses = 'p-col-10';
  const timeRequired = installInfo?.time_required;
  const fittersRequired = installInfo?.fitters_required;
  const offsidersRequired = installInfo?.offsiders_required;
  const fixingsRequired = installInfo?.fixings_required;
  const additionalItems = installInfo?.additional_items;
  const note = installInfo?.note;
  return (
    <div className="p-mt-5">
      {(timeRequired || !readOnly) && (
        <div className="p-grid">
          <label className={labelClasses}>
            <small>duration (h)</small>
          </label>
          <div className={valueClasses}>
            {readOnly ? (
              timeRequired
            ) : (
              <InputNumber
                min={0}
                step={1}
                value={timeRequired}
                showButtons
                disabled={readOnly}
                onChange={e => onFieldChange('time_required', e.value)}
              />
            )}
          </div>
        </div>
      )}
      {(fittersRequired || !readOnly) && (
        <div className="p-grid">
          <label className={labelClasses}>
            <small># fitters</small>
          </label>
          <div className={valueClasses}>
            {readOnly ? (
              fittersRequired
            ) : (
              <InputNumber
                min={0}
                step={1}
                value={fittersRequired}
                showButtons
                onChange={e => onFieldChange('fitters_required', e.value)}
              />
            )}
          </div>
        </div>
      )}
      {(offsidersRequired || !readOnly) && (
        <div className="p-grid">
          <label className={labelClasses}>
            <small># off-siders</small>
          </label>
          <div className={valueClasses}>
            {readOnly ? (
              offsidersRequired
            ) : (
              <InputNumber
                min={0}
                step={1}
                value={offsidersRequired}
                showButtons
                onChange={e => onFieldChange('offsiders_required', e.value)}
              />
            )}
          </div>
        </div>
      )}
      {(fixingsRequired?.length || !readOnly) && (
        <div className="p-grid">
          <label className={labelClasses}>
            <small>fixing into</small>
          </label>
          <div className={valueClasses}>
            {readOnly ? (
              fixingsRequired?.join(', ')
            ) : (
              <div className="p-field p-grid p-m-0">
                {fixingsRequiredOptions.map(option => (
                  <>
                    <label className="p-col-3 p-md-2 p-mb-0 p-text-lowercase">
                      <small>{option}</small>
                    </label>
                    <div className="p-col-3 p-md-2">
                      <InputSwitch
                        name={option}
                        checked={fixingsRequired?.includes(option)}
                        onChange={e => onMultiInputSwitchChange('fixings_required', e.value, option)}
                        disabled={readOnly}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {(additionalItems?.length || !readOnly) && (
        <div className="p-grid">
          <label className={labelClasses}>
            <small>additional tools</small>
          </label>
          <div className={valueClasses}>
            {readOnly ? (
              additionalItems?.join(', ')
            ) : (
              <div className="p-field p-grid p-m-0">
                {additionalItemOptions.map(option => (
                  <>
                    <label className="p-col-3 p-md-2 p-mb-0 p-text-lowercase">
                      <small>{option}</small>
                    </label>
                    <div className="p-col-3 p-md-2">
                      <InputSwitch
                        name={option}
                        checked={additionalItems?.includes(option)}
                        onChange={e => onMultiInputSwitchChange('additional_items', e.value, option)}
                        disabled={readOnly}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {(note || !readOnly) && (
        <div className="p-grid">
          <label className={labelClasses}>
            <small>installation note</small>
          </label>
          <div className={valueClasses}>
            {readOnly ? (
              note
            ) : (
              <InputTextarea className="w-100" value={note} onChange={e => onFieldChange('note', e.target.value)} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
