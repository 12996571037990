import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, OrderPatch, PaOrder, PaOrderPatch, QueryParameter} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

class OrdersService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async getOrders(params: QueryParameter): Promise<ApiListResponse> {
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    return this.get(config(companyId).endpoints.orders, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateOrder(companyId: string, orderId: string, data: PaOrderPatch | OrderPatch): Promise<PaOrder> {
    return this.patch(`${config(companyId).endpoints.orders}/${orderId}`, data)
      .then(data => {
        return Promise.resolve(data as PaOrder);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  async createOrder(companyId: string, data: PaOrder): Promise<PaOrder> {
    return this.post(`${config(companyId).endpoints.orders}`, data)
      .then(data => {
        return Promise.resolve(data as PaOrder);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  async deleteOrder(companyId: string, orderId: string): Promise<PaOrder> {
    return this.delete(`${config(companyId).endpoints.orders}/${orderId}`)
      .then(data => {
        return Promise.resolve(data as PaOrder);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default OrdersService;
