import React from 'react';
import {Button} from 'primereact/button';
import {TwoDialog} from 'two-app-ui';

interface Props {
  showDialog: boolean;
  onNo: () => void;
  onYes: () => void;
  loading: boolean;
}

export const CancelJobDialog = ({showDialog, onNo, onYes, loading}: Props) => {
  const footer = (
    <div className={'p-d-flex p-justify-end'}>
      <Button label="No" className={'p-mr-2 p-button-text'} onClick={onNo} disabled={loading} />
      <Button label="Yes" onClick={onYes} loading={loading} />
    </div>
  );
  return (
    <TwoDialog
      header={'Cancel Job'}
      showDialog={showDialog}
      style={{width: '50vw'}}
      breakpoints={{'768px': '80vw', '576px': '95vw'}}
      onHide={() => {}}
      loading={false}
      footer={footer}
    >
      <div className="p-d-flex w-100">
        <span>
          This action will cancel the Job with all it’s appointments and documents. Are you sure you want to continue?
        </span>
      </div>
    </TwoDialog>
  );
};
