import {Button} from 'primereact/button';
import React, {PropsWithChildren} from 'react';
import {TwoDialog} from 'two-app-ui';

interface Props {
  showDialog: boolean;
  transitioning: boolean;
  jobName: string;
  fromStage: string;
  toStage: string;
  onCancel: () => void;
  onYes: () => void;
}

export const StageTransitionDialog = ({
  showDialog,
  transitioning,
  jobName,
  fromStage,
  toStage,
  onYes,
  onCancel,
}: PropsWithChildren<Props>) => {
  const footer = (
    <div className={'p-d-flex p-justify-end'}>
      <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={onCancel} disabled={transitioning} />
      <Button label="Transition" onClick={onYes} loading={transitioning} />
    </div>
  );
  const content = `Are you sure you want to move the job ${jobName} from stage ${fromStage} to stage ${toStage} ? Please note, this action might cause stage changes in related job documents and appointments.`;
  return (
    <TwoDialog
      header={`Job Stage Transition for ${jobName}`}
      loading={false}
      showDialog={showDialog}
      style={{width: '50vw'}}
      breakpoints={{'768px': '75vw', '576px': '90vw'}}
      onHide={onCancel}
      footer={footer}
    >
      {content}
    </TwoDialog>
  );
};
