import React from 'react';
import {PaCompany} from 'two-core';
import {Divider} from 'primereact/divider';

interface Props {
  company: PaCompany;
}

class CompanyDetail extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const company = this.props.company;

    return (
      <div className={'p-d-flex p-flex-column'}>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="name" className="p-col-12 p-md-3">
            entity name
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.name ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="trading_as" className="p-col-12 p-md-3">
            trading as
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.trading_as ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="account_number" className="p-col-12 p-md-3">
            acc number
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.account_number ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="business_entity" className="p-col-12 p-md-3">
            entity type
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.business_entity ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="tax_id" className="p-col-12 p-md-3">
            tax id
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.tax_id ?? ''}</span>
          </div>
        </div>

        <Divider />

        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="phone_number" className="p-col-12 p-md-3">
            phone
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.phone_number ?? ''}</span>
          </div>
        </div>

        <div className="p-d-flex p-ai-center">
          <label htmlFor="accounts_emails" className="p-col-12 p-md-3">
            accounts emails
          </label>
          <div className="p-col-12 p-md-9">{company.accounts_emails ?? ''}</div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="delivery_emails" className="p-col-12 p-md-3">
            delivery emails
          </label>
          <div className="p-col-12 p-md-9">{company.delivery_emails ?? ''}</div>
        </div>

        <Divider />

        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="billing_address" className="p-col-12 p-md-3">
            billing address
          </label>
          <div className="p-col-12 p-md-9">
            <span>
              {company.billing_address &&
                `${company.billing_address.country ?? ''}, ${company.billing_address.street ?? ''}, ${
                  company.billing_address.suburb ?? ''
                }, ${company.billing_address.state ?? ''}`}
            </span>
          </div>
        </div>

        <Divider />

        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="sales_rep" className="p-col-12 p-md-3">
            sales rep
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.sales_rep ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.stage ?? ''}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyDetail;
