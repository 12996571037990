import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog, TwoSpeedDial, TwoSpeedDialItem} from 'two-app-ui';
import {
  CheckMeasureOriginalContent,
  ConsultOriginalContent,
  DcmContent,
  Field,
  JobDocument,
  JobDocumentPatch,
  OrderItem,
  OriginalContent,
  PaProductDefinition,
} from 'two-core';
import ProductsService from '../../../services/ProductsService';
import JobDocumentsService from '../../../services/JobDocumentsService';
import OrdersService from '../../../services/OrdersService';
import JobsService from '../../../services/JobsService';
import {Toast} from 'primereact/toast';
import {DcmContentComponent} from './DcmContentComponent';
import {messages} from '../../../config/messages';
import {faArrowsMaximize, faArrowsMinimize, faCheck, faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons';
import {MenuItem} from 'primereact/menuitem';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  dcm: JobDocument;
  original?: JobDocument;
  dcmProdDefs?: PaProductDefinition[];
  originalProdDefs?: PaProductDefinition[];
}

interface State {
  saving?: boolean;
  clonedItems?: OrderItem[];
  invalidItemMessagesMap?: Map<number, string[]>;
  collapsedItemIndexes: Set<number>;
  dcmPatch: JobDocumentPatch;
  comparisonMode: boolean;
  showInvalidFields: boolean;
}

export class EditDcmDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  toastService?: ToastService;
  productsService?: ProductsService;
  jobDocumentsService?: JobDocumentsService;
  ordersService?: OrdersService;
  jobsService?: JobsService;

  constructor(props: Props) {
    super(props);

    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.setInvalidItemMessagesMap = this.setInvalidItemMessagesMap.bind(this);
    this.onItemsChange = this.onItemsChange.bind(this);
    this.onSaveDcmClick = this.onSaveDcmClick.bind(this);
    this.validate = this.validate.bind(this);
    this.onDcmContentChange = this.onDcmContentChange.bind(this);
    this.getSpeedDialItems = this.getSpeedDialItems.bind(this);
    this.onComparisonModeToggle = this.onComparisonModeToggle.bind(this);
    this.onItemsCollapse = this.onItemsCollapse.bind(this);
    this.onItemToggle = this.onItemToggle.bind(this);

    this.state = {
      comparisonMode: false,
      invalidItemMessagesMap: new Map<number, string[]>(),
      collapsedItemIndexes: new Set<number>(),
      dcmPatch: {},
      showInvalidFields: false,
    };
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.productsService = this.context.productsService;
    this.jobDocumentsService = this.context.jobDocumentsService;
    this.ordersService = this.context.ordersService;
    this.jobsService = this.context.jobsService;
  }

  async updateJobDocument(dcmId: string, dcmPatch: JobDocumentPatch) {
    if (Object.keys(dcmPatch).length) {
      this.setState({saving: true});
      return this.jobDocumentsService
        ?.updateJobDocument(dcmId, dcmPatch)
        .then((dcm: JobDocument) => {
          return dcm;
        })
        .catch(error => {
          return error;
        })
        .finally(() => {
          this.setState({saving: false});
        });
    }
  }

  setInvalidItemMessagesMap(newInvalidItemMessagesMap: Map<number, string[]>) {
    this.setState({
      invalidItemMessagesMap: newInvalidItemMessagesMap,
    });
  }

  cloneItems(items: OrderItem[]) {
    const clonedItems = (structuredClone(items) as OrderItem[]).map(item => {
      const fields: Field[] = item.fields.map(field => {
        //we must convert field to class object
        const values = field.values.map(fieldValue => {
          if (fieldValue.sub_fields) {
            fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
              //we must convert subfield to class object
              return new Field(subField);
            });
          }
          return fieldValue;
        });
        return new Field({...field, values: values});
      });
      item.fields = fields;
      return new OrderItem(item);
    });
    return clonedItems;
  }

  validate(): boolean {
    const {dcm} = this.props;
    const {dcmPatch, invalidItemMessagesMap} = this.state;
    const dcmContent = (dcmPatch.content ?? dcm?.content) as DcmContent;

    const errors: string[] = [];
    const itemsErrors = [];
    if (!dcmContent.items?.length) {
      errors.push('DCM must have at least one item.');
    }
    if (invalidItemMessagesMap?.size) {
      for (const [itemIndex, messages] of Array.from(invalidItemMessagesMap.entries())) {
        itemsErrors.push(
          <div>
            Item {itemIndex} is invalid:
            {messages.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </div>
        );
      }
    }

    if (errors.length || itemsErrors.length) {
      const errorText = (
        <>
          {!!errors.length && (
            <div>
              Form is invalid:
              {errors.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </div>
          )}
          {!!itemsErrors.length && itemsErrors}
        </>
      );
      this.toastService?.showError(this.props.toast, errorText);
      return false;
    }
    return true;
  }

  onHide() {
    this.setState({
      saving: false,
      clonedItems: [],
      invalidItemMessagesMap: new Map<number, string[]>(),
      dcmPatch: {},
    });
    this.props.onHide();
  }

  onShow() {
    const {dcm} = this.props;
    const dcmContent = dcm.content as DcmContent;
    this.setState({clonedItems: this.cloneItems(dcmContent.items ?? [])});
  }

  async onSaveDcmClick() {
    if (!this.validate()) {
      return;
    }
    const {dcm} = this.props;
    const {dcmPatch} = this.state;
    this.updateJobDocument(dcm!.id!, dcmPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'DCM updated.');
        this.onHide();
        MessageService.sendMessage(messages.jobUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, update failed, please try again.');
        console.error('error: ' + error);
      });
  }

  onItemsChange(newItems: OrderItem[]) {
    const {dcm} = this.props;
    const {dcmPatch} = this.state;

    const updatedContent = (dcmPatch?.content ? {...dcmPatch.content} : {...dcm!.content}) as DcmContent;
    updatedContent.items = newItems;
    const updatedDcmPatch: JobDocumentPatch = {
      ...dcmPatch,
      content: updatedContent,
    };
    this.setState({
      dcmPatch: updatedDcmPatch,
      clonedItems: newItems,
    });
  }

  onDcmContentChange(fieldsToUpdate: Partial<DcmContent>) {
    const {dcm} = this.props;
    const {dcmPatch} = this.state;
    const newDcmPatch = {
      ...dcmPatch,
      content: {
        ...(dcmPatch?.content ?? dcm?.content ?? {}),
        ...fieldsToUpdate,
      } as DcmContent,
    };
    this.setState({
      dcmPatch: newDcmPatch,
    });
  }

  onItemToggle(index: number) {
    const newCollapsedItemIndexes = new Set(this.state.collapsedItemIndexes);
    if (newCollapsedItemIndexes.has(index)) {
      newCollapsedItemIndexes.delete(index);
    } else {
      newCollapsedItemIndexes.add(index);
    }
    this.setState({collapsedItemIndexes: newCollapsedItemIndexes});
  }

  onItemsCollapse() {
    const {clonedItems} = this.state;
    const itemIndexes = clonedItems?.map(item => item.index) ?? [];
    this.setState({collapsedItemIndexes: new Set<number>(itemIndexes)});
  }

  onComparisonModeToggle() {
    this.setState({comparisonMode: !this.state.comparisonMode});
  }

  getSpeedDialItems(): MenuItem[] {
    const {comparisonMode, clonedItems, collapsedItemIndexes} = this.state;
    const speedDialItems: MenuItem[] = [];
    if (clonedItems?.length) {
      const allItemsMinimised = clonedItems.every(item => collapsedItemIndexes.has(item.index));
      if (allItemsMinimised) {
        speedDialItems.push({
          template: (
            <TwoSpeedDialItem
              icon={faArrowsMaximize}
              label="Expand"
              onClick={() => this.setState({collapsedItemIndexes: new Set<number>()})}
            />
          ),
        });
      } else {
        speedDialItems.push({
          template: <TwoSpeedDialItem icon={faArrowsMinimize} label="Collapse" onClick={this.onItemsCollapse} />,
        });
      }
    }
    if (comparisonMode) {
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faEyeSlash} label="Hide Changes" onClick={this.onComparisonModeToggle} />,
      });
    } else {
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faEye} label="Show Changes" onClick={this.onComparisonModeToggle} />,
      });
    }
    speedDialItems.push({
      template: (
        <TwoSpeedDialItem icon={faCheck} label="Validate" onClick={() => this.setState({showInvalidFields: true})} />
      ),
    });
    return speedDialItems;
  }

  render() {
    const {showDialog, dcm, dcmProdDefs, originalProdDefs, original} = this.props;
    const {saving, dcmPatch, comparisonMode, collapsedItemIndexes, showInvalidFields} = this.state;
    const originalContent = original?.content as OriginalContent;
    let originalItems = undefined;
    if (originalContent.requested_service === 'Check Measure & Install') {
      const checkMeasureContent = originalContent as CheckMeasureOriginalContent;
      originalItems = checkMeasureContent.items;
    } else if (originalContent.requested_service === 'Consult & Install') {
      const consultContent = originalContent as ConsultOriginalContent;
      if (consultContent.detail_level === 'draft' || consultContent.detail_level === 'estimate') {
        originalItems = consultContent.items;
      }
    }
    const dcmContent = (dcmPatch?.content ?? dcm?.content) as DcmContent;
    const speedDialItems = this.getSpeedDialItems();

    return (
      <>
        <TwoDialog
          header="Edit DCM"
          onHide={this.onHide}
          onShow={this.onShow}
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          onSave={this.onSaveDcmClick}
          saving={saving}
        >
          <DcmContentComponent
            dcmContent={dcmContent}
            dcmProdDefs={dcmProdDefs ?? []}
            readOnly={false}
            onDcmContentChange={this.onDcmContentChange}
            setInvalidItemMessagesMap={this.setInvalidItemMessagesMap}
            onItemsChange={this.onItemsChange}
            comparisonMode={comparisonMode}
            onItemToggle={this.onItemToggle}
            collapsedItemIndexes={collapsedItemIndexes}
            originalItems={originalItems}
            originalProductDefinitions={originalProdDefs}
            showInvalidFields={showInvalidFields}
          />
          <TwoSpeedDial model={speedDialItems} bottomPosition="5rem" />
        </TwoDialog>
      </>
    );
  }
}
