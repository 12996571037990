import React, {useContext} from 'react';
import {Appointment} from 'two-core';
import {AppContext, getTwoDateFormat, ReferenceComponent, ReferenceType} from 'two-app-ui';
import {faCalendar} from '@fortawesome/free-regular-svg-icons';
import {DateTime} from 'luxon';
import {JobAppointmentReferenceTooltip} from './JobAppointmentReferenceTooltip';

interface Props {
  identifier: string;
  appointment: Appointment;
}

export const JobAppointmentReference = ({identifier, appointment}: Props) => {
  const context = useContext(AppContext);

  let referenceType: ReferenceType = ReferenceType.DEFAULT;
  let label;

  switch (appointment.stage) {
    case 'Planned':
      label = 'P: ';
      referenceType = ReferenceType.INFO;
      break;
    case 'Invited':
      label = 'I: ';
      referenceType = ReferenceType.INFO;
      break;
    case 'Booked':
      label = 'B: ';
      referenceType = ReferenceType.DEFAULT;
      break;
    case 'Started':
      label = 'S: ';
      referenceType = ReferenceType.WARNING;
      break;
    case 'Finished':
      label = 'F: ';
      referenceType = ReferenceType.WARNING;
      break;
    case 'Under Review':
      label = 'R: ';
      referenceType = ReferenceType.INFO;
      break;
    case 'Completed':
      label = '';
      referenceType = ReferenceType.SUCCESS;
      break;
    default:
      label = '';
      referenceType = ReferenceType.DEFAULT;
  }
  label += DateTime.fromISO(appointment.start_plan!.toString()).toFormat(
    getTwoDateFormat(context.usersService?.settings?.date_format, 'dateTime')
  );
  return (
    <ReferenceComponent
      key={'job-appointment-reference-' + identifier}
      label={label}
      index={'job-appointment-reference-' + identifier}
      type={referenceType}
      faIcon={faCalendar}
      tooltipElement={<JobAppointmentReferenceTooltip appointment={appointment} />}
    />
  );
};
