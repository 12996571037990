import React from 'react';
import {TwoDialog} from 'two-app-ui';
import {Job} from 'two-core';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  job: Job;
}

export class ReviewInstallDialog extends React.Component<Props, {}> {
  render() {
    const {showDialog, onHide} = this.props;
    return (
      <TwoDialog
        headerTitle="Review Install"
        loading={false}
        onHide={onHide}
        showDialog={showDialog}
        style={{width: '50vw'}}
        breakpoints={{'768px': '75vw', '576px': '90vw'}}
      >
        Not implemented yet!
      </TwoDialog>
    );
  }
}
