import React from 'react';
import {DropdownChangeParams} from 'primereact/dropdown';
import {PaCompany, PaCompanyPatch} from 'two-core';
import {InputText} from 'primereact/inputtext';
import {Divider} from 'primereact/divider';

interface CompanyEditProps {
  company: PaCompany;
  companyPatch: PaCompanyPatch;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) => void;
  handleBillingAddressChange: (e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) => void;
}

const CompanyEditComponent = ({
  company: company,
  companyPatch: companyPatch,
  handleInputChange,
  handleBillingAddressChange,
}: CompanyEditProps) => {
  return (
    <>
      <div id="company-detail-item">
        <div className="p-grid">
          <div className="p-col-12 p-md-6 p-lg-6">
            <div className="p-grid p-ai-center">
              <label htmlFor="name" className="p-col-12 p-md-4">
                entity name
              </label>
              <div className="p-col-12 p-md-8">
                <span className="p-fluid">{company.name ?? ''}</span>
              </div>
            </div>
          </div>
          <div className="p-col-12 p-md-6 p-lg-6">
            <div className="p-grid p-ai-center">
              <label htmlFor="trading_as" className="p-col-12 p-md-4">
                trading as
              </label>
              <div className="p-col-12 p-md-8">
                <span className="p-fluid">{company.trading_as ?? ''}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="company-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="business_entity" className="p-col-12 p-md-4">
                  entity type
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">{company.business_entity ?? ''}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="company-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="account_number" className="p-col-12 p-md-4">
                  account number
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">{company.account_number ?? ''}</span>
                </div>
              </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="tax_id" className="p-col-12 p-md-4">
                  tax id
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">{company.tax_id ?? ''}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div id="company-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="phone_number" className="p-col-12 p-md-4">
                  phone number
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">
                    <InputText
                      value={companyPatch.phone_number ?? company.phone_number ?? ''}
                      name="phone_number"
                      onChange={e => {
                        handleInputChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="company-detail-item">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="p-grid p-ai-center">
                <label htmlFor="accounts_emails" className="p-col-12 p-md-2">
                  accounts emails
                </label>
                <div className="p-col-12 p-md-10">
                  <span className="p-fluid">
                    <InputText
                      value={companyPatch.accounts_emails ?? company.accounts_emails ?? ''}
                      name="accounts_emails"
                      onChange={e => {
                        handleInputChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="company-detail-item">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="p-grid p-ai-center">
                <label htmlFor="accounts_emails" className="p-col-12 p-md-2">
                  delivery emails
                </label>
                <div className="p-col-12 p-md-10">
                  <span className="p-fluid">
                    <InputText
                      value={companyPatch.delivery_emails ?? company.delivery_emails ?? ''}
                      name="delivery_emails"
                      onChange={e => {
                        handleInputChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div id="company-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="street" className="p-col-12 p-md-4">
                  billing address
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">
                    <InputText
                      name="street"
                      value={companyPatch.billing_address?.street ?? company.billing_address?.street ?? ''}
                      onChange={e => {
                        handleBillingAddressChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="suburb" className="p-col-12 p-md-4">
                  suburb/city
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">
                    <InputText
                      name="suburb"
                      value={companyPatch.billing_address?.suburb ?? company.billing_address?.suburb ?? ''}
                      onChange={e => {
                        handleBillingAddressChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="company-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="state" className="p-col-12 p-md-4">
                  state
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">
                    <InputText
                      name="state"
                      value={companyPatch.billing_address?.state ?? company.billing_address?.state ?? ''}
                      onChange={e => handleBillingAddressChange(e)}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="country" className="p-col-12 p-md-4">
                  country
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">
                    <InputText
                      name="country"
                      value={companyPatch.billing_address?.country ?? company.billing_address?.country ?? ''}
                      onChange={e => {
                        handleBillingAddressChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="company-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="postCode" className="p-col-12 p-md-4">
                  postcode
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">
                    <InputText
                      name="postCode"
                      value={companyPatch.billing_address?.postCode ?? company.billing_address?.postCode ?? ''}
                      onChange={e => {
                        handleBillingAddressChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div id="company-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-md-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="is_distributor" className="p-col-12 p-md-4">
                  stage
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">{company.stage ?? ''}</span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="is_distributor" className="p-col-12 p-md-4">
                  sales rep
                </label>
                <div className="p-col-12 p-md-8">
                  <span className="p-fluid">{company.sales_rep ?? ''}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyEditComponent;
