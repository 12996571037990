import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {MenuItemOptions} from 'primereact/menuitem';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import React from 'react';
import {Subscription} from 'rxjs';
import {
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
  TwoMessage,
} from 'two-app-ui';
import {PaCompany, PaContact, QueryParameter} from 'two-core';
import {messages} from '../../config/messages';
import {contactRoles, values} from '../../config/values';
import ContactsService from '../../services/ContactsService';
import M2OUsersService from '../../services/M2OUsersService';
import AddEditContactDialog from '../Contact/AddEditContactDialog';
import {localStorageAttributes} from '../../config/localStorageAttributes';

interface Props {
  company: PaCompany;
}

interface State {
  loading: boolean;
  items: PaContact[];
  contactToEdit: PaContact;
  total_items: number;
  activeFilters: {};
  filters: {
    title: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    role: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddEditDialog: boolean;
}

class CompanyContacts extends React.Component<Props, State> {
  static contextType = AppContext;
  contactsService: ContactsService | null = null;
  toastService: ToastService | null = null;
  usersService: M2OUsersService | null = null;

  subscription: Subscription = new Subscription();
  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      contactToEdit: {} as PaContact,
      total_items: 0,
      activeFilters: {},
      filters: {
        title: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        role: '',
      },
      pagination: {
        pageSize: 10,
        offset: 0,
      },
      sortBy: null,
      showAddEditDialog: false,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);
  }

  componentDidMount() {
    this.contactsService = this.context.contactsService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.contactUpdate) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === messages.topSelectionChanged) {
          this.loadData();
        }
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.title) {
      filters.push(
        JSON.stringify({
          field: 'title',
          value: this.state.filters.title,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.first_name) {
      filters.push(
        JSON.stringify({
          field: 'first_name',
          value: this.state.filters.first_name,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.last_name) {
      filters.push(
        JSON.stringify({
          field: 'last_name',
          value: this.state.filters.last_name,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.email) {
      filters.push(
        JSON.stringify({
          field: 'email',
          value: this.state.filters.email,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.phone_number) {
      filters.push(
        JSON.stringify({
          field: 'phone_number',
          value: this.state.filters.phone_number,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.role) {
      filters.push(
        JSON.stringify({
          field: 'company_contact.in_role',
          value: this.state.filters.role,
          condition: 'in',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    const sortBy = JSON.stringify({
      field: this.state.sortBy?.field ?? 'title',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      orderBys: [sortBy],
      aggregate: true,
      filters: filters,
    };

    this.contactsService
      ?.getContacts(params)
      .then(data => {
        const contacts = (data.records as PaContact[]) ?? [];
        this.setState({
          items: contacts,
          total_items: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }

  initMenuItems(): AppMenuItem[] {
    this.usersService = this.context.usersService;
    const menuItems: AppMenuItem[] = [];

    if (localStorage.getItem(localStorageAttributes.currentRole) === 'admin') {
      const addNewMenuItem: AppMenuItem = {
        label: 'Add New',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAddEditDialog({} as PaContact);
        },
      };
      menuItems.push(addNewMenuItem);
    }
    return menuItems;
  }

  showAddEditDialog(forContact: PaContact) {
    this.setState({contactToEdit: forContact, showAddEditDialog: true});
  }

  hideAddEditDialog() {
    this.setState({showAddEditDialog: false});
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  render() {
    const firstNameBodyTemplate = (rowData: PaContact) => {
      return (
        <a href={'#/'} onClick={() => this.showAddEditDialog(rowData)}>
          {rowData.first_name}
        </a>
      );
    };
    const firstNameFilter = (
      <InputText
        name="first_name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const lastNameFilter = (
      <InputText
        name="last_name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const emailFilter = (
      <InputText
        name="email"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const phoneNumberFilter = (
      <InputText
        name="phone_number"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const roleFilter = (
      <MultiSelect
        value={this.state.filters.role}
        options={contactRoles}
        name="role"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );
    const roleBodyTemplate = (rowData: PaContact) => {
      return <span>{rowData.role}</span>;
    };

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="contact_list_page" className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'contact_list_page'}
            heightToScroll={undefined}
            selectedItems={[]}
            loading={this.state.loading}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            activeFilters={this.state.activeFilters}
            value={this.state.items}
            totalRecords={this.state.total_items}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
            onSort={e => this.onSort(e)}
            sortField={this.state.sortBy?.field}
            sortOrder={this.state.sortBy?.order}
            initMenuItems={() => this.initMenuItems()}
          >
            <Column
              header="First name"
              body={firstNameBodyTemplate}
              filter
              filterElement={firstNameFilter}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
            <Column
              header="Last name"
              field="last_name"
              filter
              filterElement={lastNameFilter}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
            <Column
              header="Email"
              field="email"
              filter
              filterElement={emailFilter}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
            <Column
              header="Phone number"
              field="phone_number"
              filter
              filterElement={phoneNumberFilter}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
            <Column
              header="Role"
              body={roleBodyTemplate}
              filter
              filterElement={roleFilter}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
          </TwoDataTable>
          <Toast ref={this.toast} />
          <AddEditContactDialog
            contactId={this.state.contactToEdit.id}
            showDialog={this.state.showAddEditDialog}
            onHide={this.hideAddEditDialog}
            companyId={this.props.company.id ?? ''}
          />
        </div>
      </div>
    );
  }
}

export default CompanyContacts;
