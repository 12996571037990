import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  ToastService,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoMessage,
  TwoTimeline,
  TwoTimelineItem,
} from 'two-app-ui';
import './Company.scss';
import {CompanyContact, Contact, PaCompany, QueryParameter, TimeLineEvent} from 'two-core';
import {Subscription} from 'rxjs';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import CompaniesService from '../../services/CompaniesService';
import TleService from '../../services/TleService';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import CompanyEditDialog from './CompanyEditDialog';
import CompanyLocations from './CompanyLocations';
import CompanyDetail from './CompanyDetail';
import CompanyContacts from './CompanyContacts';
import M2OUsersService from '../../services/M2OUsersService';
import {localStorageAttributes} from '../../config/localStorageAttributes';

interface RouteProps {
  id: string;
}

interface State {
  loadingCompany: boolean;
  loadingSecondaryView: boolean;
  company: PaCompany | undefined;
  contact: Contact | undefined;
  companyContact: CompanyContact | undefined;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showEditDialog: boolean;
}

class CompanyComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  companiesService: CompaniesService | null = null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;
  usersService: M2OUsersService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingCompany: false,
      loadingSecondaryView: false,
      company: undefined,
      contact: undefined,
      companyContact: undefined,
      events: [],
      items: [],
      showEditDialog: false,
    };
    this.toast = React.createRef();

    this.hideEditDialog = this.hideEditDialog.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;

    const id = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    this.loadCompany(id);
    this.loadEvents(id);

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.companyUpdated) {
        this.loadCompany(id);
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name === messages.topSelectionChanged) {
          const id = localStorage.getItem(localStorageAttributes.currentCompanyId);
          let endpoint: string;
          if (id) {
            this.loadCompany(id);
            endpoint = '/company/' + id;
          } else {
            endpoint = '/';
          }
          this.props.history.push(endpoint);
        }
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadCompany(id: string) {
    this.setState({loadingCompany: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const company = (data.records as PaCompany[])[0];

        this.setState({
          company: company,
          loadingCompany: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, company load failed, please try again.');
        console.error(error);
        this.setState({loadingCompany: false});
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'company',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tleService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, Order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }

  showEditDialog() {
    if (localStorage.getItem(localStorageAttributes.currentRole) === 'admin') {
      this.setState({
        showEditDialog: true,
      });
    }
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  getActions(): TwoAction[] {
    const actions: TwoAction[] = [];

    if (localStorage.getItem(localStorageAttributes.currentRole) === 'admin') {
      actions.push({
        icon: ['far', 'pencil'] as IconProp,
        label: 'Edit',
        main: true,
        action: () => {
          this.showEditDialog();
        },
      });
    }

    return actions;
  }

  render() {
    const {company, items} = this.state;

    return company ? (
      <>
        <TwoEntityComponent title={company.name} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingCompany ? <CompanyDetail company={company} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={['far', 'calendar-alt']} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? <TwoTimeline key={company.id} items={items} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Contacts" icon={['far', 'user-friends']} tooltip="Contacts">
            {!this.state.loadingSecondaryView ? <CompanyContacts company={company} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Locations" icon={['far', 'warehouse']} tooltip="Locations">
            {!this.state.loadingSecondaryView ? <CompanyLocations company={company} /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <CompanyEditDialog company={company} showDialog={this.state.showEditDialog} onHide={this.hideEditDialog} />
        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(CompanyComponent);
