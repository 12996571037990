import React from 'react';
import {Accordion, AccordionTab} from 'primereact/accordion';
import './OrdersOverviewComponent.scss';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import JobListComponent from '../Jobs/JobListComponent';

export const JobsOverviewComponent = () => {
  const onTabOpen = () => {
    // TwoDataTable needs to recalculate its height.
    setTimeout(() => {
      MessageService.sendMessage(messages.heightChanged);
    }, 50);
  };

  return (
    <div id="orders_overview" className="page-container">
      <Accordion
        className="overview-accordion"
        onTabOpen={onTabOpen}
        transitionOptions={{timeout: {enter: 0, exit: 0}}}
      >
        <AccordionTab header="Requested Measures">
          <JobListComponent mode={'Requested'} />
        </AccordionTab>
        <AccordionTab header="Measure Reviews">
          <JobListComponent mode={'Measure Reviews'} />
        </AccordionTab>
      </Accordion>
    </div>
  );
};
