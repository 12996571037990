import React from 'react';
import {AppContext} from 'two-app-ui';
import './DashboardComponent.scss';
import OrdersOverviewComponent from './OrdersOverviewComponent';
import QuickMenuComponent from './QuickMenuComponent';
import {Company} from 'two-core';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import {JobsOverviewComponent} from './JobsOverviewComponent';
interface State {
  loading: boolean;
}
class DashboardComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '{}';
    const currentCompany = JSON.parse(currentCompanyString) as Company;
    const doesFitting =
      currentCompany.fits_for_others ||
      (currentCompany.fitting_types && currentCompany.fitting_types.includes('Internal'));

    return (
      <div className="dashboard-component">
        {/*<NewsComponent />*/}
        {currentCompany.does_orders && <QuickMenuComponent />}
        {currentCompany.does_orders && <OrdersOverviewComponent />}
        {doesFitting && <JobsOverviewComponent />}
      </div>
    );
  }
}
export default DashboardComponent;
