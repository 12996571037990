import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, JobDocument, JobDocumentPatch, QueryParameter} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

class JobsService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async getJobDocuments(params: QueryParameter): Promise<ApiListResponse> {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.get(config(currentCompanyId).endpoints.jobDocuments, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async createJobDocument(data: JobDocument): Promise<JobDocument> {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.post(config(currentCompanyId).endpoints.jobDocuments, data)
      .then(data => {
        return Promise.resolve(data as JobDocument);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateJobDocument(id: string, data: JobDocumentPatch): Promise<JobDocument> {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.patch(`${config(currentCompanyId).endpoints.jobDocuments}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as JobDocument);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}

export default JobsService;
