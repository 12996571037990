import React from 'react';
import {ScrollPanel} from 'primereact/scrollpanel';
import {OrderItemsComponent} from 'two-app-ui';
import {CheckMeasureOriginalContent, ConsultOriginalContent, OriginalContent, PaProductDefinition} from 'two-core';
import {ProgressSpinner} from 'primereact/progressspinner';
import './OriginalRequest.scss';

interface Props {
  loading: boolean;
  docContent: OriginalContent;
  productDefinitions?: PaProductDefinition[];
}

export const OriginalRequest = ({loading, docContent, productDefinitions}: Props) => {
  if (loading) {
    return (
      <div className="p-d-flex p-ai-center w-100 h-100">
        <ProgressSpinner />
      </div>
    );
  }
  const dealerInfo = docContent.dealer_info;
  const clientInfo = docContent.client_info;

  const dealerDetail = (
    <div className="p-my-4">
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2">dealership</label>
        <div className="p-col-10">{dealerInfo.company_name}</div>
      </div>
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2 p-as-start">dealer</label>
        <div className="p-col-10">
          {dealerInfo.first_name} {dealerInfo.last_name}
        </div>
      </div>
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2 p-as-start">email</label>
        <div className="p-col-10">{dealerInfo.email}</div>
      </div>
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2 p-as-start">phone</label>
        <div className="p-col-10">{dealerInfo.phone_number}</div>
      </div>
    </div>
  );

  const clientDetail = (
    <div className="p-my-4">
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2">client</label>
        <div className="p-col-10">
          {clientInfo.first_name} {clientInfo.last_name}
        </div>
      </div>
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2 p-as-start">address</label>
        <div className="p-col-10">
          {clientInfo.address.street}, {clientInfo.address.suburb}, {clientInfo.address.postCode},{' '}
          {clientInfo.address.state}
        </div>
      </div>
      {clientInfo.email && (
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2 p-as-start">email</label>
          <div className="p-col-10">{clientInfo.email}</div>
        </div>
      )}
      {clientInfo.phone_number && (
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2 p-as-start">phone</label>
          <div className="p-col-10">{clientInfo.phone_number}</div>
        </div>
      )}
    </div>
  );

  const generalData = (
    <div className="p-my-4">
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2">service</label>
        <div className="p-col-10">{docContent.requested_service}</div>
      </div>
      {docContent.comments && (
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2 p-as-start">comments</label>
          <div className="p-col-10">{docContent.comments}</div>
        </div>
      )}
      {docContent.attachments && docContent.attachments.length > 0 && (
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2 p-as-start">attachments</label>
          <div className="p-col-10">{docContent.attachments?.join(', ')}</div>
        </div>
      )}
    </div>
  );

  let sourceDetailLevel = <></>;
  let sourceEntity = <></>;
  let itemsComponent = <></>;
  if (docContent.requested_service === 'Consult & Install') {
    const consultContent = docContent as ConsultOriginalContent;
    sourceDetailLevel = (
      <div className="p-my-4">
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2">detail level</label>
          <div className="p-col-10">
            {consultContent.detail_level.charAt(0).toUpperCase() + consultContent.detail_level.slice(1)}
          </div>
        </div>
      </div>
    );
    if (consultContent.detail_level === 'products') {
      itemsComponent = (
        <div className="p-my-4">
          {consultContent.products?.map(prod => {
            return (
              <div className="p-field p-grid p-mb-1" key={prod.product_name}>
                <div className="p-col-2" />
                <div className="p-col-10">{`${prod.product_name} x ${prod.quantity}`}</div>
              </div>
            );
          })}
        </div>
      );
    } else if (consultContent.detail_level === 'draft' || consultContent.detail_level === 'estimate') {
      sourceEntity = (
        <div className="p-my-4">
          <div className="p-field p-grid p-mb-1">
            <label className="p-col-2">code</label>
            <div className="p-col-10">
              {consultContent.detail_level === 'draft' ? consultContent.draft_code : consultContent.estimate_code}
            </div>
          </div>
        </div>
      );
      itemsComponent = (
        <OrderItemsComponent
          mode="readonly"
          items={consultContent.items ?? []}
          productDefinitions={productDefinitions ?? []}
          onItemsChanged={() => {}}
          outerItemColumnClassName={'p-col-12 p-lg-6'}
          innerItemColumnClassName={'p-col-12 p-xl-6'}
        />
      );
    }
  } else if (docContent.requested_service === 'Check Measure & Install') {
    const measureContent = docContent as CheckMeasureOriginalContent;
    sourceEntity = (
      <div className="p-my-4">
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2">code</label>
          <div className="p-col-10">{measureContent.estimate_code}</div>
        </div>
      </div>
    );
    itemsComponent = (
      <OrderItemsComponent
        mode="readonly"
        items={measureContent.items ?? []}
        productDefinitions={productDefinitions ?? []}
        onItemsChanged={() => {}}
        outerItemColumnClassName={'p-col-12 p-lg-6'}
        innerItemColumnClassName={'p-col-12 p-xl-6'}
      />
    );
  }

  return (
    <div id="original_page_container" className="p-mt-1">
      <div id="original_request_page" className="app-page">
        <ScrollPanel className="p-mt-1 drafts-scroll-panel">
          {dealerDetail}
          {clientDetail}
          {generalData}
          {sourceDetailLevel}
          {sourceEntity}
          {itemsComponent}
        </ScrollPanel>
      </div>
    </div>
  );
};
