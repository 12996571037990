export const messages = {
  companyUpdated: 'company-updated',
  locationUpdate: 'location-updated',
  contactUpdate: 'contact-updated',
  subDealerUpdate: 'subDealer-updated',
  topSelectionChanged: 'top-selection-changed',
  orderCreated: 'order-created',
  orderUpdated: 'order-updated',
  draftCreated: 'draft-created',
  draftUpdated: 'draft-updated',
  refreshAppMenu: 'refresh-app-menu',
  orderCanNotBeSaved: 'order-can-not-be-saved',
  quoteExport: 'quote-ready-for-export',
  initTableMenuItems: 'init-table-menu-items',
  printDrafts: 'drafts-ready-to-print',
  printDraft: 'draft-ready-to-print',
  printOrders: 'orders-ready-to-print',
  printOrder: 'order-ready-to-print',
  printDcms: 'dcm-ready-to-print',
  heightChanged: 'height-changed',
  jobCreated: 'job-created',
  jobUpdated: 'job-updated',
};
