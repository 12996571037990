import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, PaContact, PaContactPatch, QueryParameter} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

class ContactsService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async getMe(): Promise<ApiResponse> {
    return this.get(config().endpoints.me)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getContacts(params: QueryParameter): Promise<ApiListResponse> {
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.get(config(companyId).endpoints.contacts, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateContact(id: string, data: PaContactPatch): Promise<PaContact> {
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.patch(`${config(companyId).endpoints.contacts}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as PaContact);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createContact(data: PaContact): Promise<PaContact> {
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.post(`${config(companyId).endpoints.contacts}`, data)
      .then(data => {
        return Promise.resolve(data as PaContact);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default ContactsService;
