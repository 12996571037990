import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

class LocationsService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async getLocations(params: QueryParameter, companyId?: string): Promise<ApiListResponse> {
    if (!companyId) {
      companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    }
    return this.get(config(companyId).endpoints.locations, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getLocationsByCompanyId(companyId: string, forFittingOnly: boolean): Promise<ApiListResponse> {
    const filters = [];
    if (forFittingOnly) {
      filters.push(
        JSON.stringify({
          field: 'used_for_fitting',
          value: true,
        })
      );
    }
    const params: QueryParameter = {
      filters: filters,
      aggregate: false,
    };
    return this.get(config(companyId).endpoints.locations, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}

export default LocationsService;
