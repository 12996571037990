import {Button} from 'primereact/button';
import React from 'react';
import {AppContext, ToastService, TwoDialog} from 'two-app-ui';
import LocationsService from '../../services/LocationsService';
import {QueryParameter, Location} from 'two-core';
import {Toast} from 'primereact/toast';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';

interface Props {
  forCompanyId?: string;
  showDialog: boolean;
  onHide: () => void;
  onSaveEstimateButtonClick: () => void;
  onShippingAddressChange: (location: Location) => void;
  toast: React.RefObject<Toast>;
}

interface State {
  locations: Location[];
  selectedLocation?: Location;
  loading: boolean;
}

export class ShippingAddressDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  locationsService?: LocationsService;
  toastService?: ToastService;

  constructor(props: Props) {
    super(props);
    this.state = {
      locations: [],
      loading: false,
    };

    this.loadLocations = this.loadLocations.bind(this);
    this.onSaveEstimateButtonClick = this.onSaveEstimateButtonClick.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onInputShippingAddressChange = this.onInputShippingAddressChange.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.locationsService = this.context.locationsService;
  }

  async loadLocations() {
    const sortByStringyfied = JSON.stringify({
      field: 'id',
      direction: 'ASC',
    });

    const params: QueryParameter = {
      orderBys: [sortByStringyfied],
      aggregate: false,
    };
    return this.locationsService
      ?.getLocations(params, this.props.forCompanyId)
      .then(data => {
        const locations = data.records as Location[];
        this.setState({
          locations: locations,
        });
      })
      .catch(error => {
        console.error('Failed Loading Data:' + error);
        this.toastService?.showError(this.props.toast, 'Failed loading data, please refresh and try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  onShow() {
    this.setState({loading: true});
    this.loadLocations();
  }

  onInputShippingAddressChange(e: DropdownChangeParams) {
    const {onShippingAddressChange} = this.props;
    const value = e.value as Location;
    onShippingAddressChange(value);
    this.setState({selectedLocation: value});
  }

  addressOptionTemplate(location: Location) {
    if (location) {
      return (
        <div>
          {location.address?.street +
            ', ' +
            location.address?.suburb +
            ', ' +
            location.address?.state +
            ', ' +
            location.address?.country}
        </div>
      );
    }

    return 'empty';
  }

  onSaveEstimateButtonClick() {
    const {selectedLocation} = this.state;
    const {onSaveEstimateButtonClick} = this.props;
    if (selectedLocation) {
      onSaveEstimateButtonClick();
    } else {
      this.toastService?.showError(this.props.toast, 'Shipping address field is empty.');
    }
  }

  render() {
    const {onHide, showDialog} = this.props;
    const {locations, selectedLocation, loading} = this.state;
    const footer = (
      <>
        <Button label="Close" className={'p-mr-2 p-button-text'} onClick={onHide} />
        <Button label="Send" className={'p-mr-2'} onClick={this.onSaveEstimateButtonClick} />
      </>
    );
    const dialogBody = (
      <div className="p-fluid w-100 p-mx-2">
        <div className="p-field p-grid p-mb-0">
          <div className="p-col-12">
            <Dropdown
              placeholder="Select shipping address"
              value={selectedLocation}
              options={locations}
              onChange={this.onInputShippingAddressChange}
              dataKey="id"
              optionLabel="id"
              valueTemplate={this.addressOptionTemplate}
              itemTemplate={this.addressOptionTemplate}
            />
          </div>
        </div>
      </div>
    );

    return (
      <TwoDialog
        headerTitle="Choose shipping address"
        loading={loading}
        onHide={onHide}
        onShow={this.onShow}
        showDialog={showDialog}
        footer={footer}
        style={{width: '30vw'}}
        breakpoints={{
          '1920px': '40vw',
          '992px': '55vw',
          '768px': '70vw',
          '576px': '85vw',
        }}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
