export const localStorageAttributes = {
  currentCompanyId: 'current-company-id',
  doesQuotes: 'does-quotes',
  currentRole: 'current-role',
  fittingTypes: 'fitting-types',
  fittsForOthers: 'fits-for-others',
  currentCompanyState: 'current-company-state',
  currentCompany: 'current-company',
  myContact: 'current-user-contact',
  user: 'user',
};
