import React from 'react';
import {Card} from 'primereact/card';
import {AppContext, getTwoDateFormat, UsersService} from 'two-app-ui';
import {DateTime} from 'luxon';
import {Address, LocationReference, PaOrder} from 'two-core';
import {Divider} from 'primereact/divider';

interface Props {
  order: PaOrder;
}

class OrderDetail extends React.Component<Props, {}> {
  static contextType = AppContext;

  usersService?: UsersService;

  constructor(props: Props) {
    super(props);
    this.state = {};

    this.priorityTemplate = this.priorityTemplate.bind(this);
  }

  componentDidMount() {
    this.usersService = this.context.usersService;
  }

  priorityTemplate(priority: number) {
    let priorityText = '';
    switch (priority) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span>{priorityText}</span>;
  }

  render() {
    const order = this.props.order;
    const dateFormat = this.usersService?.settings?.date_format;

    let shippingAddress = '';
    if ((order.shipping_address as Address).street) {
      const address = order.shipping_address as Address;
      shippingAddress = `${address.street} ${address.suburb} ${address.postCode} ${address.state}`;
    } else {
      shippingAddress = (order.shipping_address as LocationReference).address;
    }

    return (
      <Card className={'p-d-flex order-detail-item'}>
        {/** Reference */}
        <div className="p-d-flex">
          <label htmlFor="reference" className="p-col-12 p-md-3">
            reference
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.reference ?? ''}</span>
          </div>
        </div>
        {/** Owner */}
        {order.owner_contact_ref && (
          <div className="p-d-flex">
            <label htmlFor="owner" className="p-col-12 p-md-3">
              owner
            </label>
            <div className="p-col-12 p-md-9">
              <span>{order.owner_contact_ref.label ?? ''}</span>
            </div>
          </div>
        )}
        <Divider />
        {/** Stage */}
        <div className="p-d-flex">
          <label htmlFor="stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className={'p-col-12 p-md-9'}>
            <span>{order.stage}</span>
          </div>
        </div>
        {/** Type */}
        <div className="p-d-flex">
          <label htmlFor="type" className="p-col-12 p-md-3">
            type
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.type}</span>
          </div>
        </div>
        <Divider />
        {/** Size */}
        <div className="p-d-flex">
          <label htmlFor="size" className="p-col-12 p-md-3">
            size
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.size}</span>
          </div>
        </div>
        {/** Summary */}
        <div className="p-d-flex">
          <label htmlFor="summary" className="p-col-12 p-md-3">
            summary
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.summary ?? ''}</span>
          </div>
        </div>
        <Divider />
        {/** Shipping address */}
        {order.shipping_address && (
          <>
            <div className="p-d-flex">
              <label htmlFor="shipping_address" className="p-col-12 p-md-3">
                shipping to
              </label>
              <div className="p-col-12 p-md-12 p-lg-9">
                <span>{shippingAddress}</span>
              </div>
            </div>
          </>
        )}
        {/** Draft todo: add link to draft if exists including [deleted] flag */}
        {/** Users */}
        {order.created_by_id && order.created_at && (
          <>
            <Divider />
            <div className="p-d-flex">
              <label htmlFor="created" className="p-col-12 p-md-3">
                created
              </label>
              <div className="p-col-12 p-md-9">
                <span>
                  {DateTime.fromISO(order.created_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))}
                </span>
              </div>
            </div>
            <div className="p-d-flex">
              <label htmlFor="created by" className="p-col-12 p-md-3">
                by
              </label>
              <div className="p-col-12 p-md-9">
                <span>{order.created_by_id}</span>
              </div>
            </div>
          </>
        )}
        {order.submitted_by && order.submitted_at && (
          <>
            <div className="p-d-flex">
              <label htmlFor="submitted" className="p-col-12 p-md-3">
                submitted
              </label>
              <div className="p-col-12 p-md-9">
                <span>
                  {DateTime.fromISO(order.submitted_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))}
                </span>
              </div>
            </div>
            <div className="p-d-flex">
              <label htmlFor="submitted by" className="p-col-12 p-md-3">
                by
              </label>
              <div className="p-col-12 p-md-9">
                <span>{order.submitted_by}</span>
              </div>
            </div>
          </>
        )}
        {order.approved_at ? (
          <div className="p-d-flex">
            <label htmlFor="approved" className="p-col-12 p-md-3">
              approved
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${order.approved_at ? DateTime.fromISO(order.approved_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime')) : ''}`}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {order.fitting_options && (
          <>
            <Divider />
            <div className="p-grid">
              <label className="p-col-12 p-md-3">fitting type</label>
              <div className="p-col-12 p-md-9">
                <span>{order.fitting_options.type}</span>
              </div>
            </div>
            {order.fitting_options.type !== 'made2fit' && (
              <div className="p-grid">
                <label className="p-col-12 p-md-3">
                  {order.fitting_options.type === 'internal' ? 'fitter' : 'provider'}
                </label>
                <div className="p-col-12 p-md-9">
                  <span>{order.fitting_options.reference.label}</span>
                </div>
              </div>
            )}
          </>
        )}
      </Card>
    );
  }
}

export default OrderDetail;
