import React, {useContext} from 'react';
import {Card} from 'primereact/card';
import {Draft} from 'two-core';
import './DraftDetail.scss';
import {DateTime} from 'luxon';
import {AppContext, getTwoDateFormat} from 'two-app-ui';

interface Props {
  draft: Draft;
}

export const DraftDetail = (props: Props) => {
  const context = useContext(AppContext);
  const dateTimeFormat = getTwoDateFormat(context.usersService?.settings?.date_format, 'dateTime');
  const {draft} = props;

  return (
    <Card className={'p-d-flex order-detail-item'}>
      {/** Reference */}
      <div className="p-d-flex">
        <label htmlFor="reference" className="p-col-12 p-md-3">
          reference
        </label>
        <div className="p-col-12 p-md-9">
          <span>{draft.reference ?? ''}</span>
        </div>
      </div>
      {/** Owner */}
      <div className="p-d-flex">
        <label htmlFor="owner" className="p-col-12 p-md-3">
          owner
        </label>
        <div className="p-col-12 p-md-9">
          <span>{draft.owner_contact_ref.label ?? ''}</span>
        </div>
      </div>
      <div className="p-d-flex">
        <div className="p-col-12" />
      </div>

      {/** Note */}
      {draft.note ? (
        <div className="p-d-flex">
          <label htmlFor="note" className="p-col-12 p-md-3">
            note
          </label>
          <div className="p-col-12 p-md-9 wrapped_text">
            <span>{draft.note}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/** Size */}
      <div className="p-d-flex">
        <label htmlFor="size" className="p-col-12 p-md-3">
          size
        </label>
        <div className="p-col-12 p-md-9">
          <span>{draft.size ?? ''}</span>
        </div>
      </div>
      {/** Summary */}
      <div className="p-d-flex">
        <label htmlFor="summary" className="p-col-12 p-md-3">
          summary
        </label>
        <div className="p-col-12 p-md-9">
          <span>{draft.summary ?? ''}</span>
        </div>
      </div>
      <div className="p-d-flex">
        <div className="p-col-12" />
      </div>

      {/** Order */}
      {draft.order_id && (
        <div className="p-d-flex">
          <label htmlFor="orderId" className="p-col-12 p-md-3">
            order
          </label>
          <div className="p-col-12 p-md-9 wrapped_text">
            <span>{draft.order_id}</span>
          </div>
        </div>
      )}
      <div className="p-d-flex">
        <div className="p-col-12" />
      </div>

      {draft.created_at && (
        <>
          <div className="p-d-flex">
            <label htmlFor="created" className="p-col-12 p-md-3">
              created
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromISO(draft.created_at.toString()).toFormat(dateTimeFormat)}</span>
            </div>
          </div>
          <div className="p-d-flex">
            <label htmlFor="created by" className="p-col-12 p-md-3">
              by
            </label>
            <div className="p-col-12 p-md-9">
              <span>{draft.created_by_id}</span>
            </div>
          </div>
        </>
      )}

      {draft.updated_at && (
        <div className="p-d-flex">
          <label htmlFor="updated" className="p-col-12 p-md-3">
            updated
          </label>
          <div className="p-col-12 p-md-9">
            <span>{DateTime.fromISO(draft.updated_at.toString()).toFormat(dateTimeFormat)}</span>
          </div>
        </div>
      )}
    </Card>
  );
};
