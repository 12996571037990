import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Appointment, AppointmentPatch, QueryParameter} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

export default class AppointmentsService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async createAppointment(data: AppointmentPatch): Promise<Appointment> {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.post(config(currentCompanyId).endpoints.appointments, data)
      .then(data => {
        return Promise.resolve(data as Appointment);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateAppointment(id: number, data: AppointmentPatch): Promise<Appointment> {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.patch(config(currentCompanyId).endpoints.appointments + '/' + id, data)
      .then(data => {
        return Promise.resolve(data as Appointment);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getAppointments(params: QueryParameter): Promise<ApiListResponse> {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.get(config(currentCompanyId).endpoints.appointments, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}
