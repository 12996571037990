import React from 'react';
import {Tooltip} from 'primereact/tooltip';
import {Job} from 'two-core';

interface Props {
  job: Job;
}

export const JobOwnerColumn = ({job}: Props) => {
  const owner = job.owner;
  const labelClasses = 'p-col-4';
  const valueClasses = 'p-col-8';
  return (
    <div id={`job_owner_column_${job.id}`}>
      <Tooltip target={`#job_owner_column_${job.id}`} position="left" style={{width: '500px'}}>
        <div className={'p-d-flex p-flex-column'}>
          <div className="p-grid">
            <label className={labelClasses}>Account number</label>
            <div className={valueClasses}>
              <span>{owner?.account_number}</span>
            </div>
          </div>
        </div>
        <div className={'p-d-flex p-flex-column'}>
          <div className="p-grid">
            <label className={labelClasses}>Company Name</label>
            <div className={valueClasses}>
              <span>{owner?.name}</span>
            </div>
          </div>
        </div>
        {owner?.trading_as && (
          <div className={'p-d-flex p-flex-column'}>
            <div className="p-grid">
              <label className={labelClasses}>Trading as</label>
              <div className={valueClasses}>
                <span>{owner?.trading_as}</span>
              </div>
            </div>
          </div>
        )}
      </Tooltip>
      {owner?.name}
    </div>
  );
};
