import React, {PropsWithChildren} from 'react';
import {Button} from 'primereact/button';
import {TwoDialog} from 'two-app-ui';

interface Props {
  showDialog: boolean;
  onCancel: () => void;
  onYes: () => void;
}

export const NewProductDefinitionDialog = ({showDialog, onYes, onCancel}: PropsWithChildren<Props>) => {
  const footer = (
    <div className={'p-d-flex p-justify-end'}>
      <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={onCancel} />
      <Button label="Yes, Re-Run" onClick={onYes} />
    </div>
  );
  const content =
    'There is a newer product definition revision on ff2. This draft needs to be re-run with the new revision to make sure it is compatible. Do you wish to do that now?';
  return (
    <TwoDialog
      header="New Product Definition Revision on FF2"
      loading={false}
      showDialog={showDialog}
      style={{width: '50vw'}}
      breakpoints={{'768px': '75vw', '576px': '90vw'}}
      onHide={onCancel}
      footer={footer}
    >
      {content}
    </TwoDialog>
  );
};
