import React from 'react';
import {ConsultProduct, PaProductDefinition} from 'two-core';
import {Button} from 'primereact/button';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {InputNumber, InputNumberChangeParams} from 'primereact/inputnumber';

interface Props {
  products: ConsultProduct[];
  productDefinitions: PaProductDefinition[];
  onChange: (products: ConsultProduct[]) => void;
  disabled?: boolean;
}

export const ProductsQtysComponent = (props: Props) => {
  const onAdd = () => {
    const {onChange, products, productDefinitions} = props;
    const newProduct: ConsultProduct = {
      product_name: productDefinitions[0].product_name,
      quantity: 0,
    };
    onChange([...products, newProduct]);
  };

  const onProductChange = (e: DropdownChangeParams, index: number) => {
    const {onChange, products} = props;
    const value = e.target.value;
    const newProducts = [...products];
    newProducts[index].product_name = value;
    onChange(newProducts);
  };

  const onQtyChange = (e: InputNumberChangeParams, index: number) => {
    const {onChange, products} = props;
    const value = e.value ?? 0;
    const newProducts = [...products];
    newProducts[index].quantity = value;
    onChange(newProducts);
  };

  const onRemove = (index: number) => {
    const {onChange, products} = props;
    const newProducts = [...products];
    newProducts.splice(index, 1);
    onChange(newProducts);
  };

  const productOptions = props.productDefinitions.map(def => {
    return {value: def.product_name, label: def.product_name};
  });

  return (
    <div className="p-field p-grid">
      <label className="p-col-1 p-as-start">products</label>
      <div className="p-col-11">
        {props.products.map((product, index) => {
          return (
            <div key={`product-${index}`} className="p-field p-grid">
              <div className="p-col-5">
                <Dropdown
                  value={product.product_name}
                  options={productOptions}
                  onChange={e => onProductChange(e, index)}
                  disabled={props.disabled ?? false}
                />
              </div>
              <label htmlFor="reference" className="p-col-1">
                qty
              </label>
              <div className="p-col-4">
                <InputNumber
                  value={product.quantity}
                  onChange={e => onQtyChange(e, index)}
                  disabled={props.disabled ?? false}
                />
              </div>
              <div className="p-col-2 p-d-flex p-justify-end">
                <Button
                  className="p-button p-component w-auto"
                  onClick={() => onRemove(index)}
                  label="Remove"
                  disabled={props.disabled ?? false}
                />
              </div>
            </div>
          );
        })}
        {!!props.productDefinitions.length && (
          <Button
            className="p-button p-component w-auto"
            onClick={onAdd}
            label="Add"
            disabled={props.disabled ?? false}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsQtysComponent;
