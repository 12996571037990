import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {Toast} from 'primereact/toast';
import React from 'react';
import {Subscription} from 'rxjs';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  MessageService,
  ToastService,
  TwoDataTable,
  TwoMessage,
} from 'two-app-ui';
import {Location, PaCompany, QueryParameter} from 'two-core';
import {messages} from '../../config/messages';
import LocationsService from '../../services/LocationsService';

export const types: string[] = ['factory', 'warehouse', 'dealership', 'end-customer', 'service', 'other'];

interface Props {
  company: PaCompany;
}

interface State {
  loading: boolean;
  items: Location[];
  selectedItems: Location[];
  total_items: number;
  activeFilters: {};
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
}

class CompanyLocations extends React.Component<Props, State> {
  static contextType = AppContext;
  locationsService: LocationsService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;
  subscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      selectedItems: [],
      total_items: 0,
      activeFilters: {},
      pagination: {
        pageSize: 10,
        offset: 0,
      },

      sortBy: null,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.locationsService = this.context.locationsService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.locationUpdate) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === messages.topSelectionChanged) {
          this.loadData();
        }
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  loadData() {
    this.setState({loading: true});

    const sortBy = JSON.stringify({
      field: this.state.sortBy?.field ?? 'name',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      orderBys: [sortBy],
      aggregate: true,
      showAll: true,
    };

    this.locationsService
      ?.getLocations(params)
      .then(data => {
        const locations = (data.records as Location[]) ?? [];
        this.setState({
          items: locations,
          total_items: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  nameBodyTemplate(rowData: Location) {
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        selectedItems={[]}
      >
        {rowData.name}
      </AppColumnMenuBodyTemplate>
    );
  }

  adressBodyTemplate(rowData: Location) {
    return (
      <>
        {`${rowData.address.street}, ${rowData.address.suburb}, ${rowData.address.state}, ${rowData.address.country}`}
      </>
    );
  }

  render() {
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="location_list_page" className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'location_list_page'}
            heightToScroll={undefined}
            selectedItems={[]}
            loading={this.state.loading}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            activeFilters={this.state.activeFilters}
            value={this.state.items}
            totalRecords={this.state.total_items}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
            onSort={e => this.onSort(e)}
            sortField={this.state.sortBy?.field}
            sortOrder={this.state.sortBy?.order}
          >
            <Column header="Name" field="name" sortable showFilterMenu={false} className={'p-col-2'} />
            <Column
              header="Address"
              field="address"
              body={this.adressBodyTemplate}
              sortable
              className={'p-col-10'}
              showFilterMenu={false}
            />
          </TwoDataTable>
          <Toast ref={this.toast} />
        </div>
      </div>
    );
  }
}

export default CompanyLocations;
