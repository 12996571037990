import React from 'react';
import {AdditionalItemType, FixingRequiredType, InstallationInfo} from 'two-core';
import {InputNumber} from 'primereact/inputnumber';
import {InputSwitch} from 'primereact/inputswitch';
import {InputTextarea} from 'primereact/inputtextarea';

interface Props {
  installationInfo?: InstallationInfo;
  onInstallationInfoChange: (fieldsToUpdate: Partial<InstallationInfo>) => void;
  readOnly: boolean;
  showValidation: boolean;
}

const fixingsRequiredOptions: FixingRequiredType[] = [
  'Timber',
  'Brick',
  'Concrete',
  'Metal',
  'Steel',
  'Tile',
  'Stucco',
  'Granite',
  'Drywall',
];

const additionalItemOptions: AdditionalItemType[] = ['Tall Ladder', 'Scaffold', 'Cherry Picker'];

export const InfoView = ({installationInfo, onInstallationInfoChange, readOnly, showValidation}: Props) => {
  const onFieldChange = (name: keyof InstallationInfo, value: string | number | string[]) => {
    onInstallationInfoChange({[name]: value});
  };
  const onMultiInputSwitchChange = (
    name: 'fixings_required' | 'additional_items',
    checked: boolean,
    newValue: FixingRequiredType | AdditionalItemType
  ) => {
    let values: string[] = [...(installationInfo?.[name] ?? [])];
    if (checked) {
      values.push(newValue);
    } else {
      values = values.filter(value => value !== newValue);
    }
    onFieldChange(name, values);
  };
  const durationLabelClass = showValidation && !installationInfo?.time_required ? 'p-error' : '';
  const fittersLabelClass = showValidation && !installationInfo?.fitters_required ? 'p-error' : '';
  return (
    <div className="p-fluid p-p-2">
      <div className="p-field p-grid p-m-0">
        <label className="p-col-3 p-md-2 p-mb-2 p-mt-1 p-as-start">
          <small className={durationLabelClass}>duration (h)</small>
        </label>
        <div className="p-col-3 p-md-4 p-mb-2">
          <InputNumber
            name="time_required"
            min={0}
            step={1}
            value={installationInfo?.time_required}
            onChange={e => onFieldChange('time_required', e.value as number)}
            showButtons
            disabled={readOnly}
          />
        </div>
        <label className="p-col-3 p-md-2 p-mb-2 p-mt-1 p-as-start">
          <small className={fittersLabelClass}># fitters</small>
        </label>
        <div className="p-col-3 p-md-4 p-mb-2">
          <InputNumber
            name="fitters_required"
            min={0}
            step={1}
            value={installationInfo?.fitters_required}
            onChange={e => onFieldChange('fitters_required', e.value as number)}
            showButtons
            disabled={readOnly}
          />
        </div>
        {/*<label className="p-col-3 p-md-2 p-mb-2 p-mt-1 p-as-start">*/}
        {/*  <small># off-siders</small>*/}
        {/*</label>*/}
        {/*<div className="p-col-3 p-md-4 p-mb-2">*/}
        {/*  <InputNumber*/}
        {/*    name="offsiders_required"*/}
        {/*    min={0}*/}
        {/*    step={1}*/}
        {/*    value={installationInfo?.offsiders_required}*/}
        {/*    onChange={e => onFieldChange('offsiders_required', e.value as number)}*/}
        {/*    showButtons*/}
        {/*    disabled={readOnly}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div className="p-field p-grid p-mx-0 p-mt-0 p-mb-2">
        <label className="p-col-3 p-md-2 p-mb-0 p-mt-1 p-as-start">
          <small>fixing into</small>
        </label>
        <div className="p-col-9 p-md-10">
          <div className="p-field p-grid p-m-0">
            {fixingsRequiredOptions.map(option => (
              <>
                <label className="p-col-3 p-md-2 p-mb-0 p-text-lowercase">
                  <small>{option}</small>
                </label>
                <div className="p-col-3 p-md-2">
                  <InputSwitch
                    name={option}
                    checked={installationInfo?.fixings_required?.includes(option)}
                    onChange={e => onMultiInputSwitchChange('fixings_required', e.value, option)}
                    disabled={readOnly}
                  />
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      <div className="p-field p-grid p-mx-0 p-mt-0 p-mb-2">
        <label className="p-col-3 p-md-2 p-mb-0 p-mt-1 p-as-start">
          <small>additional tools</small>
        </label>
        <div className="p-col-9 p-md-10">
          <div className="p-field p-grid p-m-0">
            {additionalItemOptions.map(option => (
              <>
                <label className="p-col-3 p-md-2 p-mb-0 p-mb-0 p-text-lowercase">
                  <small>{option}</small>
                </label>
                <div className="p-col-3 p-md-2">
                  <InputSwitch
                    name={option}
                    checked={installationInfo?.additional_items?.includes(option)}
                    onChange={e => onMultiInputSwitchChange('additional_items', e.value, option)}
                    disabled={readOnly}
                  />
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      <div className="p-field p-grid p-m-0">
        <label className="p-col-3 p-md-2 p-mb-0 p-as-start">
          <small>installation note</small>
        </label>
        <div className="p-col-9 p-md-10">
          <InputTextarea
            className="w-100"
            value={installationInfo?.note}
            onChange={e => onFieldChange('note', e.target.value)}
            disabled={readOnly}
          />
        </div>
      </div>
    </div>
  );
};
