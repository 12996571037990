import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import React from 'react';
import {Link} from 'react-router-dom';
import {AppContext, ToastService} from 'two-app-ui';
import {FittingType, LocationReference, PaOrder} from 'two-core';
import OrdersService from '../../services/OrdersService';
import ProductsService from '../../services/ProductsService';
import TleService from '../../services/TleService';
import AddEditOrderDialog from '../Order/AddEditOrderDialog';
import './DashboardComponent.scss';
import {RequestJobDialog} from '../Job/RequestJobDialog/RequestJobDialog';
import CompaniesService from '../../services/CompaniesService';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Subscription} from 'rxjs';
import {localStorageAttributes} from '../../config/localStorageAttributes';

interface State {
  showAddEditDialog: boolean;
  newOrder?: PaOrder;
  loading: boolean;
  showRequestJobDialog: boolean;
}

class QuickMenuComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  ordersService: OrdersService | null = null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;
  productsService: ProductsService | null = null;
  companiesService: CompaniesService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: {}) {
    super(props);
    this.state = {
      showAddEditDialog: false,
      loading: false,
      showRequestJobDialog: false,
    };

    this.hideEditDialog = this.hideEditDialog.bind(this);
    this.onShowAddDialog = this.onShowAddDialog.bind(this);
    this.onShowRequestJobDialog = this.onShowRequestJobDialog.bind(this);
    this.onHideRequestJobDialog = this.onHideRequestJobDialog.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.productsService = this.context.productsService;
    this.companiesService = this.context.companiesService;
  }

  hideEditDialog() {
    this.setState({newOrder: undefined, showAddEditDialog: false});
  }

  onShowAddDialog() {
    const ownerId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    const newOrder: PaOrder = {
      stage: 'Estimate',
      type: 'Standard',
      priority: 1,
      owner: ownerId,
      items: [],
      freight_options: {
        freight_type: 'made2freight',
      },
      reference: '',
      shipping_address: {} as LocationReference,
    };
    this.setState({newOrder: newOrder, showAddEditDialog: true});
  }

  onShowRequestJobDialog() {
    this.setState({showRequestJobDialog: true});
  }

  onHideRequestJobDialog() {
    this.setState({showRequestJobDialog: false});
  }

  render() {
    const {loading} = this.state;
    const companyFittingTypes = localStorage.getItem(localStorageAttributes.fittingTypes) as FittingType | undefined;
    if (loading) {
      return <ProgressSpinner />;
    }
    return (
      <div className="p-d-flex p-lg-12 p-sm-12">
        <div className="p-lg-6 left">
          <Button className="p-button-sm p-button-rounded quick-action-btn p-m-1" onClick={this.onShowAddDialog}>
            <FontAwesomeIcon className="p-pr-3" icon={['far', 'plus-circle']} />
            <span className="p-button-label p-c">Place Order</span>
          </Button>
          {(companyFittingTypes && companyFittingTypes) !== 'none' && (
            <Button
              className="p-button-sm p-button-rounded quick-action-btn p-m-1"
              onClick={this.onShowRequestJobDialog}
            >
              <FontAwesomeIcon className="p-pr-3" icon={['far', 'plus-circle']} />
              <span className="p-button-label p-c">Request Fitting</span>
            </Button>
          )}
          <Button className="p-button-sm p-button-rounded quick-action-btn p-m-1">
            <Link to="/containers" className="btn btn-primary">
              <FontAwesomeIcon className="p-pr-3" icon={['far', 'truck']} />
              <span className="p-button-label p-c">Delivery Dockets</span>
            </Link>
          </Button>
          <Button className="p-button-sm p-button-rounded quick-action-btn p-m-1">
            <FontAwesomeIcon className="p-pr-3" icon={['far', 'dollar']} />
            <span className="p-button-label p-c t-4 ">Reward Points</span>
          </Button>
        </div>
        <div className="right p-lg-6">
          <div className="position-r">
            <Button className="p-button-sm p-button-rounded quick-action-btn p-m-1">
              <a href="https://www.two-australia.com/dealer-center/">
                <FontAwesomeIcon className="p-pr-3" icon={['far', 'analytics']} />
                <span className="p-button-label p-c">{'Sales & Marketing'}</span>
              </a>
            </Button>
            <Button className="p-button-sm p-button-rounded quick-action-btn p-m-1">
              <a href="https://www.two-australia.com/oos-items/">
                <FontAwesomeIcon className="p-pr-3" icon={['far', 'calendar-exclamation']} />
                <span className="p-button-label p-c">Out of Stock</span>
              </a>
            </Button>
            <Button className="p-button-sm p-button-rounded quick-action-btn p-m-1">
              <a href="https://www.two-australia.com/product-lead-times/">
                <FontAwesomeIcon className="p-pr-3" icon={['far', 'clock']} />
                <span className="p-button-label p-c">Lead Times</span>
              </a>
            </Button>
          </div>
        </div>
        <AddEditOrderDialog
          toast={this.toast}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideEditDialog}
          newOrder={this.state.newOrder}
        />
        <RequestJobDialog
          showDialog={this.state.showRequestJobDialog}
          onHide={this.onHideRequestJobDialog}
          toast={this.toast}
        />
        <Toast ref={this.toast} />
      </div>
    );
  }
}
export default QuickMenuComponent;
