import React from 'react';
import {AppContext, MessageService, OrderItemsComponent, ToastService, TwoDialog} from 'two-app-ui';
import {Field, OrderItem, PaOrder, PaOrderPatch, PaProductDefinition, QueryParameter} from 'two-core';
import {Toast} from 'primereact/toast';
import OrdersService from '../../services/OrdersService';
import './Order.scss';
import {messages} from '../../config/messages';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import ProductsService from '../../services/ProductsService';
import {NewProductDefinitionDialog} from '../Job/ReviewMeasureDialog/NewProductDefinitionDialog';
import AddEditOrderDialog from './AddEditOrderDialog';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  orderId: string;
}

interface State {
  loading: boolean;
  saving: boolean;
  order?: PaOrder;
  orderPatch?: PaOrderPatch;
  orderProductDefinitions?: PaProductDefinition[];
  newestProductDefinitions?: PaProductDefinition[];
  showNewProductDefinitionDialog: boolean;
  forceCurrentProductDefinition: boolean;
}

class SubmitOrderConfirmDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;
  productsService: ProductsService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      saving: false,
      showNewProductDefinitionDialog: false,
      forceCurrentProductDefinition: false,
    };

    this.onHide = this.onHide.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
    this.productsService = this.context.productsService;
  }

  async loadData() {
    const {orderId, toast} = this.props;
    this.setState({loading: true});
    const order = await this.loadOrder(orderId);
    let orderProductDefinitions;
    let newestProductDefinitions;
    if (order) {
      const productDefinitionsResult = await this.loadProductDefinitions(order.revision_id!, order.owner!);
      orderProductDefinitions = productDefinitionsResult?.orderProductDefinitions;
      newestProductDefinitions = productDefinitionsResult?.newestProductDefinitions;
    }
    if (order && orderProductDefinitions && newestProductDefinitions) {
      this.setState({
        order,
        orderProductDefinitions,
        newestProductDefinitions,
        loading: false,
      });
    } else {
      this.setState({loading: false});
      this.toastService?.showError(toast, 'Sorry, data load failed, please try again.');
    }
  }

  async loadOrder(orderId: string) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: orderId,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    return this.ordersService!.getOrders(params).then(data => {
      return (data.records as PaOrder[])?.[0];
    });
  }

  async loadProductDefinitions(orderRevisionId: number, ownerId: string) {
    const orderProductDefinitionsPromise = this.productsService!.getProductsDefinitions(orderRevisionId, ownerId);
    const newestProductDefinitionsPromise = this.productsService!.getProductsDefinitions(undefined, ownerId);

    return Promise.all([orderProductDefinitionsPromise, newestProductDefinitionsPromise]).then(results => {
      const [orderResult, newestResult] = results;
      const orderProductDefinitions = (orderResult.records as PaProductDefinition[]) ?? [];
      const newestProductDefinitions = (newestResult.records as PaProductDefinition[]) ?? [];
      return {
        orderProductDefinitions,
        newestProductDefinitions,
      };
    });
  }

  onHide() {
    this.setState({
      loading: false,
      saving: false,
      order: undefined,
      orderPatch: undefined,
      orderProductDefinitions: undefined,
      newestProductDefinitions: undefined,
      showNewProductDefinitionDialog: false,
      forceCurrentProductDefinition: false,
    });
    this.props.onHide();
  }

  onSubmit() {
    const {orderProductDefinitions, newestProductDefinitions, forceCurrentProductDefinition} = this.state;
    if (
      !forceCurrentProductDefinition &&
      orderProductDefinitions?.length &&
      newestProductDefinitions?.length &&
      orderProductDefinitions[0].revision_id !== newestProductDefinitions[0].revision_id
    ) {
      this.setState({showNewProductDefinitionDialog: true});
    } else {
      this.updateOrder();
    }
  }

  onShow() {
    this.setState({loading: false});
    this.loadData();
  }

  async updateOrder() {
    const {order, orderPatch, forceCurrentProductDefinition, newestProductDefinitions} = this.state;
    this.setState({saving: true});
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    const newOrderPatch: PaOrderPatch = {...orderPatch, stage: 'New'};
    if (forceCurrentProductDefinition) {
      newOrderPatch.revision_id = newestProductDefinitions![0].revision_id;
    }
    return this.ordersService
      ?.updateOrder(companyId, order!.id!, newOrderPatch)
      .then(() => {
        this.toastService?.showSuccess(
          this.props.toast,
          `Submission of ${order!.id} ${
            orderPatch?.reference ?? order!.reference
          } successful. Thank you for your business.`
        );
        MessageService.sendMessage(messages.orderUpdated);
        this.onHide();
      })
      .catch(() => {
        this.toastService?.showError(
          this.props.toast,
          `Submitting the order ${order!.id} ${orderPatch?.reference ?? order!.reference} failed. Please, try again.`
        );
        this.setState({saving: false});
      });
  }

  render() {
    const {order, orderPatch, forceCurrentProductDefinition, showNewProductDefinitionDialog, saving} = this.state;

    const dialogBody = (
      <>
        <div className="w-100 p-mx-2">
          <p className="p-text-bold ">You are about to submit this order! Are you sure?</p>
          <p>
            Once the order is submitted, changes can not be made to the order. If unsure, keep the order as an Estimate
            until you are ready to submit the order.
          </p>
        </div>
        <NewProductDefinitionDialog
          showDialog={showNewProductDefinitionDialog}
          onCancel={() => this.setState({showNewProductDefinitionDialog: false})}
          onYes={() =>
            this.setState({
              forceCurrentProductDefinition: true,
              showNewProductDefinitionDialog: false,
            })
          }
        />
        <AddEditOrderDialog
          showDialog={forceCurrentProductDefinition}
          onHide={this.onHide}
          toast={this.props.toast}
          forceCurrentProductDefinition
          order={order}
        />
      </>
    );

    const orderId = order?.id ?? '';
    const orderReference = orderPatch?.reference ?? order?.reference ?? '';
    const headerTitle = `Submit Order ${orderId} ${orderReference}`;

    return (
      <TwoDialog
        header={headerTitle}
        showDialog={this.props.showDialog}
        className={'p-col-10 p-col-offset-1'}
        onHide={this.onHide}
        loading={this.state.loading}
        saveButtonTitle="Submit Order"
        saving={saving}
        onSave={this.onSubmit}
        onShow={this.onShow}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default SubmitOrderConfirmDialog;
