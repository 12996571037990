import {Column} from 'primereact/column';
import {
  DataTableExpandedRows,
  DataTablePageParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {Toast} from 'primereact/toast';
import React from 'react';
import {Subscription} from 'rxjs';
import {AppContext, MessageService, ToastService, TwoDataTable, TwoMessage} from 'two-app-ui';
import {Container, QueryParameter} from 'two-core';
import {messages} from '../../config/messages';
import ContainersService from '../../services/ContainersService';
import GroupOrdersComponent from '../GroupOrders/GroupOrdersComponent';
import config from '../../config/config';

interface State {
  loading: boolean;
  items: Location[];
  totalItems: number;
  activeFilters: {};
  filters: {
    container: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  containers: Container[];
  expandedRows: DataTableExpandedRows[];
}

class ContainersListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  containersService: ContainersService | null = null;
  toastService: ToastService | null = null;
  subscription: Subscription = new Subscription();
  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: {}) {
    super(props);

    this.state = {
      items: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        container: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      containers: [],
      expandedRows: [],
    };
    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  async componentDidMount() {
    this.containersService = this.context.containersService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.orderUpdated) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === messages.topSelectionChanged) {
          this.loadData();
        }
      }
    });
    this.loadData();
  }

  loadData() {
    this.setState({loading: true});
    const defaultFilters: string[] = [];
    const filters: string[] = [];

    if (this.state.filters.container) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.container,
          condition: 'iLike',
        })
      );
    }

    this.setState({activeFilters: {...filters}});
    filters.push(...defaultFilters);

    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'id':
        sortBy.field = ':id';
        break;
      case 'etaToPort':
        sortBy.field = ':eta_to_port';
        break;
    }

    const sortByStringyField = JSON.stringify(sortBy.field ? sortBy : {field: 'eta_to_port', direction: 'ASC'});

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyField],
      aggregate: true,
      calculate_values: true,
      showAll: true,
    };

    this.containersService
      ?.getContainers(params)
      .then(data => {
        const containers = (data?.records as Container[]) ?? [];
        this.setState({
          containers: containers,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  rowExpansionTemplate = (container: Container) => {
    return <GroupOrdersComponent container={container} heightToScroll={'max'} hideFilter={true} />;
  };

  handleFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  render() {
    const containerFilter = (
      <InputText
        name="container"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="location_list_page_container" className="page-container">
        <TwoDataTable
          pageSizeIdentifier={'location_list_page_container'}
          sizeIdentifiers={['filter-box']}
          selectedItems={[]}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          value={this.state.containers}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          rowExpansionTemplate={this.rowExpansionTemplate}
          expandedRows={this.state.expandedRows}
          onRowToggle={e => {
            this.setState({expandedRows: e.data});
          }}
          selection
          columnClassName="no-selection"
        >
          <Column expander className={'table-expander'} bodyClassName={'table-expander'} />
          <Column
            header="Container"
            field="id"
            filter
            sortable
            filterElement={containerFilter}
            showFilterMenu={false}
            style={{width: '150px'}}
          />
          <Column header="Stage" field="stage" style={{width: '150px'}} />
          <Column header="Orders" field="number_of_orders" style={{width: '150px'}} />
          <Column header="Shipped to" field="destination_state_id" style={{width: '150px'}} />
          <Column header="ETA to PORT" field="etaToPort" sortable style={{width: '150px'}} />
        </TwoDataTable>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default ContainersListComponent;
