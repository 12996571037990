import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {
  ApiListResponse,
  CheckMeasureOriginalContent,
  ConsultOriginalContent,
  DcmContent,
  Field,
  InstallationInfo,
  Job,
  JobDocument,
  JobDocumentPatch,
  JobDocumentType,
  JobPatch,
  Location,
  LocationReference,
  OrderItem,
  OriginalContent,
  PaOrder,
  PaOrderPatch,
  PaProductDefinition,
  QueryParameter,
} from 'two-core';
import {SelectButton} from 'primereact/selectbutton';
import ProductsService from '../../../services/ProductsService';
import JobDocumentsService from '../../../services/JobDocumentsService';
import {DcmView} from './View/DcmView';
import {OriginalRequest} from '../../JobDocument/Original/OriginalRequest';
import {InfoView} from './View/InfoView';
import {Button} from 'primereact/button';
import OrdersService from '../../../services/OrdersService';
import {localStorageAttributes} from '../../../config/localStorageAttributes';
import JobsService from '../../../services/JobsService';
import {messages} from '../../../config/messages';
import {ShippingAddressDialog} from '../../ShippingAddressDialog/ShippingAddressDialog';
import {Toast} from 'primereact/toast';
import {NewProductDefinitionDialog} from './NewProductDefinitionDialog';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  job: Job;
  toast: React.RefObject<Toast>;
}

interface State {
  activeView: ReviewMeasureView;
  original?: JobDocument;
  dcm?: JobDocument;
  loading?: boolean;
  dcmSaving?: boolean;
  estimateSaving?: boolean;
  estimateSubmitting?: boolean;
  dcmProductDefinitions?: PaProductDefinition[];
  originalProductDefinitions?: PaProductDefinition[];
  newestProductDefinitions?: PaProductDefinition[];
  clonedItems?: OrderItem[];
  invalidItemMessagesMap?: Map<number, string[]>;
  dcmPatch: JobDocumentPatch;
  showValidation: boolean;
  showShippingAddressDialog: boolean;
  submitNewOrder?: boolean;
  shippingAddressRef?: LocationReference;
  showNewProductDefinitionDialog: boolean;
  forceCurrentProductDefinition: boolean;
}

type ReviewMeasureView = 'original' | 'dcm' | 'info';

const headerOptions: {label: string; value: ReviewMeasureView}[] = [
  {label: 'Original', value: 'original'},
  {label: 'DCM', value: 'dcm'},
  {label: 'Install Info', value: 'info'},
];

export class ReviewMeasureDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  toastService?: ToastService;
  productsService?: ProductsService;
  jobDocumentsService?: JobDocumentsService;
  ordersService?: OrdersService;
  jobsService?: JobsService;

  constructor(props: Props) {
    super(props);

    this.loadDocuments = this.loadDocuments.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.setInvalidItemMessagesMap = this.setInvalidItemMessagesMap.bind(this);
    this.onItemsChange = this.onItemsChange.bind(this);
    this.onReleaseNoteChange = this.onReleaseNoteChange.bind(this);
    this.onInstallationInfoChange = this.onInstallationInfoChange.bind(this);
    this.onSaveDcmClick = this.onSaveDcmClick.bind(this);
    this.onUpdateAndWait = this.onUpdateAndWait.bind(this);
    this.onUpdateAndWaitClick = this.onUpdateAndWaitClick.bind(this);
    this.onCreateAndWaitClick = this.onCreateAndWaitClick.bind(this);
    this.onUpdateAndSubmitClick = this.onUpdateAndSubmitClick.bind(this);
    this.onCreateAndSubmitClick = this.onCreateAndSubmitClick.bind(this);
    this.onCreateAndWait = this.onCreateAndWait.bind(this);
    this.onCreateAndSubmit = this.onCreateAndSubmit.bind(this);
    this.onShippingAddressChange = this.onShippingAddressChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onNewProductDefinitionDialogCancel = this.onNewProductDefinitionDialogCancel.bind(this);
    this.onNewProductDefinitionDialogYes = this.onNewProductDefinitionDialogYes.bind(this);
    this.onUpdateAndSubmit = this.onUpdateAndSubmit.bind(this);

    this.state = {
      activeView: 'dcm',
      invalidItemMessagesMap: new Map<number, string[]>(),
      dcmPatch: {},
      showValidation: false,
      showShippingAddressDialog: false,
      showNewProductDefinitionDialog: false,
      forceCurrentProductDefinition: false,
    };
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.productsService = this.context.productsService;
    this.jobDocumentsService = this.context.jobDocumentsService;
    this.ordersService = this.context.ordersService;
    this.jobsService = this.context.jobsService;
  }

  async loadData() {
    this.setState({loading: true});
    const {job} = this.props;
    const {original, dcm, clonedItems} = (await this.loadDocuments(job.id!)) ?? {};
    const dcmRevisionId = (dcm?.content as DcmContent).revision_id;
    const originalRevisionId = (dcm?.content as CheckMeasureOriginalContent).revision_id;
    const {dcmProductDefinitions, originalProductDefinitions, newestProductDefinitions} =
      (await this.loadProductDefinitions(dcmRevisionId, originalRevisionId)) ?? {};
    this.setState({
      original,
      dcm,
      clonedItems,
      dcmProductDefinitions,
      originalProductDefinitions,
      newestProductDefinitions,
      loading: false,
    });
  }

  async loadDocuments(jobId: string) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'job_id',
        value: jobId,
      })
    );
    const types: JobDocumentType[] = ['Original', 'DCM'];
    filters.push(
      JSON.stringify({
        field: 'type',
        value: types,
        condition: 'in',
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: false,
    };

    return this.jobDocumentsService!.getJobDocuments(params)
      .then((data: ApiListResponse) => {
        const documents = (data.records as JobDocument[]) ?? [];
        const original: JobDocument = documents.find(doc => doc.type === 'Original')!;
        const originalContent = original.content as OriginalContent;
        if (originalContent.requested_service === 'Check Measure & Install') {
          const checkMeasureContent = originalContent as CheckMeasureOriginalContent;
          (original.content as CheckMeasureOriginalContent).items = this.cloneItems(checkMeasureContent.items ?? []);
        } else if (originalContent.requested_service === 'Consult & Install') {
          const consultContent = originalContent as ConsultOriginalContent;
          if (consultContent.detail_level === 'draft' || consultContent.detail_level === 'estimate') {
            (original.content as ConsultOriginalContent).items = this.cloneItems(consultContent.items ?? []);
          }
        }
        const dcm: JobDocument = documents.find(doc => doc.type === 'DCM')!;
        const dcmContent = dcm.content as DcmContent;
        const clonedItems = this.cloneItems(dcmContent.items);
        return {original, dcm, clonedItems};
      })
      .catch(error => {
        // this.toastService?.showError(this.toast, 'Record load failed');
        console.log(error);
      });
  }

  async loadProductDefinitions(dcmRevisionId: number, originalRevisionId: number) {
    let originalProductDefinitionsPromise;
    const dcmProductDefinitionsPromise = this.productsService!.getProductsDefinitions(
      dcmRevisionId,
      this.props.job.owner_id
    );
    if (originalRevisionId && originalRevisionId !== dcmRevisionId) {
      originalProductDefinitionsPromise = this.productsService!.getProductsDefinitions(
        originalRevisionId,
        this.props.job.owner_id
      );
    }
    const newestProductDefinitionsPromise = this.productsService!.getProductsDefinitions(
      undefined,
      this.props.job.owner_id
    );

    return Promise.all([
      dcmProductDefinitionsPromise,
      originalProductDefinitionsPromise,
      newestProductDefinitionsPromise,
    ]).then(results => {
      const [dcmResult, originalResult, newestResult] = results;
      const dcmProductDefinitions = (dcmResult.records as PaProductDefinition[]) ?? [];
      let originalProductDefinitions;
      if (originalResult) {
        originalProductDefinitions = (originalResult?.records as PaProductDefinition[]) ?? [];
      } else if (dcmRevisionId === originalRevisionId) {
        originalProductDefinitions = dcmProductDefinitions;
      }
      const newestProductDefinitions = (newestResult.records as PaProductDefinition[]) ?? [];
      return {
        dcmProductDefinitions,
        originalProductDefinitions,
        newestProductDefinitions,
      };
    });
  }

  async updateJobDocument(dcmId: string, dcmPatch: JobDocumentPatch) {
    return this.jobDocumentsService
      ?.updateJobDocument(dcmId, dcmPatch)
      .then((dcm: JobDocument) => {
        return Promise.resolve(dcm);
      })
      .catch(error => {
        console.error('error: ' + error);
        return Promise.reject(error);
      });
  }

  async updateOrder(orderId: string, orderPatch: PaOrderPatch, companyId: string) {
    return this.ordersService
      ?.updateOrder(companyId, orderId, orderPatch)
      .then((order: PaOrder) => {
        return Promise.resolve(order);
      })
      .catch(error => {
        console.error('error: ' + error);
        return Promise.reject(error);
      });
  }

  async createOrder(newOrder: PaOrder, companyId: string) {
    return this.ordersService
      ?.createOrder(companyId, newOrder)
      .then((order: PaOrder) => {
        return Promise.resolve(order);
      })
      .catch(error => {
        console.error('error: ' + error);
        return Promise.reject(error);
      });
  }

  async updateJob(jobId: string, jobPatch: JobPatch) {
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    return this.jobsService
      ?.updateJob(jobId, jobPatch, companyId)
      .then((job: Job) => {
        return Promise.resolve(job);
      })
      .catch(error => {
        console.error('error: ' + error);
        return Promise.reject(error);
      });
  }

  setInvalidItemMessagesMap(newInvalidItemMessagesMap: Map<number, string[]>) {
    this.setState({
      invalidItemMessagesMap: newInvalidItemMessagesMap,
    });
  }

  cloneItems(items: OrderItem[]) {
    const clonedItems = (structuredClone(items) as OrderItem[]).map(item => {
      const fields: Field[] = item.fields.map(field => {
        //we must convert field to class object
        const values = field.values.map(fieldValue => {
          if (fieldValue.sub_fields) {
            fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
              //we must convert subfield to class object
              return new Field(subField);
            });
          }
          return fieldValue;
        });
        return new Field({...field, values: values});
      });
      item.fields = fields;
      return new OrderItem(item);
    });
    return clonedItems;
  }

  validate(dcmOnly?: boolean): boolean {
    const {job} = this.props;
    const {dcm, dcmPatch, invalidItemMessagesMap, shippingAddressRef} = this.state;
    const dcmContent = (dcmPatch.content ?? dcm?.content) as DcmContent;

    const errors: string[] = [];
    const itemsErrors = [];
    if (!dcmContent.items?.length) {
      errors.push('Order must have at least one item.');
    }
    if (invalidItemMessagesMap?.size) {
      for (const [itemIndex, messages] of Array.from(invalidItemMessagesMap.entries())) {
        itemsErrors.push(
          <div>
            Item {itemIndex} is invalid:
            {messages.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </div>
        );
      }
    }
    if (dcmPatch && dcmPatch.content && (dcmPatch.content as DcmContent).installation_info) {
      const infoPatch = (dcmPatch.content as DcmContent).installation_info!;
      if (!infoPatch.time_required || !infoPatch.fitters_required) {
        errors.push('Installation Info incomplete.');
      }
    }
    if (!dcmOnly && job.order_id && !shippingAddressRef) {
      errors.push('Shipping address is empty.');
    }

    if (errors.length || itemsErrors.length) {
      const errorText = (
        <>
          {!!errors.length && (
            <div>
              Form is invalid:
              {errors.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </div>
          )}
          {!!itemsErrors.length && itemsErrors}
        </>
      );
      this.toastService?.showError(this.props.toast, errorText);
      this.setState({
        showValidation: true,
      });
      MessageService.sendMessage(messages.orderCanNotBeSaved);
      return false;
    }
    return true;
  }

  onHide() {
    this.setState({
      activeView: 'dcm',
      original: undefined,
      dcm: undefined,
      loading: false,
      dcmSaving: false,
      estimateSaving: false,
      estimateSubmitting: false,
      dcmProductDefinitions: undefined,
      originalProductDefinitions: undefined,
      newestProductDefinitions: undefined,
      clonedItems: undefined,
      invalidItemMessagesMap: new Map<number, string[]>(),
      dcmPatch: {},
      showShippingAddressDialog: false,
      submitNewOrder: false,
      showValidation: false,
      shippingAddressRef: undefined,
      showNewProductDefinitionDialog: false,
      forceCurrentProductDefinition: false,
    });
    this.props.onHide();
  }

  onShow() {
    this.loadData();
  }

  onSaveDcmClick() {
    if (!this.validate(true)) {
      return;
    }
    const {dcm, dcmPatch} = this.state;
    this.setState({dcmSaving: true});
    this.updateJobDocument(dcm!.id!, dcmPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'DCM updated.');
        this.onHide();
        MessageService.sendMessage(messages.jobUpdated);
      })
      .catch(error => {
        console.error(error);
        this.toastService?.showError(
          this.props.toast,
          'Sorry, update failed, please check your connectivity and try again.'
        );
      })
      .finally(() => this.setState({dcmSaving: false}));
  }

  onUpdateAndWaitClick() {
    const {dcmProductDefinitions, newestProductDefinitions, forceCurrentProductDefinition} = this.state;
    if (
      !forceCurrentProductDefinition &&
      dcmProductDefinitions?.length &&
      newestProductDefinitions?.length &&
      dcmProductDefinitions[0].revision_id !== newestProductDefinitions[0].revision_id
    ) {
      this.setState({showNewProductDefinitionDialog: true});
    } else {
      this.onUpdateAndWait();
    }
  }

  //updates dcm, estimate and ff2 estimate and moves the job into Measure Approval Pending stage
  onUpdateAndWait() {
    if (!this.validate(true)) {
      return;
    }
    const {job} = this.props;
    const {dcm, dcmPatch, newestProductDefinitions} = this.state;
    this.setState({estimateSaving: true});

    const newDcmContent = {
      ...((dcmPatch.content ?? dcm?.content) as DcmContent),
    };
    if (this.state.forceCurrentProductDefinition) {
      newDcmContent.revision_id = newestProductDefinitions![0].revision_id;
    }
    const newDcmPatch = {...dcmPatch, content: newDcmContent};

    this.updateJobDocument(dcm!.id!, newDcmPatch)
      .then(() => {
        //DCM update
        this.toastService?.showSuccess(this.props.toast, 'DCM updated.');
        const orderPatch: PaOrderPatch = {
          items: newDcmContent.items!,
          revision_id: newDcmContent.revision_id,
          reference: `${job.title}${job.lead_fitter?.label ? ' (' + this.getInitials(job.lead_fitter.label) + ')' : ''}`,
        };
        this.updateOrder(job!.order_id!, orderPatch, job.owner_id!)
          .then(() => {
            //estimate updated
            this.toastService?.showSuccess(this.props.toast, 'Estimate updated.');
            this.toastService?.showSuccess(this.props.toast, 'FF2 Estimate updated.');
            const jobPatch: JobPatch = {
              stage: 'Measure Approval Pending',
            };
            if (newDcmContent && newDcmContent.installation_info) {
              jobPatch.installation_info = newDcmContent.installation_info;
            }
            this.jobsService
              ?.updateJob(job.id!, jobPatch, job.owner_id!)
              .then(() => {
                this.toastService?.showSuccess(
                  this.props.toast,
                  'Job install info updated and Job moved to stage Measure Approval Pending.'
                );
                this.onHide();
                MessageService.sendMessage(messages.jobUpdated);
              })
              .catch(() => {
                this.toastService?.showError(
                  this.props.toast,
                  'Job update failed. Your DCM, Estimate and FF2 estimate have been updated, but the job failed to transfer to the next stage. Please let DevOps know.'
                );
                this.onHide();
                MessageService.sendMessage(messages.jobUpdated);
              });
          })
          .catch(() => {
            this.toastService?.showError(
              this.props.toast,
              'Estimate update failed. Your changes have been saved, but the update into FF2 failed. Hence, the Job stage transition has been halted. DevOps have been informed.'
            );
            this.onHide();
            MessageService.sendMessage(messages.jobUpdated);
          });
      })
      .catch(() => {
        this.toastService?.showError(
          this.props.toast,
          'DCM update failed. Please check your internet connection and try again.'
        );
        this.setState({estimateSaving: false});
      });
  }

  onUpdateAndSubmitClick() {
    const {dcmProductDefinitions, newestProductDefinitions, forceCurrentProductDefinition} = this.state;
    if (!this.validate(true)) {
      return;
    }
    if (
      !forceCurrentProductDefinition &&
      dcmProductDefinitions?.length &&
      newestProductDefinitions?.length &&
      dcmProductDefinitions[0].revision_id !== newestProductDefinitions[0].revision_id
    ) {
      this.setState({showNewProductDefinitionDialog: true});
    } else {
      this.onUpdateAndSubmit();
    }
  }

  //updates dcm, estimate and ff2 estimate, submits the ff2 estimate, moves estimate into New and moves the job into In Production
  onUpdateAndSubmit() {
    const {job} = this.props;
    const {dcm, dcmPatch, newestProductDefinitions} = this.state;
    this.setState({estimateSubmitting: true});

    const newDcmContent = {
      ...((dcmPatch.content ?? dcm?.content) as DcmContent),
    };
    if (this.state.forceCurrentProductDefinition) {
      newDcmContent.revision_id = newestProductDefinitions![0].revision_id;
    }
    const newDcmPatch = {...dcmPatch, content: newDcmContent};

    this.updateJobDocument(dcm!.id!, newDcmPatch)
      .then(() => {
        //DCM update
        this.toastService?.showSuccess(this.props.toast, 'DCM updated.');
        const orderPatch: PaOrderPatch = {
          items: newDcmContent.items!,
          revision_id: newDcmContent.revision_id,
          stage: 'New',
          reference: `${job.title}${job.lead_fitter?.label ? ' (' + this.getInitials(job.lead_fitter.label) + ')' : ''}`,
        };
        this.updateOrder(job!.order_id!, orderPatch, job.owner_id!)
          .then(() => {
            //estimate updated
            this.toastService?.showSuccess(this.props.toast, 'Estimate updated.');
            this.toastService?.showSuccess(this.props.toast, 'FF2 Estimate updated and submitted.');
            const jobPatch: JobPatch = {
              stage: 'In Production',
            };
            if (newDcmContent && newDcmContent.installation_info) {
              jobPatch.installation_info = newDcmContent.installation_info;
            }
            this.jobsService
              ?.updateJob(job.id!, jobPatch, job.owner_id!)
              .then(() => {
                this.toastService?.showSuccess(
                  this.props.toast,
                  'Job install info updated and Job moved to stage In Production.'
                );
                this.onHide();
                MessageService.sendMessage(messages.jobUpdated);
              })
              .catch(() => {
                this.toastService?.showError(
                  this.props.toast,
                  'Job update failed. Your DCM, Estimate and FF2 estimate have been updated, but the job failed to transfer to the next stage. Please let DevOps know.'
                );
                this.onHide();
                MessageService.sendMessage(messages.jobUpdated);
              });
          })
          .catch(() => {
            this.toastService?.showError(
              this.props.toast,
              'Estimate update failed. Your changes have been saved, but the update into FF2 failed. Hence, the Job stage transition has been halted. DevOps have been informed.'
            );
            this.onHide();
            MessageService.sendMessage(messages.jobUpdated);
          });
      })
      .catch(() => {
        this.toastService?.showError(
          this.props.toast,
          'DCM update failed. Please check your internet connection and try again.'
        );
        this.setState({estimateSubmitting: false});
      });
  }

  getInitials(name: string): string {
    return name
      .split(' ')
      .map(word => word[0].toUpperCase())
      .join('');
  }

  onCreateAndSubmitClick() {
    const {dcmProductDefinitions, newestProductDefinitions, forceCurrentProductDefinition} = this.state;
    if (
      !forceCurrentProductDefinition &&
      dcmProductDefinitions?.length &&
      newestProductDefinitions?.length &&
      dcmProductDefinitions[0].revision_id !== newestProductDefinitions[0].revision_id
    ) {
      this.setState({showNewProductDefinitionDialog: true});
    } else {
      this.setState({submitNewOrder: true, showShippingAddressDialog: true});
    }
  }

  async onCreateAndSubmit() {
    if (!this.validate()) {
      return;
    }
    const {job} = this.props;
    const {dcm, dcmPatch, shippingAddressRef, newestProductDefinitions} = this.state;
    this.setState({
      estimateSubmitting: true,
      showShippingAddressDialog: false,
    });

    const newDcmContent = {
      ...((dcmPatch.content ?? dcm?.content) as DcmContent),
    };
    if (this.state.forceCurrentProductDefinition) {
      newDcmContent.revision_id = newestProductDefinitions![0].revision_id;
    }
    const newDcmPatch = {...dcmPatch, content: newDcmContent};

    this.updateJobDocument(dcm!.id!, newDcmPatch)
      .then(() => {
        //DCM update
        this.toastService?.showSuccess(this.props.toast, 'DCM updated.');
        const newOrder: PaOrder = {
          stage: 'New',
          type: 'Standard',
          priority: 1,
          owner: job.owner_id,
          items: newDcmContent.items,
          freight_options: {
            freight_type: 'made2freight',
          },
          reference: job.title,
          shipping_address: shippingAddressRef!,
          revision_id: newDcmContent.revision_id,
        };
        this.createOrder(newOrder, job.owner_id!)
          .then(orderCreateResponse => {
            //estimate created
            this.toastService?.showSuccess(this.props.toast, 'Estimate created.');
            this.toastService?.showSuccess(this.props.toast, 'FF2 Estimate created and submitted.');
            const jobPatch: JobPatch = {
              stage: 'In Production',
              order_id: orderCreateResponse?.id,
            };
            if (newDcmContent && newDcmContent.installation_info) {
              jobPatch.installation_info = newDcmContent.installation_info;
            }
            this.jobsService
              ?.updateJob(job.id!, jobPatch, job.owner_id!)
              .then(() => {
                this.toastService?.showSuccess(
                  this.props.toast,
                  'Job install info updated and Job moved to stage In Production.'
                );
                this.onHide();
                MessageService.sendMessage(messages.jobUpdated);
              })
              .catch(() => {
                this.toastService?.showError(
                  this.props.toast,
                  'Job update failed. Your DCM, Estimate and FF2 estimate have been updated, but the job failed to transfer to the next stage. Please let DevOps know.'
                );
                this.onHide();
                MessageService.sendMessage(messages.jobUpdated);
              });
          })
          .catch(() => {
            this.toastService?.showError(
              this.props.toast,
              'Estimate update failed. Your changes have been saved, but creating your estimate in FF2 failed. Hence, the Job stage transition has been halted. DevOps have been informed.'
            );
            this.onHide();
            MessageService.sendMessage(messages.jobUpdated);
          });
      })
      .catch(() => {
        this.toastService?.showError(
          this.props.toast,
          'DCM update failed. Please check your internet connection and try again.'
        );
        this.setState({estimateSubmitting: false});
      });
  }

  onCreateAndWaitClick() {
    const {dcmProductDefinitions, newestProductDefinitions, forceCurrentProductDefinition} = this.state;
    if (
      !forceCurrentProductDefinition &&
      dcmProductDefinitions?.length &&
      newestProductDefinitions?.length &&
      dcmProductDefinitions[0].revision_id !== newestProductDefinitions[0].revision_id
    ) {
      this.setState({showNewProductDefinitionDialog: true});
    } else {
      this.setState({submitNewOrder: false, showShippingAddressDialog: true});
    }
  }

  onCreateAndWait() {
    if (!this.validate()) {
      return;
    }
    const {job} = this.props;
    const {dcm, dcmPatch, shippingAddressRef, newestProductDefinitions} = this.state;
    this.setState({
      estimateSaving: true,
      showShippingAddressDialog: false,
    });

    const newDcmContent = {
      ...((dcmPatch.content ?? dcm?.content) as DcmContent),
    };
    if (this.state.forceCurrentProductDefinition) {
      newDcmContent.revision_id = newestProductDefinitions![0].revision_id;
    }
    const newDcmPatch = {...dcmPatch, content: newDcmContent};

    this.updateJobDocument(dcm!.id!, newDcmPatch)
      .then(() => {
        //DCM update
        this.toastService?.showSuccess(this.props.toast, 'DCM updated.');
        const newOrder: PaOrder = {
          stage: 'Estimate',
          type: 'Standard',
          priority: 1,
          owner: job.owner_id,
          items: newDcmContent.items,
          freight_options: {
            freight_type: 'made2freight',
          },
          reference: job.title,
          shipping_address: shippingAddressRef!,
          revision_id: newDcmContent.revision_id,
        };
        this.createOrder(newOrder, job.owner_id!)
          .then(orderCreateResponse => {
            //estimate created
            this.toastService?.showSuccess(this.props.toast, 'Estimate created.');
            this.toastService?.showSuccess(this.props.toast, 'FF2 Estimate created.');
            const jobPatch: JobPatch = {
              stage: 'Measure Approval Pending',
              order_id: orderCreateResponse?.id,
            };
            if (newDcmContent && newDcmContent.installation_info) {
              jobPatch.installation_info = newDcmContent.installation_info;
            }
            this.jobsService
              ?.updateJob(job.id!, jobPatch, job.owner_id!)
              .then(() => {
                this.toastService?.showSuccess(
                  this.props.toast,
                  'Job install info updated and Job moved to stage In Production.'
                );
                this.onHide();
                MessageService.sendMessage(messages.jobUpdated);
              })
              .catch(() => {
                this.toastService?.showError(
                  this.props.toast,
                  'Job update failed. Your DCM, Estimate and FF2 estimate have been updated, but the job failed to transfer to the next stage. Please let DevOps know.'
                );
                this.onHide();
                MessageService.sendMessage(messages.jobUpdated);
              });
          })
          .catch(() => {
            this.toastService?.showError(
              this.props.toast,
              'Estimate creation failed. Your changes have been saved, but creating your estimate in FF2 failed. Hence, the Job stage transition has been halted. DevOps have been informed.'
            );
            this.onHide();
            MessageService.sendMessage(messages.jobUpdated);
          });
      })
      .catch(() => {
        this.toastService?.showError(
          this.props.toast,
          'DCM update failed. Please check your internet connection and try again.'
        );
        this.setState({estimateSaving: false});
      });
  }

  onItemsChange(newItems: OrderItem[]) {
    const {dcmPatch, dcm} = this.state;

    const updatedContent = (dcmPatch?.content ? {...dcmPatch.content} : {...dcm!.content}) as DcmContent;
    updatedContent.items = newItems;
    const updatedDcmPatch: JobDocumentPatch = {
      ...dcmPatch,
      content: updatedContent,
    };
    this.setState({
      dcmPatch: updatedDcmPatch,
      clonedItems: newItems,
    });
  }

  onReleaseNoteChange(newReleaseNote: string) {
    const {dcmPatch, dcm} = this.state;

    const updatedContent = (dcmPatch?.content ? {...dcmPatch.content} : {...dcm!.content}) as DcmContent;
    updatedContent.release_note = newReleaseNote;
    const updatedDcmPatch: JobDocumentPatch = {
      ...dcmPatch,
      content: updatedContent,
    };
    this.setState({
      dcmPatch: updatedDcmPatch,
    });
  }

  onInstallationInfoChange(fieldsToUpdate: Partial<InstallationInfo>) {
    const {dcmPatch, dcm} = this.state;
    const currentInstallInfo =
      dcmPatch && dcmPatch.content && (dcmPatch.content as DcmContent).installation_info
        ? (dcmPatch.content as DcmContent).installation_info
        : dcm && dcm.content && (dcm.content as DcmContent).installation_info
          ? (dcm.content as DcmContent).installation_info
          : {};
    const newDcmContentPatch: DcmContent = {
      ...(dcmPatch.content as DcmContent),
      installation_info: {
        ...currentInstallInfo,
        ...fieldsToUpdate,
      } as InstallationInfo,
    };
    this.setState({
      dcmPatch: {
        ...dcmPatch,
        content: newDcmContentPatch,
      },
    });
  }

  onShippingAddressChange(location: Location) {
    const newAddressRef: LocationReference = {
      address:
        location.address.street +
        ', ' +
        location.address.suburb +
        ', ' +
        location.address.state +
        ', ' +
        location.address.country,
      id: location.id!,
    };
    this.setState({shippingAddressRef: newAddressRef});
  }

  getOriginalItems(original?: JobDocument) {
    if (original) {
      const docContent = original.content as OriginalContent;
      if (docContent.requested_service === 'Consult & Install') {
        const consultContent = docContent as ConsultOriginalContent;
        if (consultContent.detail_level === 'draft' || consultContent.detail_level === 'estimate') {
          return consultContent.items;
        }
      } else if (docContent.requested_service === 'Check Measure & Install') {
        const measureContent = docContent as CheckMeasureOriginalContent;
        return measureContent.items;
      }
    }
    return undefined;
  }

  onNewProductDefinitionDialogCancel() {
    this.setState({showNewProductDefinitionDialog: false});
  }

  onNewProductDefinitionDialogYes() {
    this.setState({
      forceCurrentProductDefinition: true,
      showNewProductDefinitionDialog: false,
    });
  }

  render() {
    const {showDialog, job, toast} = this.props;
    const {
      activeView,
      clonedItems,
      loading,
      dcm,
      dcmPatch,
      original,
      originalProductDefinitions,
      dcmProductDefinitions,
      showValidation,
      showShippingAddressDialog,
      submitNewOrder,
      showNewProductDefinitionDialog,
      forceCurrentProductDefinition,
      newestProductDefinitions,
      dcmSaving,
      estimateSaving,
      estimateSubmitting,
    } = this.state;

    const originalItems = this.getOriginalItems(original);
    const dcmContent = dcm?.content as DcmContent;
    const dcmPatchContent = dcmPatch?.content as DcmContent;

    let oldDcmProductDefinitions;
    let currentDcmProductDefinitions;
    if (forceCurrentProductDefinition) {
      oldDcmProductDefinitions = dcmProductDefinitions;
      currentDcmProductDefinitions = newestProductDefinitions;
    } else {
      currentDcmProductDefinitions = dcmProductDefinitions;
    }

    const saving = dcmSaving || estimateSaving || estimateSubmitting;
    const header = (
      <div className="p-d-flex p-jc-between p-ai-center w-100">
        <div>Review Measure for {job.title} </div>
        {!loading && (
          <div className="p-text-nowrap">
            <SelectButton
              value={activeView}
              options={headerOptions}
              onChange={e => e.value && this.setState({activeView: e.value})}
              disabled={saving}
            />
          </div>
        )}
      </div>
    );

    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className="p-button-text" onClick={this.onHide} autoFocus disabled={saving} />
        <Button
          className="p-ml-2"
          label="Save DCM"
          onClick={this.onSaveDcmClick}
          loading={dcmSaving}
          disabled={saving}
        />
        {job.order_id ? (
          <>
            <Button
              className="p-ml-2"
              label="Update Estimate & Wait for Approval"
              onClick={this.onUpdateAndWaitClick}
              loading={estimateSaving}
              disabled={saving}
            />
            <Button
              className="p-ml-2"
              label="Update Estimate & Submit"
              onClick={this.onUpdateAndSubmitClick}
              loading={estimateSubmitting}
              disabled={saving}
            />
          </>
        ) : (
          <>
            <Button
              className="p-ml-2"
              label="Create Estimate & Wait for Approval"
              onClick={this.onCreateAndWaitClick}
              loading={estimateSaving}
              disabled={saving}
            />
            <Button
              className="p-ml-2"
              label="Create Estimate & Submit"
              onClick={this.onCreateAndSubmitClick}
              loading={estimateSubmitting}
              disabled={saving}
            />
          </>
        )}
      </div>
    );
    return (
      <>
        <TwoDialog
          header={header}
          footer={footer}
          loading={loading}
          onHide={this.onHide}
          onShow={this.onShow}
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          draggable={false}
        >
          {activeView === 'original' && original && (
            <OriginalRequest
              loading={false}
              docContent={original.content as OriginalContent}
              productDefinitions={originalProductDefinitions}
            />
          )}
          {activeView === 'dcm' && dcm && dcmProductDefinitions && (
            <DcmView
              items={clonedItems ?? []}
              releaseNote={dcmPatchContent?.release_note ?? dcmContent?.release_note}
              originalItems={originalItems}
              onReleaseNoteChange={this.onReleaseNoteChange}
              productDefinitions={currentDcmProductDefinitions ?? []}
              originalProductDefinitions={oldDcmProductDefinitions}
              onItemsChange={this.onItemsChange}
              setInvalidItemMessagesMap={this.setInvalidItemMessagesMap}
              disabled={saving}
            />
          )}
          {activeView === 'info' && (
            <InfoView
              installationInfo={dcmPatchContent?.installation_info ?? dcmContent?.installation_info}
              onInstallationInfoChange={this.onInstallationInfoChange}
              readOnly={saving ?? false}
              showValidation={showValidation}
            />
          )}
          <ShippingAddressDialog
            forCompanyId={job.owner_id}
            showDialog={showShippingAddressDialog}
            onHide={() => this.setState({showShippingAddressDialog: false})}
            onSaveEstimateButtonClick={submitNewOrder ? this.onCreateAndSubmit : this.onCreateAndWait}
            onShippingAddressChange={this.onShippingAddressChange}
            toast={toast}
          />
          <NewProductDefinitionDialog
            showDialog={showNewProductDefinitionDialog}
            onCancel={this.onNewProductDefinitionDialogCancel}
            onYes={this.onNewProductDefinitionDialogYes}
          />
        </TwoDialog>
      </>
    );
  }
}
