import React from 'react';
import {OrderItemsComponent} from 'two-app-ui';
import {OrderItem, PaProductDefinition} from 'two-core';
import {InputTextarea} from 'primereact/inputtextarea';

interface Props {
  items: OrderItem[];
  releaseNote?: string;
  productDefinitions: PaProductDefinition[];
  originalProductDefinitions?: PaProductDefinition[];
  onItemsChange: (newItems: OrderItem[]) => void;
  onReleaseNoteChange: (newNote: string) => void;
  setInvalidItemMessagesMap?: (invalidItemMessagesMap: Map<number, string[]>) => void;
  collapsedItemIndexes?: Set<number>;
  onItemToggle?: (index: number) => void;
  originalItems?: OrderItem[];
  disabled?: boolean;
}

export const DcmView = ({
  items,
  releaseNote,
  productDefinitions,
  onItemsChange,
  onReleaseNoteChange,
  onItemToggle,
  setInvalidItemMessagesMap,
  collapsedItemIndexes,
  originalItems,
  originalProductDefinitions,
  disabled,
}: Props) => {
  return (
    <>
      <div className="p-grid p-ml-10 w-100">
        <label className="p-col-2 p-md-1">
          <small>release note</small>
        </label>
        <div className="p-col-10 p-md-11">
          <InputTextarea
            className="w-100"
            rows={3}
            value={releaseNote}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              onReleaseNoteChange(e.target.value);
            }}
            disabled={disabled}
          />
        </div>
      </div>
      <OrderItemsComponent
        mode={disabled ? 'readonly' : 'advanced_edit'}
        items={items}
        productDefinitions={productDefinitions}
        originalProductDefinitions={originalProductDefinitions}
        onItemsChanged={onItemsChange}
        setInvalidItemMessagesMap={setInvalidItemMessagesMap}
        outerItemColumnClassName={'p-col-12 p-md-6'}
        innerItemColumnClassName={'p-col-12'}
        collapsedItemIndexes={collapsedItemIndexes}
        onItemToggle={onItemToggle}
        showCopyLastButton
        originalItems={originalItems}
        comparisonMode={true}
      />
    </>
  );
};
