import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import {
  Address,
  ConsultSourceDetail,
  ConsultProduct,
  Draft,
  DropdownOption,
  PaContact,
  PaOrder,
  PaProductDefinition,
  RequestedServices,
} from 'two-core';
import {SiteAddressFields} from './SiteAddressFields';
import ProductsQtysComponent from './ProductsQtysComponent';

export const requestedServicesOptions: RequestedServices[] = ['Consult & Install', 'Check Measure & Install'];

export interface JobDetailInterface {
  ownerCompanyId?: string;
  ownerCompanyName?: string;
  dealerContact?: PaContact;
  siteAddress?: Address;
  requestedServices?: RequestedServices;
  clientFirstName?: string;
  clientLastName?: string;
  clientPhone?: string;
  clientEmail?: string;
  detailDepth?: ConsultSourceDetail;
  products?: ConsultProduct[];
  selectedEstimate?: PaOrder;
  selectedDraft?: Draft;
  measureNote?: string;
}

interface Props {
  requestedOnBehalf: boolean;
  contacts?: PaContact[];
  requestForExisting: boolean;
  jobDetails: JobDetailInterface;
  onChange: (fieldsData: JobDetailInterface) => void;
  doesQuotes: boolean;
  drafts?: Draft[];
  estimates?: PaOrder[];
  productDefinitions?: PaProductDefinition[];
  disableFields?: boolean;
}

export const NewJobDetailsComponent = (props: Props) => {
  const onDealerContactChange = (e: DropdownChangeParams) => {
    onChange('dealerContact', e.target.value);
  };

  const onRequestedServicesChange = (e: DropdownChangeParams) => {
    onChange('requestedServices', e.target.value);
  };

  const onMeasureNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange('measureNote', e.target.value);
  };

  const onClientFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange('clientFirstName', e.target.value);
  };

  const onClientLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange('clientLastName', e.target.value);
  };

  const onClientPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange('clientPhone', e.target.value);
  };

  const onClientEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange('clientEmail', e.target.value);
  };

  const onEstimateChange = (e: DropdownChangeParams) => {
    onChange('selectedEstimate', e.target.value);
  };

  const onDraftChange = (e: DropdownChangeParams) => {
    onChange('selectedDraft', e.target.value);
  };

  const onSiteAddressChange = (siteAddress: Address) => {
    onChange('siteAddress', siteAddress);
  };

  const onDepthChange = (e: DropdownChangeParams) => {
    onChange('detailDepth', e.target.value);
  };

  const onProductsChange = (products: ConsultProduct[]) => {
    onChange('products', products);
  };

  const onChange = (name: keyof JobDetailInterface, value: string | number | ConsultProduct[] | Address) => {
    props.onChange({...props.jobDetails, [name]: value});
  };

  const contactTemplate = (contact: PaContact): string | undefined => {
    let result = 'empty';
    if (contact) {
      result = `${contact.first_name} ${contact.last_name} [${contact.role}]`;
    }
    return result;
  };

  const estimateTemplate = (estimate: PaOrder): string | undefined => {
    let result = 'empty';
    if (estimate) {
      result = `${estimate.id} ${estimate.reference}`;
    }
    return result;
  };

  const draftTemplate = (draft: Draft): string | undefined => {
    let result = 'empty';
    if (draft) {
      result = `${draft.id} ${draft.reference}`;
    }
    return result;
  };

  const depthOptions: DropdownOption[] = [];
  if (!props.requestForExisting) {
    depthOptions.push({
      label: 'Product QTYs',
      value: 'products',
    });
  }
  if (props.doesQuotes) {
    if ((props.requestForExisting && props.jobDetails.selectedDraft) || !props.requestForExisting) {
      depthOptions.push({
        label: 'Existing Draft',
        value: 'draft',
      });
    }
  }
  if ((props.requestForExisting && props.jobDetails.selectedEstimate) || !props.requestForExisting) {
    depthOptions.push({
      label: 'Existing Estimate',
      value: 'estimate',
    });
  }

  return (
    <>
      {props.requestedOnBehalf && (
        <div className="p-field p-grid">
          <label htmlFor="order" className="p-col-1">
            requested by
          </label>
          <div className="p-col-11">
            <Dropdown
              value={props.jobDetails.dealerContact}
              options={props.contacts}
              dataKey="id"
              optionLabel="id"
              itemTemplate={contactTemplate}
              valueTemplate={contactTemplate}
              onChange={onDealerContactChange}
              filter
              filterBy="first_name,last_name"
              disabled={props.disableFields}
            />
          </div>
        </div>
      )}

      <div className="p-field p-grid">
        <label htmlFor="client_first_name" className="p-col-1">
          client first name
        </label>
        <div className="p-col-5">
          <InputText
            value={props.jobDetails.clientFirstName}
            onChange={onClientFirstNameChange}
            disabled={props.disableFields}
          />
        </div>
        <label htmlFor="client_last_name" className="p-col-1">
          client last name
        </label>
        <div className="p-col-5">
          <InputText
            value={props.jobDetails.clientLastName}
            onChange={onClientLastNameChange}
            disabled={props.disableFields}
          />
        </div>
      </div>
      <div className="p-field p-grid">
        <label htmlFor="client_phone" className="p-col-1">
          client phone
        </label>
        <div className="p-col-5">
          <InputText
            value={props.jobDetails.clientPhone}
            onChange={onClientPhoneChange}
            disabled={props.disableFields}
          />
        </div>
        <label htmlFor="client_email" className="p-col-1">
          client email
        </label>
        <div className="p-col-5">
          <InputText
            value={props.jobDetails.clientEmail}
            onChange={onClientEmailChange}
            disabled={props.disableFields}
          />
        </div>
      </div>
      <SiteAddressFields
        siteAddress={props.jobDetails.siteAddress}
        onChange={onSiteAddressChange}
        disabled={props.disableFields}
      />
      <div className="p-field p-grid">
        <label className="p-col-1">measure note</label>
        <div className="p-col-11">
          <InputText
            value={props.jobDetails.measureNote}
            onChange={onMeasureNoteChange}
            disabled={props.disableFields}
          />
        </div>
      </div>

      <div className="p-field p-grid">
        <label htmlFor="job_type" className="p-col-1">
          job type
        </label>
        <div className="p-col-11">
          <Dropdown
            value={props.jobDetails.requestedServices}
            options={requestedServicesOptions}
            onChange={onRequestedServicesChange}
            disabled={props.disableFields}
          />
        </div>
      </div>

      {props.jobDetails.requestedServices === 'Consult & Install' && (
        <div className="p-field p-grid">
          <label htmlFor="detail_depth" className="p-col-1">
            based on
          </label>
          <div className="p-col-11">
            <Dropdown
              name="detail_depth"
              value={props.jobDetails.detailDepth}
              options={depthOptions}
              dataKey="value"
              optionLabel="label"
              onChange={onDepthChange}
              disabled={depthOptions.length === 1 || props.disableFields}
            />
          </div>
        </div>
      )}

      {props.jobDetails.requestedServices === 'Consult & Install' && props.jobDetails.detailDepth === 'products' && (
        <ProductsQtysComponent
          products={props.jobDetails.products ?? []}
          productDefinitions={props.productDefinitions ?? []}
          onChange={onProductsChange}
          disabled={props.disableFields}
        />
      )}

      {props.jobDetails.requestedServices === 'Consult & Install' && props.jobDetails.detailDepth === 'draft' && (
        <div className="p-field p-grid">
          <label htmlFor="draft" className="p-col-1">
            draft to finish
          </label>
          <div className="p-col-11">
            <Dropdown
              name="draft"
              value={props.jobDetails.selectedDraft}
              options={props.drafts}
              dataKey="id"
              optionLabel="id"
              itemTemplate={draftTemplate}
              valueTemplate={draftTemplate}
              onChange={onDraftChange}
              filter
              filterBy="id,reference"
              disabled={props.requestForExisting || props.disableFields}
            />
          </div>
        </div>
      )}

      {(props.jobDetails.requestedServices === 'Check Measure & Install' ||
        (props.jobDetails.requestedServices === 'Consult & Install' &&
          props.jobDetails.detailDepth === 'estimate')) && (
        <div className="p-field p-grid">
          <label htmlFor="order" className="p-col-1">
            estimate to check
          </label>
          <div className="p-col-11">
            <Dropdown
              value={props.jobDetails.selectedEstimate}
              options={props.estimates}
              dataKey="id"
              optionLabel="id"
              itemTemplate={estimateTemplate}
              valueTemplate={estimateTemplate}
              onChange={onEstimateChange}
              filter
              filterBy="id,reference"
              disabled={props.requestForExisting || props.disableFields}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NewJobDetailsComponent;
