export const config = (companyId?: string) => {
  const companyService = process.env.REACT_APP_CUSTOMER_SERVICE;
  const tleService = process.env.REACT_APP_TLE_SERVICE;
  const orderService = process.env.REACT_APP_ORDER_SERVICE;
  const freightService = process.env.REACT_APP_FREIGHT_SERVICE;
  const productService = process.env.REACT_APP_PRODUCT_SERVICE;
  const configService = process.env.REACT_APP_CONFIG_SERVICE;
  const fittingService = process.env.REACT_APP_FITTING_SERVICE;

  return {
    system: {
      stop_typing_detection: 1000,
      filterJobsForUs: process.env.REACT_APP_US_JOB_FILTER === 'true',
    },
    endpoints: {
      users: configService + 'users',
      me: companyService + 'api/v2/my-contact',
      companies: companyService + 'api/v2/companies',
      contacts: companyService + `api/v2/${companyId}/contacts`,
      locations: companyService + `api/v2/${companyId}/locations`,
      tles: tleService + 'api/v2/tles',
      tlesNonPapi: tleService + 'tles',
      orders: orderService + `api/v2/${companyId}/orders`,
      containers: freightService + `api/v2/${companyId}/containers`,
      products: productService + `api/v2/${companyId}/products-definitions`,
      jobs: fittingService + `api/v2/${companyId}/jobs`,
      jobDocuments: fittingService + `api/v2/${companyId}/job-documents`,
      drafts: orderService + `api/v2/${companyId}/drafts`,
      appointments: fittingService + `api/v2/${companyId}/appointments`,
    },
    countryIsoAlpha2Code: process.env.REACT_APP_COUNTRY_ISO_ALPHA2_CODE,
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  };
};
export default config;
