import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  MessageService,
  TwoAction,
  ToastService,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  TwoMessage,
  UsersService,
  DcmPrinterComponent,
} from 'two-app-ui';
import {
  faCalendarAlt,
  faTruckLoading,
  faList,
  faPencil,
  faFileLock,
  faFilePen,
  faClipboardListCheck,
  faBan,
  faArrowRight,
  faPrint,
} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  Job,
  MapOf,
  CheckMeasureOriginalContent,
  ConsultOriginalContent,
  DcmContent,
  Field,
  JobDocument,
  OrderItem,
  OriginalContent,
  PaProductDefinition,
  QueryParameter,
  TimeLineEvent,
  User,
  Company,
  AppointmentPatch,
  JobStage,
  UserReference,
  JobPatch,
  TleContentAppointmentEvent,
} from 'two-core';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import JobsService from '../../services/JobsService';
import TlesService from '../../services/TleService';
import JobDetail from './JobDetail';
import {ProgressSpinner} from 'primereact/progressspinner';
import ProductsService from '../../services/ProductsService';
import {OriginalRequest} from '../JobDocument/Original/OriginalRequest';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import {BookAppointmentDialog} from './BookAppointmentDialog';
import {ReviewInstallDialog} from './ReviewInstallDialog';
import {FitterAssignmentDialog} from './FitterAssignmentDialog';
import {AddNoteDialog} from './AddNoteDialog';
import AppointmentsService from '../../services/AppointmentsService';
import {ReviewMeasureDialog} from './ReviewMeasureDialog/ReviewMeasureDialog';
import {EditDcmDialog} from './EditDcmDialog/EditDcmDialog';
import {DcmContentComponent} from './EditDcmDialog/DcmContentComponent';
import {StageTransitionDialog} from './StageTranstionDialog';
import {CancelJobDialog} from './CancelJobDialog';

library.add(faCalendarAlt, faTruckLoading, faList, faFileLock, faArrowRight);

interface RouteProps {
  id: string;
}

interface State {
  job?: Job;
  loadingJob: boolean;
  loadingTles: boolean;
  loadingOriginalProdDef: boolean;
  loadingDcmProdDef: boolean;
  savingJob: boolean;
  showEditDialog: boolean;
  showAddEventDialog: boolean;
  showEditDcmDialog: boolean;
  eventItems: TwoTimelineItem[];
  usersMap: MapOf<User>;
  originalProdDefs?: PaProductDefinition[];
  dcmProdDefs?: PaProductDefinition[];
  showFitterAssignmentJobDialog: boolean;
  showBookAppointmentDialog: boolean;
  showReviewMeasureDialog: boolean;
  showReviewInstallDialog: boolean;
  showAddNoteDialog: boolean;
  showStageTransitionDialog: boolean;
  showCancelJobDialog: boolean;
  transitioningJobStage: boolean;
  printDcm: boolean;
}

class JobComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  toastService: ToastService | null = null;
  jobsService: JobsService | null = null;
  tleService: TlesService | null = null;
  productsService?: ProductsService;
  usersService: UsersService | null = null;
  appointmentsService: AppointmentsService | null = null;

  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingJob: false,
      loadingTles: false,
      loadingOriginalProdDef: false,
      loadingDcmProdDef: false,
      savingJob: false,
      showEditDialog: false,
      showAddEventDialog: false,
      showEditDcmDialog: false,
      eventItems: [],
      usersMap: {},
      showFitterAssignmentJobDialog: false,
      showBookAppointmentDialog: false,
      showReviewMeasureDialog: false,
      showReviewInstallDialog: false,
      showAddNoteDialog: false,
      showStageTransitionDialog: false,
      showCancelJobDialog: false,
      transitioningJobStage: false,
      printDcm: false,
    };

    this.toast = React.createRef();

    this.onHideEditDialog = this.onHideEditDialog.bind(this);
    this.onHideAddEventDialog = this.onHideAddEventDialog.bind(this);
    this.onShowFitterAssignmentDialog = this.onShowFitterAssignmentDialog.bind(this);
    this.onHideFitterAssignmentDialog = this.onHideFitterAssignmentDialog.bind(this);
    this.onShowBookApplicationDialog = this.onShowBookApplicationDialog.bind(this);
    this.onHideBookApplicationDialog = this.onHideBookApplicationDialog.bind(this);
    this.onShowReviewMeasureDialog = this.onShowReviewMeasureDialog.bind(this);
    this.onHideReviewMeasureDialog = this.onHideReviewMeasureDialog.bind(this);
    this.onShowReviewInstallDialog = this.onShowReviewInstallDialog.bind(this);
    this.onHideReviewInstallDialog = this.onHideReviewInstallDialog.bind(this);
    this.onMoveJobIntoMeasureReview = this.onMoveJobIntoMeasureReview.bind(this);
    this.onShowAddNoteDialog = this.onShowAddNoteDialog.bind(this);
    this.onHideAddNoteDialog = this.onHideAddNoteDialog.bind(this);
    this.onBookAppointment = this.onBookAppointment.bind(this);
    this.onCancelAppointment = this.onCancelAppointment.bind(this);
    this.onCancelJobClick = this.onCancelJobClick.bind(this);
    this.onCancelJobDialogYesClick = this.onCancelJobDialogYesClick.bind(this);
    this.onCancelJobDialogNoClick = this.onCancelJobDialogNoClick.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.jobsService = this.context.jobsService;
    this.tleService = this.context.tleService;
    this.productsService = this.context.productsService;
    this.usersService = this.context.usersService;
    this.appointmentsService = this.context.appointmentsService;

    const id = this.props.match.params.id;
    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.jobUpdated) {
        this.loadJob(id);
        this.loadEvents(id);
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.props.history.push('/jobs');
        }
      }
    });

    this.loadJob(id);
    this.loadEvents(id);
    this.loadUsers();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadJob(id: string) {
    this.setState({loadingJob: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: ['documents', 'appointments', 'owner', 'fitting_provider', 'parent_job'],
    };
    this.jobsService
      ?.getJobs(params)
      .then(data => {
        const job = (data.records as Job[])[0];
        if (job.documents) {
          const originalDoc = job.documents.find(doc => doc.type === 'Original');
          if (originalDoc && originalDoc.content) {
            const origContent = originalDoc.content as OriginalContent;
            let revisionId: number | undefined;
            if (origContent.requested_service === 'Consult & Install') {
              revisionId = (origContent as ConsultOriginalContent).revision_id;
              const items = (origContent as ConsultOriginalContent).items;
              if (items) {
                (origContent as ConsultOriginalContent).items = [...this.cloneDocItems(items)];
              }
            } else if (origContent.requested_service === 'Check Measure & Install') {
              revisionId = (origContent as CheckMeasureOriginalContent).revision_id;
              const items = (origContent as CheckMeasureOriginalContent).items;
              if (items) {
                (origContent as CheckMeasureOriginalContent).items = [...this.cloneDocItems(items)];
              }
            }
            if (revisionId) {
              this.setState({loadingOriginalProdDef: true});
              this.productsService
                ?.getProductsDefinitions(revisionId, job.owner_id)
                .then(data => {
                  this.setState({
                    originalProdDefs: data.records as PaProductDefinition[],
                    loadingOriginalProdDef: false,
                  });
                })
                .catch(error => {
                  console.error('Loading Original prod def failed: ', error);
                  this.toastService?.showError(
                    this.toast,
                    'Sorry, failed to download Original data. If you want to see it, please refresh and try again.'
                  );
                  this.setState({
                    loadingOriginalProdDef: false,
                  });
                });
            }
          }
          const dcmDoc = job.documents.find(doc => doc.type === 'DCM');
          if (dcmDoc && dcmDoc.content) {
            const dcmContent = dcmDoc.content as DcmContent;
            dcmContent.items = this.cloneDocItems(dcmContent.items ?? []);
            this.setState({loadingDcmProdDef: true});
            this.productsService
              ?.getProductsDefinitions((dcmDoc.content as DcmContent).revision_id, job.owner_id)
              .then(data => {
                this.setState({
                  dcmProdDefs: data.records as PaProductDefinition[],
                  loadingDcmProdDef: false,
                });
              })
              .catch(error => {
                console.error('Loading Original prod def failed: ', error);
                this.toastService?.showError(
                  this.toast,
                  'Sorry, failed to download DCM data. If you want to see it, please refresh and try again.'
                );
                this.setState({
                  loadingDcmProdDef: false,
                });
              });
          }
        }
        this.setState({
          job: job,
          loadingJob: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, Job load failed, please try again.');
        this.setState({
          loadingJob: false,
        });
      });
  }

  cloneDocItems(items: OrderItem[]) {
    const clonedItems = (structuredClone(items) as OrderItem[]).map(item => {
      const fields: Field[] = item.fields.map(field => {
        //we must convert field to class object
        const values = field.values.map(fieldValue => {
          if (fieldValue.sub_fields) {
            fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
              //we must convert subfield to class object
              return new Field(subField);
            });
          }
          return fieldValue;
        });
        return new Field({...field, values: values});
      });
      item.fields = fields;
      return new OrderItem(item);
    });
    return clonedItems;
  }

  loadEvents(id: string) {
    this.setState({loadingTles: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'job',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tleService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];
        const eventItems = events.map(event => {
          return {event: event};
        });
        this.setState({
          eventItems: eventItems,
          loadingTles: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
        this.setState({loadingTles: false});
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users: User[] = (data?.records as User[]) ?? [];
        const usersMap: MapOf<User> = {};
        for (const user of users) {
          usersMap[user.id!] = user;
        }

        this.setState({
          usersMap: usersMap,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    return currentUser?.uuid ?? '';
  }

  canEditDcm(currentUserId: string, currentUserRole: string, job?: Job): boolean {
    const allowedStages: JobStage[] = [];
    if (job?.lead_fitter?.user_id === currentUserId) {
      allowedStages.push('Measure Started');
      allowedStages.push('Measure Finished');
    } else if (currentUserRole === 'admin') {
      const newStages: JobStage[] = [
        'New',
        'Measure Planned',
        'Measure Booked',
        'Measure Review',
        'Measure Approval Pending',
      ];
      allowedStages.push(...newStages);
    }
    return !!job?.stage && allowedStages.includes(job?.stage);
  }

  getActions(): TwoAction[] {
    const {job} = this.state;
    const currentUserId = this.getCurrentUserId();
    const currentUserRole = localStorage.getItem(localStorageAttributes.currentRole) ?? '';

    const actions: TwoAction[] = [];
    const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '[]';
    const currentCompany = JSON.parse(currentCompanyString) as Company;

    if (['New', 'Measure Planned', 'Measure Booked', 'Measure Started', 'Measure Finished'].includes(job!.stage)) {
      actions.push({
        icon: faArrowRight,
        label: 'Measure Review',
        main: false,
        action: () => {
          this.setState({showStageTransitionDialog: true});
        },
      });
    }
    if (job?.stage === 'Measure Review' || job?.stage === 'Measure Approval Pending') {
      actions.push({
        icon: faClipboardListCheck,
        label: 'Measure Review',
        main: false,
        action: () => {
          this.setState({showReviewMeasureDialog: true});
        },
      });
    }
    if (job?.stage === 'Install Review') {
      actions.push({
        icon: faClipboardListCheck,
        label: 'Install Review',
        main: false,
        action: () => {
          this.setState({showReviewInstallDialog: true});
        },
      });
    }

    if (
      job?.stage !== 'Completed' &&
      job?.stage !== 'Completed With Repair' &&
      currentCompany.fitting_types !== 'made2fit'
    ) {
      const isFitterAssigned =
        !job?.fitting_provider_id || (job.fitting_provider_id === currentCompany.id && !job.lead_fitter);
      const label = `${isFitterAssigned ? 'Assign' : 'Re-assign'} Fitter`;
      actions.push({
        icon: faPencil,
        label: label,
        main: false,
        action: () => {
          this.setState({showFitterAssignmentJobDialog: true});
        },
      });
    }

    if (job?.stage === 'Measure Review' || job?.stage === 'Measure Approval Pending') {
      actions.push({
        icon: faPrint,
        label: 'Print',
        main: false,
        action: () => {
          this.setState({printDcm: true}, () => MessageService.sendMessage(messages.printDcms));
        },
      });
    }

    if (this.canEditDcm(currentUserId, currentUserRole, job)) {
      actions.push({
        icon: faFilePen,
        label: 'Edit DCM',
        main: false,
        action: () => {
          this.setState({showEditDcmDialog: true});
        },
      });
    }

    if (!['Completed', 'Completed With Repair', 'Canceled'].includes(job!.stage)) {
      if (
        (job!.owner_id === currentCompany.id || job!.fitting_provider_id === currentCompany.id) &&
        currentUserRole === 'admin'
      )
        actions.push({
          icon: faBan,
          label: 'Cancel Job',
          main: false,
          action: this.onCancelJobClick,
        });
    }

    if (
      job &&
      (job.stage === 'New' ||
        job.stage === 'Measure Planned' ||
        job.stage === 'In Production' ||
        job.stage === 'In Shipping' ||
        job.stage === 'Delivered' ||
        job.stage === 'Install Planned')
    ) {
      if (job.fitting_provider_id === currentCompany.id) {
        // actions.push({
        //   icon: faCalendar,
        //   label: 'Book',
        //   main: true,
        //   action: () => {
        //     this.setState({showBookAppointmentDialog: true});
        //   },
        // });
      }
    }

    if (job?.stage === 'Measure Booked' || job?.stage === 'Install Booked') {
      // actions.push({
      //   icon: faCalendar,
      //   label: 'Re-Book',
      //   main: true,
      //   action: () => {
      //     this.setState({showBookAppointmentDialog: true});
      //   },
      // });
    }

    // actions.push({
    //   icon: faNoteSticky,
    //   label: 'Add Note',
    //   main: false,
    //   action: () => {
    //     this.setState({showAddNoteDialog: true});
    //   },
    // });

    if (actions.length) {
      actions[0].main = true;
    }

    return actions;
  }

  onHideEditDialog() {
    this.setState({showEditDialog: false});
  }

  onHideAddEventDialog() {
    this.setState({showAddEventDialog: false});
  }
  onShowFitterAssignmentDialog() {
    this.setState({showFitterAssignmentJobDialog: true});
  }

  onHideFitterAssignmentDialog() {
    this.setState({showFitterAssignmentJobDialog: false});
  }

  onShowReviewMeasureDialog() {
    this.setState({showReviewMeasureDialog: true});
  }

  onHideReviewMeasureDialog() {
    this.setState({showReviewMeasureDialog: false});
  }

  onShowReviewInstallDialog() {
    this.setState({showReviewInstallDialog: true});
  }

  onHideReviewInstallDialog() {
    this.setState({showReviewInstallDialog: false});
  }

  onMoveJobIntoMeasureReview() {
    this.setState({
      transitioningJobStage: true,
    });
    const jobPatch: JobPatch = {
      stage: 'Measure Review',
    };
    this.jobsService
      ?.updateJob(this.state.job!.id!, jobPatch, this.state.job!.owner_id!)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Job transition complete.');
      })
      .catch(err => {
        this.toastService?.showError(this.toast, 'Sorry, Job transition failed. Please refresh and try again.');
        console.error('error: ' + err);
      })
      .finally(() => {
        this.setState(
          {
            showStageTransitionDialog: false,
            transitioningJobStage: false,
          },
          () => {
            MessageService.sendMessage(messages.jobUpdated);
          }
        );
      });
  }

  onShowAddNoteDialog() {
    this.setState({showAddNoteDialog: true});
  }

  onHideAddNoteDialog() {
    this.setState({showAddNoteDialog: false});
  }

  onShowBookApplicationDialog() {
    this.setState({showBookAppointmentDialog: true});
  }

  onHideBookApplicationDialog() {
    this.setState({showBookAppointmentDialog: false});
  }

  onBookAppointment(appointmentPatch: AppointmentPatch, newFittingProviderId?: string, newFitter?: UserReference) {
    const {job} = this.state;
    const saves: Promise<void>[] = [];
    const userId = this.getCurrentUserId();

    if (appointmentPatch.id) {
      saves.push(this.updateAppointment(appointmentPatch.id, appointmentPatch, userId));
    } else {
      saves.push(this.createAppointment(appointmentPatch, userId));
    }
    if (job?.fitting_provider_id !== newFittingProviderId || job?.lead_fitter?.user_id !== newFitter?.user_id) {
      saves.push(this.updateFitter(job!, userId, newFittingProviderId, newFitter));
    }
    return Promise.all(saves).finally(() => {
      this.loadJob(this.state.job!.id!);
    });
  }

  async createAppointment(appointmentPatch: AppointmentPatch, userId: string) {
    appointmentPatch.tles_success = [
      {
        event_type: 'appointment_event',
        entity_type: 'job',
        entity_id: this.state.job!.id!,
        recorded_at: new Date(),
        recorded_by: userId,
        content: {
          app_type: appointmentPatch.type,
          app_stage: appointmentPatch.stage,
          start: appointmentPatch.start_plan,
          end: appointmentPatch.end_plan,
        } as TleContentAppointmentEvent,
      },
    ];
    this.appointmentsService
      ?.createAppointment(appointmentPatch)
      .then(() => {
        this.toastService?.showSuccess(this.toast, `${appointmentPatch.type} ${appointmentPatch.stage} Successfully.`);
        this.setState({showBookAppointmentDialog: false});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, appointment creation failed, please try again.');
        console.error('error: ' + error);
      });
  }

  async updateAppointment(appointmentId: number, appointmentPatch: AppointmentPatch, userId: string) {
    appointmentPatch.tles_success = [
      {
        event_type: 'appointment_event',
        entity_type: 'job',
        entity_id: this.state.job!.id!,
        recorded_at: new Date(),
        recorded_by: userId,
        content: {
          app_type: appointmentPatch.type,
          app_stage: appointmentPatch.stage,
          start: appointmentPatch.start_plan,
          end: appointmentPatch.end_plan,
        } as TleContentAppointmentEvent,
      },
    ];

    this.appointmentsService
      ?.updateAppointment(appointmentId, appointmentPatch)
      .then(() => {
        this.toastService?.showSuccess(
          this.toast,
          `${appointmentPatch.type ?? 'Appointment'} ${appointmentPatch.stage ?? 'Updated'} Successfully.`
        );
        this.setState({showBookAppointmentDialog: false});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, appointment update failed, please try again.');
        console.error('error: ' + error);
      });
  }

  async updateFitter(job: Job, userId: string, newFittingProviderId?: string, newFitter?: UserReference) {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!currentCompanyId) {
      this.toastService?.showError(this.toast, 'Sorry, fitter update failed, please refresh and try again.');
      console.error('Current Company ID not found');
    }

    const jobPatch: JobPatch = {};
    if (newFittingProviderId) {
      jobPatch.fitting_provider_id = newFittingProviderId;
    }
    if (newFitter) {
      jobPatch.lead_fitter = newFitter;
    }
    this.jobsService
      ?.updateJob(job.id!, jobPatch, currentCompanyId!)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Fitter re-assigned successfully.');
        this.setState({showBookAppointmentDialog: false});
        MessageService.sendMessage(messages.jobUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, fitter re-assignment failed, please refresh and try again.');
        console.error('error: ' + error);
      });
  }

  onCancelAppointment() {
    this.setState({showBookAppointmentDialog: false});
  }

  onCancelJobClick() {
    this.setState({showCancelJobDialog: true});
  }

  onCancelJobDialogYesClick() {
    const {job} = this.state;
    this.setState({savingJob: true});
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    this.jobsService
      ?.updateJob(job!.id!, {stage: 'Canceled'}, currentCompanyId!)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Job cancelled.');
        this.setState({showCancelJobDialog: false});
        MessageService.sendMessage(messages.jobUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, job update failed, please try again.');
        console.error('error: ' + error);
      })
      .finally(() => this.setState({savingJob: false}));
  }

  onCancelJobDialogNoClick() {
    this.setState({showCancelJobDialog: false});
  }

  render() {
    const {
      job,
      eventItems,
      usersMap,
      loadingJob,
      loadingTles,
      loadingOriginalProdDef,
      originalProdDefs,
      showFitterAssignmentJobDialog,
      showBookAppointmentDialog,
      showReviewMeasureDialog,
      showReviewInstallDialog,
      showAddNoteDialog,
      dcmProdDefs,
      showEditDcmDialog,
      showStageTransitionDialog,
      transitioningJobStage,
      showCancelJobDialog,
      savingJob,
      printDcm,
    } = this.state;
    if (!job) {
      return <></>;
    }

    let originalDoc: JobDocument | undefined;
    let dcmDoc: JobDocument | undefined;
    if (job.documents) {
      originalDoc = job.documents.find(doc => doc.type === 'Original');
      dcmDoc = job.documents.find(doc => doc.type === 'DCM');
    }

    return (
      <>
        <TwoEntityComponent title={job.title} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!loadingJob ? <JobDetail job={job} usersMap={usersMap} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!loadingTles ? <TwoTimeline key={job.id} items={eventItems} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          {originalDoc && originalDoc.content ? (
            <TwoEntityPanel label="Original" icon={faFileLock} tooltip="Original">
              <OriginalRequest
                loading={loadingOriginalProdDef}
                docContent={originalDoc.content as OriginalContent}
                productDefinitions={originalProdDefs}
              />
            </TwoEntityPanel>
          ) : (
            <></>
          )}
          {dcmDoc?.content && (
            <TwoEntityPanel label="DCM" icon={faFileLock} tooltip="DCM">
              <DcmContentComponent
                dcmContent={dcmDoc.content as DcmContent}
                dcmProdDefs={dcmProdDefs ?? []}
                readOnly={true}
                onItemsChange={() => {}}
                comparisonMode={false}
              />
            </TwoEntityPanel>
          )}
        </TwoEntityComponent>
        <FitterAssignmentDialog
          jobs={[job]}
          showDialog={showFitterAssignmentJobDialog}
          onHide={this.onHideFitterAssignmentDialog}
          toast={this.toast}
        />
        <AddNoteDialog
          jobs={[job]}
          showDialog={showAddNoteDialog}
          onHide={this.onHideAddNoteDialog}
          toast={this.toast}
        />
        <BookAppointmentDialog
          job={job}
          showDialog={showBookAppointmentDialog}
          onBook={this.onBookAppointment}
          onCancel={this.onCancelAppointment}
          toast={this.toast}
        />
        <ReviewMeasureDialog
          job={job}
          showDialog={showReviewMeasureDialog}
          onHide={this.onHideReviewMeasureDialog}
          toast={this.toast}
        />
        <ReviewInstallDialog job={job} showDialog={showReviewInstallDialog} onHide={this.onHideReviewInstallDialog} />
        {dcmDoc && (
          <EditDcmDialog
            showDialog={showEditDcmDialog}
            onHide={() => this.setState({showEditDcmDialog: false})}
            toast={this.toast}
            dcm={dcmDoc}
            dcmProdDefs={dcmProdDefs ?? []}
            original={originalDoc}
            originalProdDefs={originalProdDefs}
          />
        )}
        {showStageTransitionDialog && (
          <StageTransitionDialog
            showDialog={showStageTransitionDialog}
            transitioning={transitioningJobStage}
            jobName={job.title}
            fromStage={job.stage}
            toStage={'Measure Review'}
            onCancel={() => {
              this.setState({showStageTransitionDialog: false});
            }}
            onYes={this.onMoveJobIntoMeasureReview}
          />
        )}
        <CancelJobDialog
          showDialog={showCancelJobDialog}
          loading={savingJob}
          onYes={this.onCancelJobDialogYesClick}
          onNo={this.onCancelJobDialogNoClick}
        />
        <Toast ref={this.toast} />
        {printDcm && dcmDoc && dcmProdDefs?.length && (
          <DcmPrinterComponent
            jobs={[job]}
            dcmsMap={new Map([[job.id!, dcmDoc]])}
            productDefinitionsMap={new Map([[dcmProdDefs[0].revision_id, dcmProdDefs]])}
            triggerMessage={messages.printDcms}
            onPrintDialogOpen={() => this.setState({printDcm: false})}
          />
        )}
      </>
    );
  }
}

export default withRouter(JobComponent);
