import React from 'react';
import {AppContext, getTwoDateFormat, ToastService, TwoDialog, UsersService} from 'two-app-ui';
import {
  Appointment,
  AppointmentPatch,
  AppointmentType,
  Company,
  DropdownOption,
  FittingType,
  Job,
  PaContact,
  QueryParameter,
  UserReference,
} from 'two-core';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {InputTextarea} from 'primereact/inputtextarea';
import {appointmentStages, appointmentTypes} from '../../config/values';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {DateTime} from 'luxon';
import AppointmentsService from '../../services/AppointmentsService';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import ContactsService from '../../services/ContactsService';
import {ProgressSpinner} from 'primereact/progressspinner';

interface Props {
  showDialog: boolean;
  job: Job;
  onBook: (appointmentPatch: AppointmentPatch, newFittingProviderId?: string, newFitter?: UserReference) => void;
  onCancel: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  saving: boolean;
  appointmentPatch: AppointmentPatch;
  companyFittingTypes: FittingType[];
  selectedFittingType?: FittingType;
  fittingProviderOptions: DropdownOption[];
  fittingProviderCompanies: Company[];
  fitterContacts: PaContact[];
  selectedFittingProviderId?: string;
}

export class BookAppointmentDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  appointmentsService?: AppointmentsService;
  contactsService?: ContactsService;
  toastService?: ToastService;
  usersService?: UsersService;
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      saving: false,
      appointmentPatch: {},
      companyFittingTypes: [],
      fittingProviderOptions: [],
      fittingProviderCompanies: [],
      fitterContacts: [],
    };

    this.onShow = this.onShow.bind(this);
    this.onBookClicked = this.onBookClicked.bind(this);
    this.onCancelClicked = this.onCancelClicked.bind(this);
    this.onAppointmentTypeChange = this.onAppointmentTypeChange.bind(this);
    this.onAppointmentStageChange = this.onAppointmentStageChange.bind(this);
    this.onStartPlanChange = this.onStartPlanChange.bind(this);
    this.onEndPlanChange = this.onEndPlanChange.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {
    this.toastService = this.context.toastService;
    this.appointmentsService = this.context.appointmentsService;
    this.contactsService = this.context.contactsService;
    this.usersService = this.context.usersService;
  }

  onShow() {
    this.setState({loading: true, saving: false});
    const loads: Promise<void>[] = [];

    loads.push(this.loadAppointment());
    loads.push(this.loadFitters());

    Promise.all(loads)
      .then(() => {
        const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '{}';
        const currentCompany = JSON.parse(currentCompanyString) as Company;
        const companyFittingTypesText = currentCompany.fitting_types;
        const companyFittingOptions = companyFittingTypesText?.split(',') as FittingType[];

        let selectedFittingType: FittingType | undefined;
        let selectedFittingProvider: string | undefined;
        const {job} = this.props;
        if (job.fitting_provider_id) {
          if (job.fitting_provider_id === currentCompany.id) {
            selectedFittingType = 'internal';
            selectedFittingProvider = job.lead_fitter?.contact_id;
          } else {
            selectedFittingType = '3rd party';
            selectedFittingProvider = job.fitting_provider_id;
          }
        }
        this.setFitterOptions(selectedFittingType).then(() => {
          this.setState({
            loading: false,
            companyFittingTypes: companyFittingOptions,
            fittingProviderCompanies: currentCompany.fitting_providers ?? [],
            selectedFittingProviderId: selectedFittingProvider,
          });
        });
      })
      .catch(error => {
        this.toastService?.showError(
          this.props.toast,
          'This is embarrassing. Seems I am failing to load the necessary data. Please try refreshing and starting again.'
        );
        console.log(error);
        this.onCancelClicked();
      });
  }

  async loadAppointment() {
    const filters = [
      JSON.stringify({
        field: 'job_id',
        value: this.props.job.id,
      }),
      JSON.stringify({
        field: 'stage',
        condition: '<>',
        value: 'Completed',
      }),
    ];

    const params: QueryParameter = {
      filters: filters,
    };

    this.appointmentsService?.getAppointments(params).then(response => {
      const appointment = (response.records as Appointment[])[0];
      let appPatch: AppointmentPatch = {};
      if (appointment) {
        //update
        appPatch = {
          id: appointment.id,
          type: appointment.type,
          stage: appointment.stage,
          start_plan: appointment.start_plan ? new Date(appointment.start_plan) : new Date(),
          end_plan: appointment.end_plan ? new Date(appointment.end_plan) : undefined,
          note: appointment.note,
        };
      } else {
        //new
        if (this.props.job.stage === 'New') {
          if (this.props.job.requested_services === 'Check Measure & Install') {
            appPatch.type = 'Check Measure';
          } else if (this.props.job.requested_services === 'Consult & Install') {
            appPatch.type = 'Consultation';
          } else if (this.props.job.requested_services === 'Service Call') {
            appPatch.type = 'Service Call';
          }
        } else if (['In Production', 'In Shipping', 'Delivered'].includes(this.props.job.stage)) {
          if (
            ['Check Measure & Install', 'Consult & Install', 'Service Call'].includes(this.props.job.requested_services)
          ) {
            appPatch.type = 'Installation';
          } else if (this.props.job.requested_services === 'Repair') {
            appPatch.type = 'Repair';
          }
        }
      }

      if (!appPatch.stage) {
        appPatch.stage = 'Booked';
      }
      if (!appPatch.start_plan) {
        appPatch.start_plan = new Date();
        appPatch.end_plan = this.calcEndDate(new Date(), appPatch.type);
      }

      this.setState({
        appointmentPatch: appPatch,
      });
    });
  }

  async loadFitters() {
    const sortBy = JSON.stringify({
      field: 'first_name',
      direction: 'ASC',
    });

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'company_contact.in_role',
        value: 'fitter',
      })
    );

    return this.contactsService?.getContacts({filters: filters, orderBys: [sortBy]}).then(data => {
      const fitterContacts = (data.records as PaContact[]) ?? [];
      this.setState({fitterContacts: fitterContacts});
    });
  }

  async setFitterOptions(fittingType?: FittingType) {
    let newFitterOptions: DropdownOption[] = [];
    if (fittingType === '3rd party') {
      newFitterOptions = this.state.fittingProviderCompanies?.map(company => {
        return {
          label: company.name,
          value: company.id as string,
        };
      });
    } else if (fittingType === 'internal') {
      newFitterOptions = this.state.fitterContacts.map(contact => {
        return {
          label: `${contact.first_name} ${contact.last_name}`,
          value: contact.id as string,
        };
      });
    }
    this.setState({
      fittingProviderOptions: newFitterOptions,
      selectedFittingType: fittingType,
    });
  }

  async onBookClicked() {
    const {job} = this.props;
    const {selectedFittingType, fitterContacts, selectedFittingProviderId} = this.state;
    this.setState({saving: true});
    if (this.validate()) {
      const appointmentPatch = {...this.state.appointmentPatch};
      appointmentPatch.title = `${appointmentPatch.type} for ${job.title}`;
      appointmentPatch.job_id = job.id;
      appointmentPatch.address = job.address;
      let fittingProviderId: string | undefined;
      let leadFitter: UserReference | undefined;
      if (selectedFittingType === 'internal') {
        const selectedContact = fitterContacts.find(contact => selectedFittingProviderId === contact.id)!;
        leadFitter = {
          user_id: selectedContact.user_id,
          contact_id: selectedContact.id,
          label: `${selectedContact.first_name} ${selectedContact.last_name}`,
        };
        fittingProviderId = localStorage.getItem(localStorageAttributes.currentCompanyId)!;
      } else if (selectedFittingType === '3rd party') {
        fittingProviderId = selectedFittingProviderId!;
      } else if (selectedFittingType === 'made2fit') {
        fittingProviderId = 'made2fit';
      }
      this.props.onBook(appointmentPatch, fittingProviderId, leadFitter);
    }
  }

  validate(): boolean {
    const appointmentPatch = {...this.state.appointmentPatch};

    const errors: string[] = [];
    if (!appointmentPatch?.type) {
      errors.push('Type field is empty.');
    }
    if (!appointmentPatch?.stage) {
      errors.push('Stage field is empty.');
    }

    if (appointmentPatch?.start_plan && appointmentPatch?.end_plan) {
      const startDate = DateTime.fromJSDate(appointmentPatch?.start_plan);
      const endDate = DateTime.fromJSDate(appointmentPatch?.end_plan);
      if (startDate > endDate) {
        errors.push('Start date is greater than end date.');
      }
    } else {
      if (!appointmentPatch?.start_plan) {
        errors.push('Start field is empty.');
      }
      if (!appointmentPatch?.end_plan) {
        errors.push('End field is empty.');
      }
    }

    if (!this.state.selectedFittingType) {
      errors.push('Fitting type not selected.');
    }
    if (!this.state.selectedFittingProviderId) {
      errors.push('Fitter not selected.');
    }

    if (errors.length) {
      const errorText = (
        <div>
          Form is invalid:
          {errors.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </div>
      );
      this.toastService?.showError(this.props.toast, errorText);
      return false;
    }
    return true;
  }

  onCancelClicked() {
    this.setState({appointmentPatch: {}});
    this.props.onCancel();
  }

  onAppointmentTypeChange(e: DropdownChangeParams) {
    this.setState({
      appointmentPatch: {...this.state.appointmentPatch, type: e.value},
    });
  }

  onAppointmentStageChange(e: DropdownChangeParams) {
    this.setState({
      appointmentPatch: {...this.state.appointmentPatch, stage: e.value},
    });
  }

  calcEndDate(start: Date, appType?: AppointmentType): Date {
    if (!appType) {
      return start;
    }
    let result: Date = new Date();
    switch (appType) {
      case 'Consultation':
        result = new Date(DateTime.fromJSDate(start).plus({hours: 2}).toJSDate());
        break;
      case 'Check Measure':
      case 'Service Call':
        result = new Date(DateTime.fromJSDate(start).plus({hours: 1}).toJSDate());
        break;
      case 'Installation':
      case 'Repair':
        if (this.props.job.installation_info) {
          result = new Date(
            DateTime.fromJSDate(start)
              .plus({
                hours: this.props.job.installation_info.time_required,
              })
              .toJSDate()
          );
        }
        break;
    }
    return result;
  }

  onStartPlanChange(e: CalendarChangeParams) {
    const startDate = e.value as Date;
    const endDate = this.calcEndDate(startDate, this.state.appointmentPatch.type);

    this.setState({
      appointmentPatch: {
        ...this.state.appointmentPatch,
        start_plan: startDate,
        end_plan: endDate,
      },
    });
  }

  onEndPlanChange(e: CalendarChangeParams) {
    this.setState({
      appointmentPatch: {
        ...this.state.appointmentPatch,
        end_plan: e.value as Date,
      },
    });
  }

  onNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      appointmentPatch: {
        ...this.state.appointmentPatch,
        note: e.target.value,
      },
    });
  }

  onInputFittingChange(fittingType?: FittingType) {
    this.setFitterOptions(fittingType);
  }

  onFittingProviderChange = (value: string) => {
    this.setState({
      selectedFittingProviderId: value,
    });
  };

  render() {
    const {showDialog, job} = this.props;
    const {
      loading,
      appointmentPatch,
      companyFittingTypes,
      fittingProviderOptions,
      selectedFittingType,
      selectedFittingProviderId,
      saving,
    } = this.state;
    const calendarInputDate = getTwoDateFormat(this.usersService?.settings?.date_format, 'calendarInputDate');
    const calendarInputHour = getTwoDateFormat(this.usersService?.settings?.date_format, 'calendarInputHour');
    const content = loading ? (
      <ProgressSpinner />
    ) : (
      <div id="book_appointment_dialog" className="w-100">
        <div className="p-field p-grid p-ml-0">
          <label className="p-col-2 p-md-1">
            <small>type</small>
          </label>
          <div className="p-col-10 p-md-5 p-mb-2">
            <Dropdown
              className={'w-100'}
              value={appointmentPatch.type}
              options={appointmentTypes}
              onChange={this.onAppointmentTypeChange}
              disabled={true}
            />
          </div>
          <label className="p-col-2 p-md-1">
            <small>stage</small>
          </label>
          <div className="p-col-10 p-md-5 p-mb-2">
            <Dropdown
              className={'w-100'}
              value={appointmentPatch.stage}
              options={appointmentStages}
              onChange={this.onAppointmentStageChange}
              disabled={saving ?? false}
            />
          </div>
        </div>
        <div className="p-field p-grid p-ml-0">
          <label className="p-col-2 p-md-1">
            <small>start</small>
          </label>
          <div className="p-col-10 p-md-5 p-mb-2">
            <Calendar
              className="w-100"
              value={appointmentPatch.start_plan}
              onChange={this.onStartPlanChange}
              showTime
              dateFormat={calendarInputDate}
              hourFormat={calendarInputHour}
              disabled={saving ?? false}
            />
          </div>
          <label className="p-col-2 p-md-1">
            <small>end</small>
          </label>
          <div className="p-col-10 p-md-5 p-mb-2">
            <Calendar
              className="w-100"
              value={appointmentPatch.end_plan}
              onChange={this.onEndPlanChange}
              showTime
              dateFormat={calendarInputDate}
              hourFormat={calendarInputHour}
              disabled={saving ?? false}
            />
          </div>
        </div>
        <div className="p-field p-grid p-ml-0">
          <label className="p-col-2 p-md-1">
            <small>note</small>
          </label>
          <div className="p-col-10 p-md-11">
            <InputTextarea
              className="w-100"
              rows={5}
              value={appointmentPatch.note}
              onChange={this.onNoteChange}
              disabled={saving ?? false}
            />
          </div>
        </div>
        <div className="p-field p-grid p-ml-0 p-mb-0">
          {companyFittingTypes.length > 1 && <label className="p-col-2 p-md-1">who fits</label>}
          {companyFittingTypes.length > 1 && (
            <div className="p-col-10 p-md-5">
              <Dropdown
                name="fitting_type"
                className={'w-100'}
                value={selectedFittingType}
                options={companyFittingTypes}
                onChange={e => this.onInputFittingChange(e.value)}
                disabled={saving ?? false}
              />
            </div>
          )}
          {selectedFittingType && selectedFittingType !== 'made2fit' && (
            <label className="p-col-2 p-md-1">{selectedFittingType === 'internal' ? 'fitter' : 'provider'}</label>
          )}
          {selectedFittingType && selectedFittingType !== 'made2fit' && (
            <div className="p-col-10 p-md-5">
              <Dropdown
                name="fitter"
                className={'w-100'}
                value={selectedFittingProviderId}
                options={fittingProviderOptions}
                onChange={e => this.onFittingProviderChange(e.value)}
                disabled={saving ?? false}
              />
            </div>
          )}
        </div>
      </div>
    );

    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className="p-mr-2 p-button-text" onClick={this.onCancelClicked} loading={saving} />
        <Button loading={saving} label="Book" onClick={this.onBookClicked} autoFocus />
      </div>
    );

    return (
      <TwoDialog
        headerTitle={`Book Appointment for ${job.title}`}
        loading={false}
        showDialog={showDialog}
        style={{width: '50vw'}}
        breakpoints={{'768px': '80vw', '576px': '95vw'}}
        footer={footer}
        contentClassName="w-100"
        onHide={this.onCancelClicked}
        onShow={this.onShow}
        saving={saving}
      >
        {content}
      </TwoDialog>
    );
  }
}
