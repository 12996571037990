import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Company, Job, JobPatch, TimeLineEvent, TleContentNote} from 'two-core';
import TleService from '../../services/TleService';
import JobsService from '../../services/JobsService';
import {InputTextarea} from 'primereact/inputtextarea';
import {Toast} from 'primereact/toast';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  jobs: Job[];
  toast: React.RefObject<Toast>;
}

interface State {
  note: string;
  saving: boolean;
}

export class AddNoteDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  jobService: JobsService | null = null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      note: '',
      saving: false,
    };

    this.onHide = this.onHide.bind(this);
    this.createNote = this.createNote.bind(this);
    this.createTle = this.createTle.bind(this);
    this.getCurrentUserId = this.getCurrentUserId.bind(this);
  }

  componentDidMount() {
    this.jobService = this.context.jobsService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;
  }

  async createNote() {
    if (!(this.props.jobs && this.props.jobs.length > 0)) {
      throw new Error('No jobs selected.');
    }

    this.setState({saving: true});

    if (this.getCurrentUserId()) {
      Promise.all(
        this.props.jobs.map((job: Job) => {
          const note = this.state.note;
          const content: TleContentNote = {
            text: note,
            title: 'note',
          };

          const tle: TimeLineEvent = {
            event_type: 'note',
            entity_type: 'job',
            recorded_by: this.getCurrentUserId(),
            entity_id: job.id ?? '',
            content: content,
            recorded_at: new Date(),
          };

          return this.createTle(job.id ?? '', tle)
            ?.then(() => {
              this.toastService?.showSuccess(this.props.toast, `Note recorded against ${job.title}.`);
            })
            .catch(() => {
              this.toastService?.showError(this.props.toast, `Note failed to recorded for ${job.title}.`);
            });
        })
      ).finally(() => {
        this.onHide();
      });
    } else {
      this.toastService?.showError(this.props.toast, 'Sorry, something is not right. Please, refresh and try again.');
    }
  }

  async createTle(jobId: string, tle: TimeLineEvent): Promise<void | Job> {
    const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '{}';
    const currentCompany = JSON.parse(currentCompanyString) as Company;
    return this.tleService
      ?.createTle(tle)
      .then(data => {
        const jobUpdate: JobPatch = {
          last_event_id: data.id,
        };
        MessageService.sendMessage(messages.jobUpdated);
        return this.jobService?.updateJob(jobId, jobUpdate, currentCompany.id!);
      })
      .catch(error => {
        console.log(error);
        return Promise.reject();
      });
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  onHide() {
    this.setState({
      note: '',
      saving: false,
    });
    this.props.onHide();
  }

  render() {
    const {showDialog, onHide} = this.props;
    const {saving} = this.state;
    const jobTitles = this.props.jobs.map(job => job.title);
    const dialogBody = (
      <>
        <div className="p-d-flex p-col-12 p-p-0 p-mb-2">
          <span>Note will be added to these orders: {jobTitles.join(', ')}</span>
        </div>
        <div className="p-d-flex p-col-12 p-p-0">
          <InputTextarea
            className="w-100"
            rows={5}
            cols={30}
            value={this.state.note}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({note: e.target.value})}
            disabled={saving}
          />
        </div>
      </>
    );
    return (
      <TwoDialog
        headerTitle="Add Note"
        loading={false}
        onHide={onHide}
        showDialog={showDialog}
        style={{width: '50vw'}}
        breakpoints={{'768px': '75vw', '576px': '90vw'}}
        saving={saving}
        onSave={this.createNote}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
