import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

class ContainersService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async getContainers(params: QueryParameter): Promise<ApiListResponse> {
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    return this.get(config(companyId).endpoints.containers, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}

export default ContainersService;
