import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

class ProductsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.products ?? '';
  }

  async getProductsDefinitions(revisionId?: number, companyId?: string): Promise<ApiListResponse> {
    if (!companyId) {
      companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    }
    let url;
    if (revisionId) {
      url = `${config(companyId).endpoints.products}/${revisionId}`;
    } else {
      url = config(companyId).endpoints.products;
    }
    return this.get(url)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}

export default ProductsService;
