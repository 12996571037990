import React from 'react';
import {DcmContent, InstallationInfo, OrderItem, PaProductDefinition} from 'two-core';
import {OrderItemsComponent} from 'two-app-ui';
import {ScrollPanel} from 'primereact/scrollpanel';
import {InstallationInfoComponent} from './InstallationInfoComponent';
import {InputTextarea} from 'primereact/inputtextarea';

interface Props {
  dcmContent: DcmContent;
  dcmProdDefs: PaProductDefinition[];
  readOnly: boolean;
  onItemsChange: (newItems: OrderItem[]) => void;
  onDcmContentChange?: (fieldsToUpdate: Partial<DcmContent>) => void;
  setInvalidItemMessagesMap?: (invalidItemMessagesMap: Map<number, string[]>) => void;
  comparisonMode: boolean;
  onItemToggle?: (index: number) => void;
  collapsedItemIndexes?: Set<number>;
  originalItems?: OrderItem[];
  originalProductDefinitions?: PaProductDefinition[];
  showInvalidFields?: boolean;
}
export const DcmContentComponent = ({
  dcmContent,
  onDcmContentChange,
  readOnly,
  dcmProdDefs,
  setInvalidItemMessagesMap,
  onItemsChange,
  comparisonMode,
  onItemToggle,
  collapsedItemIndexes,
  originalItems,
  originalProductDefinitions,
  showInvalidFields,
}: Props) => {
  const onInstallationInfoChange = (fieldsToUpdate: Partial<InstallationInfo>) => {
    const newInstallationInfo = {
      ...(dcmContent?.installation_info ?? {}),
      ...fieldsToUpdate,
    } as InstallationInfo;
    onDcmContentChange!({installation_info: newInstallationInfo});
  };

  const installInfo = dcmContent.installation_info;
  const releaseNote = dcmContent.release_note;
  const labelClasses = 'p-col-2';
  const valueClasses = 'p-col-10';
  return (
    <ScrollPanel className="p-mt-1 w-100">
      {/*items*/}
      <OrderItemsComponent
        mode={readOnly ? 'readonly' : 'advanced_edit'}
        items={dcmContent?.items ?? []}
        productDefinitions={dcmProdDefs}
        outerItemColumnClassName={'p-col-12 p-lg-6'}
        innerItemColumnClassName={'p-col-12 p-xl-6'}
        onItemsChanged={onItemsChange}
        setInvalidItemMessagesMap={setInvalidItemMessagesMap}
        comparisonMode={comparisonMode}
        onItemToggle={onItemToggle}
        collapsedItemIndexes={collapsedItemIndexes}
        originalItems={originalItems}
        originalProductDefinitions={originalProductDefinitions}
        showInvalidFields={showInvalidFields}
      />
      {/*installation info*/}
      <InstallationInfoComponent
        installInfo={installInfo}
        onInstallationInfoChange={onInstallationInfoChange}
        readOnly={readOnly}
      />
      {/*measure note*/}
      <div className="w-100 p-mt-5">
        {(releaseNote || !readOnly) && (
          <div className="p-grid">
            <label className={labelClasses}>
              <small>release note</small>
            </label>
            <div className={valueClasses}>
              {readOnly ? (
                releaseNote
              ) : (
                <InputTextarea
                  className="w-100"
                  value={releaseNote}
                  onChange={e => onDcmContentChange!({release_note: e.target.value})}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </ScrollPanel>
  );
};
