import React from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {PaContact, PaContactPatch, QueryParameter} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import ContactsService from '../../services/ContactsService';
import {Dropdown} from 'primereact/dropdown';
import {contactRoles} from '../../config/values';
import {MultiSelectChangeParams} from 'primereact/multiselect';

interface Props {
  showDialog: boolean;
  onHide: (runId?: number) => void;
  contactId: string | undefined;
  companyId: string;
}

interface State {
  loading: boolean;
  contact: PaContact | undefined;
  contactPatch: PaContactPatch;
  role: string | undefined;
}

class AddEditContactDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  contactsService: ContactsService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      contact: undefined,
      contactPatch: {} as PaContactPatch,
      role: undefined,
    };

    this.toast = React.createRef();

    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.contactsService = this.context.contactsService;
    this.toastService = this.context.toastService;
  }

  setContact() {
    const id = this.props.contactId;
    if (id) {
      this.loadContact(id);
    } else {
      const emptyContact: PaContact = {
        email: '',
        first_name: '',
        id: '',
        last_name: '',
        title: '',
        updated_at: new Date(),
        role: 'admin',
      };
      this.setState({contact: emptyContact});
    }
  }

  hideDialog() {
    this.setState({
      contact: undefined,
      contactPatch: {},
      role: undefined,
      loading: false,
    });
    this.props.onHide();
  }

  loadContact(id: string) {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
      calculate_values: false,
    };

    this.contactsService
      ?.getContacts(params)
      .then(data => {
        const dataRecords = (data?.records as PaContact[]) ?? [];
        const contact = dataRecords[0];
        console.log(contact);
        this.setState({
          contact: contact,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, contact load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const {contact, contactPatch} = this.state;
    if (contact?.id) {
      this.updateContact(contact.id, contactPatch);
    } else {
      this.createContact({
        ...contactPatch,
      } as PaContact);
    }
  }

  async createContact(contact: PaContact) {
    this.setState({loading: true});

    return this.contactsService
      ?.createContact(contact)
      .then(() => {
        MessageService.sendMessage(messages.contactUpdate);
        this.toastService?.showSuccess(this.toast, 'Contact created successfully.');
        this.hideDialog();
        // company contact is being created in the backend
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, contact create failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async updateContact(contactId: string, contactPatch: PaContactPatch) {
    this.setState({loading: true});

    return this.contactsService
      ?.updateContact(contactId ?? '', contactPatch)
      .then(() => {
        MessageService.sendMessage(messages.contactUpdate);
        this.toastService?.showSuccess(this.toast, 'Contact updated successfully.');
        this.hideDialog();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, contact update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const {contactPatch} = this.state;

    const patchUpdate = {
      ...contactPatch,
      [e.target.name]: e.target.value,
    };

    this.setState({contactPatch: patchUpdate});
  }

  render() {
    const {contact, loading} = this.state;

    const footer = (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="cancel" className={'p-mr-2 p-button-text'} onClick={() => this.hideDialog()} />
        <Button
          label="save"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    );

    return (
      <div>
        <TwoDialog
          headerTitle={contact?.id ? 'Edit Contact' : 'Add Contact'}
          footer={footer}
          showDialog={this.props.showDialog}
          onHide={() => this.hideDialog()}
          onShow={() => this.setContact()}
          loading={loading}
          width={70}
        >
          <div className="p-grid">
            <div className="p-d-flex p-ai-center p-col-12">
              <label htmlFor="title" className="p-col-1">
                title
              </label>
              <div className="p-col-3">
                <span className="p-fluid">
                  <InputText
                    name="title"
                    value={this.state.contactPatch?.title ?? contact?.title ?? ''}
                    onChange={e => {
                      this.handleChange(e);
                    }}
                  />
                </span>
              </div>
              <label htmlFor="first_name" className="p-col-1">
                first name
              </label>
              <div className="p-col-3">
                <span className="p-fluid">
                  <InputText
                    name="first_name"
                    value={this.state.contactPatch?.first_name ?? contact?.first_name ?? ''}
                    onChange={e => {
                      this.handleChange(e);
                    }}
                  />
                </span>
              </div>
              <label htmlFor="last_name" className="p-col-1">
                last name
              </label>
              <div className="p-col-3">
                <span className="p-fluid">
                  <InputText
                    name="last_name"
                    value={this.state.contactPatch.last_name ?? contact?.last_name ?? ''}
                    onChange={e => {
                      this.handleChange(e);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="p-d-flex p-ai-center p-col-12">
              <label htmlFor="email" className="p-col-1">
                email
              </label>
              <div className="p-col-3">
                <span className="p-fluid">
                  <InputText
                    name="email"
                    value={this.state.contactPatch?.email ?? contact?.email ?? ''}
                    onChange={e => {
                      this.handleChange(e);
                    }}
                  />
                </span>
              </div>
              <label htmlFor="phone_number" className="p-col-1">
                phone number
              </label>
              <div className="p-col-3">
                <span className="p-fluid">
                  <InputText
                    name="phone_number"
                    value={this.state.contactPatch?.phone_number ?? contact?.phone_number ?? ''}
                    onChange={e => {
                      this.handleChange(e);
                    }}
                  />
                </span>
              </div>
              <label htmlFor="role" className="p-col-1">
                role
              </label>
              <span className="p-fluid p-col-3">
                <Dropdown
                  name="role"
                  value={this.state.contactPatch?.role ?? contact?.role ?? ''}
                  options={contactRoles}
                  onChange={e => this.handleChange(e)}
                />
              </span>
            </div>
          </div>
        </TwoDialog>
        <Toast ref={this.toast} />
      </div>
    );
  }
}
export default AddEditContactDialog;
