import React from 'react';
import OrdersService from '../../services/OrdersService';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  TwoAction,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  ToastService,
  TwoEntityComponent,
  MessageService,
  OrdersPrinterComponent,
  OrderItemsComponent,
} from 'two-app-ui';
import './Order.scss';
import OrderDetail from './OrderDetail';
import {
  Company,
  Field,
  OrderItem,
  PaOrder,
  PaOrderPatch,
  PaProductDefinition,
  QueryParameter,
  TimeLineEvent,
} from 'two-core';
import TleService from '../../services/TleService';

import {
  faCalendarAlt,
  faPencil,
  faBarcodeRead,
  faCheck,
  faTrash,
  faTrashUndo,
  faPrint,
  faTape,
} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import ProductsService from '../../services/ProductsService';
import SubmitOrderConfirmDialog from './SubmitOrderConfirmDialog';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import AddEditOrderDialog from './AddEditOrderDialog';
import {RequestJobDialog} from '../Job/RequestJobDialog/RequestJobDialog';
import JobListComponent from '../Jobs/JobListComponent';

interface RouteProps {
  id: string;
}

interface State {
  loading: boolean;
  order: PaOrder;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showEditDialog: boolean;
  hideEditOrderDialog: boolean;
  productDefinitions: PaProductDefinition[];
  showSubmitOrderDialog: boolean;
  printOrder: boolean;
  showRequestJobDialog: boolean;
}

class OrderComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  ordersService: OrdersService | null = null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;
  productsService: ProductsService | null = null;

  toast: React.RefObject<Toast>;
  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);
    this.ordersService = null;
    this.tleService = null;

    this.state = {
      showSubmitOrderDialog: false,
      loading: false,
      order: {} as PaOrder,
      events: [],
      items: [],
      showEditDialog: false,
      hideEditOrderDialog: false,
      productDefinitions: [],
      printOrder: false,
      showRequestJobDialog: false,
    };
    this.hideEditDialog = this.hideEditDialog.bind(this);
    this.toast = React.createRef();
    this.hideEditDialog = this.hideEditDialog.bind(this);
    this.loadEvents = this.loadEvents.bind(this);
    this.onHideSubmitConfirmDialog = this.onHideSubmitConfirmDialog.bind(this);
    this.deleteEstimate = this.deleteEstimate.bind(this);
    this.unDeleteEstimate = this.unDeleteEstimate.bind(this);
    this.onShowRequestJobDialog = this.onShowRequestJobDialog.bind(this);
    this.onHideRequestJobDialog = this.onHideRequestJobDialog.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;
    this.productsService = this.context.productsService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.orderUpdated) {
        this.loadData();
      }
    });
    this.loadData();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
  }

  loadData() {
    this.setState({loading: true});
    const id = this.props.match.params.id;
    const loads = [];
    loads.push(this.loadOrder(id));
    loads.push(this.loadEvents(id));

    Promise.all(loads)
      .catch(error => {
        console.error('Failed Loading Data:' + error);
        this.toastService?.showError(this.toast, 'Failed loading data, please refresh and try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  async loadOrder(orderId: string) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: orderId,
      })
    );
    filters.push(
      JSON.stringify({
        condition: 'includeDeleted',
        value: true,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    return this.ordersService?.getOrders(params).then(data => {
      const order = (data.records as PaOrder[])[0];
      const orderItems: OrderItem[] = order.items.map(item => {
        const fields: Field[] = item.fields.map(field => {
          //we must convert field to class object
          const values = field.values.map(fieldValue => {
            if (fieldValue.sub_fields) {
              fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
                //we must convert subfield to class object
                return new Field(subField);
              });
            }
            return fieldValue;
          });
          return new Field({...field, values: values});
        });
        item.fields = fields;
        return new OrderItem(item);
      });
      order.items = orderItems;
      this.setState({
        order: order,
        //latestOrderSaved: order,
      });
      if (order.revision_id) {
        return this.loadProductDefinitions(order.revision_id);
      }
      return;
    });
  }

  async loadEvents(id: string) {
    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'order',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    return this.tleService?.getTimeLineEvents(params).then(data => {
      const events = data.records as TimeLineEvent[];

      const items = events.map(event => {
        const item: TwoTimelineItem = {event: event};
        return item;
      });

      this.setState({
        events: events,
        items: items,
      });
    });
  }

  async loadProductDefinitions(revisionId: number) {
    return this.productsService?.getProductsDefinitions(revisionId).then(data => {
      const productDefinitions = (data.records as PaProductDefinition[]) ?? [];
      this.setState({
        productDefinitions: productDefinitions,
      });
    });
  }

  onShowRequestJobDialog() {
    this.setState({showRequestJobDialog: true});
  }

  onHideRequestJobDialog() {
    this.setState({showRequestJobDialog: false});
  }

  getActions(order: PaOrder): TwoAction[] {
    const actions: TwoAction[] = [];

    const isEstimate = this.state.order.stage === 'Estimate' || this.state.order.stage === '00 Estimate';

    if (isEstimate) {
      if (!order.deleted) {
        const currentCompany: Company = JSON.parse(localStorage.getItem(localStorageAttributes.currentCompany) ?? '{}');
        actions.push({
          icon: faPencil,
          label: 'Edit',
          main: true,
          action: () => {
            this.showEditDialog(order);
          },
        });
        actions.push({
          icon: faCheck,
          label: 'Submit',
          action: () => {
            this.onSubmit();
          },
        });
        actions.push({
          icon: faTrash,
          label: 'Delete',
          main: false,
          action: () => {
            this.deleteEstimate();
          },
        });
        if (currentCompany?.fitting_types && order.fitting_options) {
          actions.push({
            icon: faTape,
            label: 'Request Fitting',
            main: false,
            action: () => {
              this.onShowRequestJobDialog();
            },
          });
        }
      } else {
        actions.push({
          icon: faTrashUndo,
          label: 'Undelete',
          main: true,
          action: () => {
            this.unDeleteEstimate();
          },
        });
      }
    }

    actions.push({
      icon: faPrint,
      label: 'Print',
      main: !isEstimate,
      action: () => {
        this.setState(
          {
            printOrder: true,
          },
          () => MessageService.sendMessage(messages.printOrder)
        );
      },
    });

    return actions;
  }

  async onSubmit() {
    this.setState({showSubmitOrderDialog: true});
  }

  onHideSubmitConfirmDialog() {
    this.setState({showSubmitOrderDialog: false});
  }

  showEditDialog(order: PaOrder) {
    this.setState({order: order, showEditDialog: true});
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  async deleteEstimate() {
    const {order} = this.state;

    this.setState({loading: true});
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!companyId) {
      this.setState({loading: false});
      this.toastService?.showError(this.toast, 'Sorry, something went wrong. Please refresh and try again.');
      return;
    }

    if (order && order.id) {
      this.ordersService
        ?.deleteOrder(companyId, order.id)
        .then(() => {
          this.toastService?.showSuccess(this.toast, 'Estimate successfully deleted.');
          this.loadData();
        })
        .catch(error => {
          console.error(error);
          this.toastService?.showError(this.toast, 'Oh no, estimate failed to delete.');
          this.setState({loading: false});
        });
    } else {
      console.error('No state.order or id present.');
      this.toastService?.showError(this.toast, 'Oh no, estimate failed to delete. Please, refresh and try again.');
      this.setState({loading: false});
    }
  }

  async unDeleteEstimate() {
    const {order} = this.state;

    this.setState({loading: true});
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!companyId || !order || !order.id) {
      this.setState({loading: false});
      this.toastService?.showError(this.toast, 'Sorry, something went wrong. Please, refresh and try again.');
      return;
    }
    const orderPatch: PaOrderPatch = {
      deleted: false,
    };
    this.ordersService
      ?.updateOrder(companyId, order.id, orderPatch)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Estimate successfully un-deleted.');
        this.loadData();
      })
      .catch(error => {
        console.error(error);
        this.toastService?.showError(this.toast, 'Oh no, estimate failed to un-delete.');
        this.setState({loading: false});
      });
  }

  render() {
    const {order, items, loading, printOrder} = this.state;

    const currentCompany: Company = JSON.parse(localStorage.getItem(localStorageAttributes.currentCompany) ?? '{}');

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }
    const prodDefs: Map<number, PaProductDefinition[]> = new Map<number, PaProductDefinition[]>();
    prodDefs.set(order.revision_id ?? -1, this.state.productDefinitions);

    const title = (order.id ?? '') + (order.deleted ? '  [DELETED]' : '');

    const showJobs = order.jobs?.length && (currentCompany.fits_for_others || currentCompany.fitting_types?.length);

    return order.id ? (
      <>
        <TwoEntityComponent title={title} actions={this.getActions(order)}>
          <TwoEntityPanel isPrimary={true}>
            <OrderDetail order={order} />
          </TwoEntityPanel>
          <TwoEntityPanel label="Content" icon={faBarcodeRead} tooltip="Content">
            <>
              {!!order.items.length && (
                <OrderItemsComponent
                  mode={'readonly'}
                  items={order.items}
                  productDefinitions={this.state.productDefinitions}
                  onItemsChanged={() => {}}
                />
              )}
            </>
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            <TwoTimeline key={order.id} items={items} />
          </TwoEntityPanel>
          {showJobs ? (
            <TwoEntityPanel label="Jobs" icon={faTape} tooltip="Jobs">
              <JobListComponent mode={'Order Entity Panel'} orderId={order.id} />
            </TwoEntityPanel>
          ) : (
            <></>
          )}
        </TwoEntityComponent>
        <AddEditOrderDialog
          toast={this.toast}
          showDialog={this.state.showEditDialog}
          onHide={this.hideEditDialog}
          order={order}
        />
        <SubmitOrderConfirmDialog
          showDialog={this.state.showSubmitOrderDialog}
          onHide={this.onHideSubmitConfirmDialog}
          toast={this.toast}
          orderId={order.id}
        />
        <Toast ref={this.toast} />
        {printOrder && (
          <OrdersPrinterComponent
            orders={[order]}
            productDefinitionRevisions={prodDefs}
            triggerMessage={messages.printOrder}
            onPrintDialogOpen={() => {
              this.setState({printOrder: false});
            }}
          />
        )}
        <RequestJobDialog
          showDialog={this.state.showRequestJobDialog}
          onHide={this.onHideRequestJobDialog}
          toast={this.toast}
          estimate={this.state.order}
        />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(OrderComponent);
