import React from 'react';
import {Toast} from 'primereact/toast';
import {AppContext, ToastService, TwoDialog} from 'two-app-ui';
import {ProgressSpinner} from 'primereact/progressspinner';
import {MessageService} from 'two-app-ui';
import {Subscription} from 'rxjs';
import {QueryParameter, Address, PaCompanyPatch, PaCompany} from 'two-core';
import {DropdownChangeParams} from 'primereact/dropdown';
import {messages} from '../../config/messages';
import CompaniesService from '../../services/CompaniesService';
import CompanyEditComponent from './CompanyEditComponent';

interface Props {
  company: PaCompany;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  loadingCompany: boolean;
  company: PaCompany;
  companyPatch: PaCompanyPatch;
}

class CompanyEditDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  companiesService: CompaniesService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      loadingCompany: false,
      company: {} as PaCompany,
      companyPatch: {} as PaCompanyPatch,
    };

    this.toast = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveCompany = this.saveCompany.bind(this);
    this.closeCompany = this.closeCompany.bind(this);
    this.setCompany = this.setCompany.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.companyUpdated) {
        this.reloadData();
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  setCompany() {
    const newCompany = {...this.props.company};
    this.setState({company: newCompany});
  }

  reloadData() {
    const filters: string[] = [];
    this.setState({loading: true});
    filters.push(
      JSON.stringify({
        field: 'id',
        value: this.props.company.id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.companiesService?.getCompanies(params).then(data => {
      const company = (data.records as PaCompany[])[0];
      this.setState({
        company: company,
      });
    });
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const companyPatch = this.state.companyPatch;
    const updatedCompanyPatch = {
      ...companyPatch,
      [e.target.name]: e.target.value,
    };
    this.setState({companyPatch: updatedCompanyPatch});
  }

  setBillingAddress(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const {companyPatch, company} = this.state;
    const address: Address = companyPatch.billing_address ??
      company.billing_address ?? {
        state: '',
        street: '',
        state_short: '',
        suburb: '',
        postCode: '',
        country: '',
        lat: 0,
        long: 0,
        phoneNumber: '',
      };

    const updatedAddress = {
      ...address,
      [e.target.name]: e.target.value,
    };

    const updatedCompanyPatch: PaCompanyPatch = {
      ...companyPatch,
      billing_address: updatedAddress,
    };
    this.setState({companyPatch: updatedCompanyPatch});
  }

  saveCompany() {
    this.setState({loading: true});
    this.companiesService
      ?.updateCompany(this.state.company.id ?? '', this.state.companyPatch)
      .then(company => {
        MessageService.sendMessage(messages.companyUpdated);
        this.toastService?.showSuccess(
          this.toast,
          `Company ${company.trading_as ?? company.name} updated successfully.`
        );
        this.closeCompany();
      })
      .catch(error => {
        this.toastService?.showError(
          this.toast,
          `Sorry, ${this.state.company.trading_as ?? this.state.company.name} update failed, please try again.`
        );
        console.error(error);
        this.setState({loading: false});
      });
  }

  closeCompany() {
    this.setCompany();
    this.setState({
      loading: false,
    });
    this.props.onHide();
  }

  render() {
    return (
      <>
        <TwoDialog
          headerTitle={'Edit Company'}
          showDialog={this.props.showDialog}
          style={{width: '90'}}
          className="p-fluid"
          onHide={this.closeCompany}
          onShow={this.setCompany}
          onSave={this.saveCompany}
          loading={this.state.loading}
        >
          {!this.state.loading ? (
            <CompanyEditComponent
              company={this.state.company}
              companyPatch={this.state.companyPatch}
              handleInputChange={this.handleInputChange}
              handleBillingAddressChange={e => this.setBillingAddress(e)}
            />
          ) : (
            <div className="p-d-flex p-flex-row p-ai-center">
              <ProgressSpinner />
            </div>
          )}
        </TwoDialog>
        <Toast ref={this.toast} />
      </>
    );
  }
}

export default CompanyEditDialog;
