import React, {useContext} from 'react';
import {Appointment} from 'two-core';
import {DateTime} from 'luxon';
import {AppContext, getTwoDateFormat} from 'two-app-ui';

interface Props {
  appointment: Appointment;
}

export const JobAppointmentReferenceTooltip = ({appointment}: Props) => {
  const context = useContext(AppContext);
  const dateFormat = context.usersService?.settings?.date_format;
  const labelClasses = 'p-col-2';
  const valueClasses = 'p-col-10';
  const address = `${appointment.address.street}, ${appointment.address.suburb}, ${appointment.address.postCode}, ${appointment.address.country}`;
  const plannedStart = appointment.start_plan
    ? DateTime.fromISO(appointment.start_plan.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))
    : '';
  const plannedEnd = appointment.end_plan
    ? DateTime.fromISO(appointment.end_plan.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))
    : '';
  const realStart = appointment.start_real
    ? DateTime.fromISO(appointment.start_real.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))
    : '';
  const realEnd = appointment.end_real
    ? DateTime.fromISO(appointment.end_real.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))
    : '';
  const planValue = plannedStart + (plannedEnd ? ` - ${plannedEnd}` : '');
  const realValue = realStart + (realEnd ? ` - ${realEnd}` : '');
  return (
    <div className={'p-d-flex p-flex-column'}>
      <div className="p-grid">
        <label className={labelClasses}>type</label>
        <div className={valueClasses}>
          <span>{appointment.type}</span>
        </div>
      </div>
      <div className="p-grid">
        <label className={labelClasses}>stage</label>
        <div className={valueClasses}>
          <span>{appointment.stage}</span>
        </div>
      </div>
      <div className="p-grid">
        <label className={labelClasses}>address</label>
        <div className={valueClasses}>
          <span>{address}</span>
        </div>
      </div>
      {planValue && (
        <div className="p-grid">
          <label className={labelClasses}>plan</label>
          <div className={valueClasses}>
            <span>{planValue}</span>
          </div>
        </div>
      )}
      {realValue && (
        <div className="p-grid">
          <label className={labelClasses}>real</label>
          <div className={valueClasses}>
            <span>{realValue}</span>
          </div>
        </div>
      )}
      {appointment.note && (
        <div className="p-grid">
          <label className={labelClasses}>note</label>
          <div className={valueClasses}>
            <span>{appointment.note}</span>
          </div>
        </div>
      )}
    </div>
  );
};
