import React from 'react';
import {
  DataTableExpandedRows,
  DataTablePageParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {getTwoDateFormat, TwoDataTable, UsersService} from 'two-app-ui';
import {AppContext, ToastService} from 'two-app-ui';
import {PaOrder, QueryParameter, Run} from 'two-core';
import {Container, Location} from 'two-core';
import '../../scss/CustomTable.scss';
import OrdersService from '../../services/OrdersService';
import {Subscription} from 'rxjs';
import ContainersService from '../../services/ContainersService';
import {DateTime} from 'luxon';
import {Tooltip} from 'primereact/tooltip';

interface Props {
  container?: Container;
  location?: Location;
  run?: Run;
  heightToScroll?: string;
  hideFilter?: boolean;
}

interface State {
  loading: boolean;
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  expandedRows: DataTableExpandedRows[];
  filters: {
    company: string;
    containerId: string;
    reference: string;
  };
  order: PaOrder[];
}

class GroupOrdersComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService?: OrdersService;
  toastService?: ToastService;
  containersService?: ContainersService;
  usersService?: UsersService;

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;
  subscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      expandedRows: [],
      filters: {
        company: '',
        containerId: '',
        reference: '',
      },
      order: [],
    };

    this.toast = React.createRef();

    this.loadData = this.loadData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.approvedAtBodyTemplate = this.approvedAtBodyTemplate.bind(this);
    this.submittedAtBodyTemplate = this.submittedAtBodyTemplate.bind(this);
  }

  async componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
    this.containersService = this.context.containersService;
    this.usersService = this.context.usersService;
    this.loadData();
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];
    const container = this.props.container;

    if (this.state.filters.containerId) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.containerId,
          condition: 'iLike',
        })
      );
    }
    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'id':
        sortBy.field = ':id';
        break;
      case 'reference':
        sortBy.field = ':reference';
        break;
      case 'submitted':
        sortBy.field = ':submitted_at';
        break;
      case 'approved':
        sortBy.field = ':approved_at';
        break;
      case 'eta':
        sortBy.field = ':paOrder.eta';
        break;
    }

    const sortByStringyField = JSON.stringify(sortBy.field ? sortBy : {field: 'id', direction: 'ASC'});

    const params: QueryParameter = {
      filters: filters,
      orderBys: [sortByStringyField],
      aggregate: true,
    };

    this.ordersService
      ?.getOrders(params)
      .then(data => {
        const order = (data?.records as PaOrder[]) ?? [];
        const selectedOrders = order.filter(value => value.container_id === container?.id);
        this.setState({
          order: selectedOrders,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  getInitials(fullName: string | undefined): string {
    if (fullName?.length) {
      return fullName
        .split(' ')
        .map(name => name[0])
        .join('');
    }
    return '';
  }

  approvedAtBodyTemplate(rowData: PaOrder) {
    const formated_approved_on = rowData.approved_at
      ? DateTime.fromISO(rowData.approved_at.toString()).toFormat(
          getTwoDateFormat(this.usersService?.settings?.date_format, 'date')
        )
      : '';
    const id = `approved-at-${rowData.id}`;
    return (
      <>
        <Tooltip target={`#${id}`}>{formated_approved_on}</Tooltip>
        <span id={id}>{formated_approved_on}</span>
      </>
    );
  }

  submittedAtBodyTemplate(rowData: PaOrder) {
    const formated_submitted = rowData.submitted_at
      ? DateTime.fromISO(rowData.submitted_at.toString()).toFormat(
          getTwoDateFormat(this.usersService?.settings?.date_format, 'date')
        )
      : '';
    const id = `sumitted-at-${rowData.id}`;
    return (
      <>
        <Tooltip target={`#${id}`}>
          {formated_submitted} {rowData.submitted_by}
        </Tooltip>
        <span id={id}>
          {formated_submitted} {this.getInitials(rowData.submitted_by)}
        </span>
      </>
    );
  }

  render() {
    return (
      <div id="container_list_page_container" className="page-container">
        <TwoDataTable
          style={{height: '100%'}}
          pageSizeIdentifier={'location_list_page_container'}
          sizeIdentifiers={[]}
          onSort={e => this.onSort(e)}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          loading={this.state.loading}
          value={this.state.order}
          activeFilters={{}}
          expandedRows={this.state.expandedRows}
          onRowToggle={e => {
            this.setState({expandedRows: e.data});
          }}
          selectedItems={[]}
          hideFilter={this.props.hideFilter ?? false}
          showPaging={false}
        >
          <Column header="Order Code" field="id" sortable style={{width: '100px'}} showFilterMenu={false} />
          <Column header="Reference" field="reference" sortable style={{width: '100px'}} showFilterMenu={false} />
          <Column header="Summary" field="summary" style={{width: '100px'}} />
          <Column
            header="Submitted"
            field="submitted_at"
            sortable
            body={this.submittedAtBodyTemplate}
            style={{width: '100px'}}
            showFilterMenu={false}
          />
          <Column
            header="Approved"
            field="approved_at"
            body={this.approvedAtBodyTemplate}
            sortable
            style={{width: '100px'}}
            showFilterMenu={false}
          />
          <Column header="ETA" field="eta" sortable style={{width: '100px'}} />
        </TwoDataTable>

        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default GroupOrdersComponent;
