import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Company, DropdownOption, FittingType, Job, JobPatch, PaContact} from 'two-core';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import {Dropdown} from 'primereact/dropdown';
import ContactsService from '../../services/ContactsService';
import JobsService from '../../services/JobsService';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import {ProgressSpinner} from 'primereact/progressspinner';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  jobs: Job[];
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  selectedFittingType?: FittingType;
  selectedFittingProviderId?: string;
  companyFittingTypes: FittingType[];
  fittingProviderOptions: DropdownOption[];
  fittingProviderCompanies: Company[];
  fitterContacts: PaContact[];
  saving?: boolean;
}

export class FitterAssignmentDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  contactsService?: ContactsService;
  jobsService?: JobsService;
  toastService?: ToastService;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      companyFittingTypes: [],
      fittingProviderOptions: [],
      fittingProviderCompanies: [],
      fitterContacts: [],
    };

    this.onShow = this.onShow.bind(this);
    this.loadContacts = this.loadContacts.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onInputFittingChange = this.onInputFittingChange.bind(this);
    this.onFittingProviderChange = this.onFittingProviderChange.bind(this);
  }

  componentDidMount() {
    this.contactsService = this.context.contactsService;
    this.jobsService = this.context.jobsService;
    this.toastService = this.context.toastService;
  }

  async onShow() {
    this.setState({loading: true});

    this.loadContacts()
      .then(() => {
        const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '{}';
        const currentCompany = JSON.parse(currentCompanyString) as Company;
        const companyFittingTypesText = currentCompany.fitting_types;
        const companyFittingOptions = companyFittingTypesText?.split(',') as FittingType[];

        this.setState(
          {
            loading: false,
            companyFittingTypes: companyFittingOptions,
            fittingProviderCompanies: currentCompany.fitting_providers ?? [],
          },
          () => {
            if (companyFittingOptions.length === 1) {
              this.setFitterOptions(companyFittingOptions[0]);
            }
          }
        );
      })
      .catch(error => {
        this.toastService?.showError(
          this.props.toast,
          'This is embarrassing. Seems I am failing to load the necessary data. Please try refreshing and starting again.'
        );
        console.log(error);
        this.onHide();
      });
  }

  async loadContacts() {
    const sortBy = JSON.stringify({
      field: 'first_name',
      direction: 'ASC',
    });

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'company_contact.in_role',
        value: 'fitter',
      })
    );

    return this.contactsService?.getContacts({filters: filters, orderBys: [sortBy]}).then(data => {
      const fitterContacts = (data.records as PaContact[]) ?? [];
      this.setState({fitterContacts: fitterContacts});
    });
  }

  onHide() {
    this.setState({
      selectedFittingType: undefined,
      selectedFittingProviderId: undefined,
      companyFittingTypes: [],
      fittingProviderOptions: [],
      fittingProviderCompanies: [],
      fitterContacts: [],
      saving: false,
    });
    this.props.onHide();
  }

  onSave() {
    if (this.validate()) {
      this.updateJobs();
    }
  }

  validate() {
    const {selectedFittingType, selectedFittingProviderId, fitterContacts, fittingProviderCompanies} = this.state;
    if (selectedFittingType === 'internal') {
      const selectedContact = fitterContacts.find(contact => selectedFittingProviderId === contact.id);
      if (!selectedContact) {
        return false;
      }
    } else if (selectedFittingType === '3rd party') {
      const selectedCompany = fittingProviderCompanies.find(company => selectedFittingProviderId === company.id);
      if (!selectedCompany) {
        return false;
      }
    }
    return true;
  }

  async updateJobs() {
    const {jobs} = this.props;
    const {selectedFittingType, selectedFittingProviderId, fitterContacts} = this.state;
    const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '{}';
    const currentCompany = JSON.parse(currentCompanyString) as Company;
    let jobPatch: JobPatch = {};
    this.setState({saving: true});
    if (selectedFittingType === 'internal') {
      const selectedContact = fitterContacts.find(contact => selectedFittingProviderId === contact.id)!;
      jobPatch = {
        lead_fitter: {
          user_id: selectedContact.user_id,
          contact_id: selectedContact.id,
          label: `${selectedContact.first_name} ${selectedContact.last_name}`,
        },
        fitting_provider_id: 'internal',
      };
    } else if (selectedFittingType === '3rd party') {
      jobPatch.fitting_provider_id = selectedFittingProviderId;
    } else if (selectedFittingType === 'made2fit') {
      jobPatch.fitting_provider_id = 'made2fit';
    }
    const promises = jobs.map(async job => {
      if (job.stage === 'New') {
        jobPatch.stage = 'Assigned';
      }
      return this.jobsService?.updateJob(job.id!, jobPatch, currentCompany.id!);
    });
    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, `Job${jobs.length > 1 ? 's' : ''} assigned successfully.`);
        this.onHide();
        MessageService.sendMessage(messages.jobUpdated);
      })
      .catch(error => {
        this.toastService?.showError(
          this.props.toast,
          'Sorry, updating jobs did not finish to satisfaction. Please, try again.'
        );
        console.error('error: ' + error);
      })
      .finally(() => {
        this.setState({saving: false});
      });
  }

  async setFitterOptions(fittingType?: FittingType) {
    let newFitterOptions: DropdownOption[] = [];
    if (fittingType === '3rd party') {
      newFitterOptions = this.state.fittingProviderCompanies?.map(company => {
        return {
          label: company.name,
          value: company.id as string,
        };
      });
    } else if (fittingType === 'internal') {
      newFitterOptions = this.state.fitterContacts.map(contact => {
        return {
          label: `${contact.first_name} ${contact.last_name}`,
          value: contact.id as string,
        };
      });
    }
    this.setState({
      fittingProviderOptions: newFitterOptions,
      selectedFittingType: fittingType,
    });
  }

  onInputFittingChange(fittingType?: FittingType) {
    this.setFitterOptions(fittingType);
  }

  onFittingProviderChange = (value: string) => {
    this.setState({
      selectedFittingProviderId: value,
    });
  };

  getMessage(jobs: Job[]) {
    const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '{}';
    const currentCompany = JSON.parse(currentCompanyString) as Company;
    const fitterIDs: string[] = [];
    const assignedJobs = jobs.filter(job => {
      if (job.fitting_provider_id === currentCompany.id) {
        if (job.lead_fitter && job.lead_fitter.user_id) {
          if (!fitterIDs.includes(job.lead_fitter.user_id)) {
            fitterIDs.push(job.lead_fitter.user_id);
          }
          return true;
        }
        return false;
      } else if (job.fitting_provider_id) {
        if (!fitterIDs.includes(job.fitting_provider_id)) {
          fitterIDs.push(job.fitting_provider_id);
        }
        return true;
      }
      return false;
    });
    if (assignedJobs.length > 0) {
      const jobText = assignedJobs.length === 1 ? 'job' : 'jobs';
      const jobTitles = jobs
        .filter(job => assignedJobs.find(asJob => asJob.id === job.id))
        .map(job => job.title)
        .join(', ');
      const beVerb = `${assignedJobs.length === 1 ? 'is' : 'are'}`;
      const fitterText = fitterIDs.length === 1 ? 'a fitter' : 'multiple fitters';
      return `Please note: the ${jobText} ${jobTitles} ${beVerb} assigned to ${fitterText} already.`;
    } else {
      return undefined;
    }
  }

  render() {
    const {showDialog, jobs} = this.props;
    const {
      loading,
      companyFittingTypes,
      selectedFittingType,
      fittingProviderOptions,
      selectedFittingProviderId,
      saving,
    } = this.state;
    const jobText = jobs.length === 1 ? jobs[0].title : 'Jobs';
    const title = `Assign ${jobText} to Fitter`;
    const message = this.getMessage(jobs);

    const content = loading ? (
      <ProgressSpinner />
    ) : (
      <div className="w-100">
        <div className="p-field p-fluid p-grid p-mt-2">
          {companyFittingTypes.length > 1 && (
            <>
              <label htmlFor="fitting" className="p-col-1">
                request fit
              </label>
              <div className="p-col-5">
                <Dropdown
                  name="fitting_type"
                  value={selectedFittingType}
                  options={companyFittingTypes}
                  onChange={e => this.onInputFittingChange(e.value)}
                />
              </div>
            </>
          )}
          {selectedFittingType && selectedFittingType !== 'made2fit' && (
            <>
              <label htmlFor="fitter" className="p-col-1">
                {selectedFittingType === 'internal' ? 'fitter' : 'provider'}
              </label>
              <div className="p-col-5">
                <Dropdown
                  name="fitter"
                  value={selectedFittingProviderId}
                  options={fittingProviderOptions}
                  onChange={e => this.onFittingProviderChange(e.value)}
                />
              </div>
            </>
          )}
        </div>
        {message && <div>{message}</div>}
      </div>
    );

    return (
      <TwoDialog
        headerTitle={title}
        loading={false}
        onHide={this.onHide}
        showDialog={showDialog}
        onShow={this.onShow}
        onSave={this.onSave}
        style={{width: '50vw'}}
        breakpoints={{'768px': '75vw', '576px': '90vw'}}
        saving={saving}
      >
        {content}
      </TwoDialog>
    );
  }
}
