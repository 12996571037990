import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {NavLink} from 'react-router-dom';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {Toast} from 'primereact/toast';
import {
  MessageService,
  TwoDataTable,
  AppColumnMenuBodyTemplate,
  ToastService,
  AppMenuItem,
  AppMenuItemTemplate,
  TwoMessage,
  DraftsPrinterComponent,
  UsersService,
  getTwoDateFormat,
} from 'two-app-ui';
import {AppContext} from 'two-app-ui';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {
  QueryParameter,
  ApiListResponse,
  Draft,
  DropdownOption,
  DraftPatch,
  PaContact,
  PaProductDefinition,
} from 'two-core';
import '../../scss/CustomTable.scss';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitchChangeParams} from 'primereact/inputswitch';
import {messages} from '../../config/messages';
import {Tooltip} from 'primereact/tooltip';
import {MenuItemOptions} from 'primereact/menuitem';
import DraftsService from '../../services/DraftsService';
import {NewEditDraftDialog} from '../Draft/NewEditDraftDialog/NewEditDraftDialog';
import {faPrint, faToggleOff, faToggleOn, faTrash, faTrashUndo} from '@fortawesome/pro-regular-svg-icons';
import {MultiSelect} from 'primereact/multiselect';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import ContactsService from '../../services/ContactsService';
import ProductsService from '../../services/ProductsService';

interface State {
  loading: boolean;
  items: Draft[];
  totalItems: number;
  selectedItems: Draft[];
  productDefinitions: Map<number, PaProductDefinition[]>;
  activeFilters: {};
  availableOwners: PaContact[];
  filters: {
    draft_code: string;
    reference: string;
    size: number | undefined;
    summary: string;
    owner_ids: string[];
    order_id: string;
    created_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    updated_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  error: boolean;
  showAddEditDialog: boolean;
  showDeletedDrafts: boolean;
  printDrafts: boolean;
}

class OrderListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  draftsService?: DraftsService;
  contactsService?: ContactsService;
  toastService?: ToastService;
  productsService?: ProductsService;
  usersService?: UsersService;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: {}) {
    super(props);
    this.state = {
      items: [],
      totalItems: 0,
      loading: false,
      selectedItems: [],
      productDefinitions: new Map<number, PaProductDefinition[]>(),
      availableOwners: [],
      activeFilters: {},
      filters: {
        draft_code: '',
        size: undefined,
        reference: '',
        summary: '',
        owner_ids: [],
        order_id: '',
        created_at: {
          fromDate: null,
          toDate: null,
        },
        updated_at: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      error: false,
      showAddEditDialog: false,
      showDeletedDrafts: false,
      printDrafts: false,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.codeBodyTemplate = this.codeBodyTemplate.bind(this);
    this.orderBodyTemplate = this.orderBodyTemplate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.createdAtBodyTemplate = this.createdAtBodyTemplate.bind(this);
    this.updatedAtBodyTemplate = this.updatedAtBodyTemplate.bind(this);
    this.initTableMenu = this.initTableMenu.bind(this);
    this.initRowMenu = this.initRowMenu.bind(this);
    this.toggleShowDeleted = this.toggleShowDeleted.bind(this);
    this.deleteDrafts = this.deleteDrafts.bind(this);
    this.unDeleteDrafts = this.unDeleteDrafts.bind(this);
    this.printSelectedDrafts = this.printSelectedDrafts.bind(this);
    this.onPrintDialogOpen = this.onPrintDialogOpen.bind(this);
  }

  async componentDidMount() {
    this.draftsService = this.context.draftsService;
    this.contactsService = this.context.contactsService;
    this.toastService = this.context.toastService;
    this.productsService = this.context.productsService;
    this.usersService = this.context.usersService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.draftCreated || message === messages.orderCreated) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name === messages.topSelectionChanged) {
          this.loadData();
        }
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.draft_code) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.draft_code,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'reference',
          value: this.state.filters.reference,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.size) {
      filters.push(
        JSON.stringify({
          field: 'size',
          value: this.state.filters.size,
        })
      );
    }
    if (this.state.filters.owner_ids && this.state.filters.owner_ids.length) {
      filters.push(
        JSON.stringify({
          field: "owner_contact_ref->>'user_id'",
          value: this.state.filters.owner_ids,
          condition: 'in',
        })
      );
    }
    if (this.state.filters.summary) {
      filters.push(
        JSON.stringify({
          field: 'summary',
          value: this.state.filters.summary,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.order_id) {
      filters.push(
        JSON.stringify({
          field: 'order_id',
          value: this.state.filters.order_id,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.created_at.fromDate) {
      const fromDate = this.state.filters.created_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.created_at.toDate) {
      const toDate = this.state.filters.created_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.showDeletedDrafts) {
      filters.push(
        JSON.stringify({
          condition: 'includeDeleted',
          value: true,
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    const sortBy = {
      field: this.state.sortBy?.field ?? 'draft_code',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'draft_code':
        sortBy.field = 'id';
        break;
      case 'summary':
        sortBy.field = 'size';
        break;
    }

    const sortByStringyField = JSON.stringify(sortBy.field ? sortBy : {field: 'id', direction: 'DESC'});

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyField],
      aggregate: true,
    };

    this.draftsService
      ?.getDrafts(params)
      .then((data: ApiListResponse) => {
        const dataRecords = (data.records as Draft[]) ?? [];
        this.handleSelectedItems(dataRecords);
        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Loading Draft failed. Please refresh.');
        this.setState({loading: false});
        console.log(error);
      });

    this.contactsService
      ?.getContacts({})
      .then(data => {
        const contacts = data.records as PaContact[];
        this.setState({availableOwners: contacts});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Loading contacts failed. Please refresh.');
        this.setState({loading: false});
        console.log(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | DropdownChangeParams
      | InputSwitchChangeParams
      | DateColumnFilterChangeEvent
  ) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleSelectedItems(allItems: Draft[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Draft[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Draft[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Draft) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  codeBodyTemplate(rowData: Draft) {
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          key={'draft-row' + rowData.id}
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          initMenuItems={() => this.initRowMenu()}
          selectedItems={this.state.selectedItems}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <NavLink to={'draft/' + rowData.id}>{rowData.id}</NavLink>
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  ownerBodyTemplate(rowData: Draft) {
    return <span>{rowData.owner_contact_ref?.label ?? ''}</span>;
  }

  orderBodyTemplate(rowData: Draft) {
    if (rowData.order_id) {
      return (
        <React.Fragment>
          <NavLink to={'Order/' + rowData.order_id}>{rowData.order_id}</NavLink>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  }

  createdAtBodyTemplate(rowData: Draft) {
    const dateFormat = this.usersService?.settings?.date_format;
    const formattedDate = rowData.created_at
      ? DateTime.fromISO(rowData.created_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'date'))
      : '';
    const formattedDateTime = rowData.created_at
      ? DateTime.fromISO(rowData.created_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))
      : '';
    const id = `created-at-${rowData.id}`;
    return (
      <>
        <Tooltip target={`#${id}`}>{formattedDateTime}</Tooltip>
        <span id={id}>{formattedDate}</span>
      </>
    );
  }

  updatedAtBodyTemplate(rowData: Draft) {
    const dateFormat = this.usersService?.settings?.date_format;
    const formattedDate = rowData.updated_at
      ? DateTime.fromISO(rowData.updated_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'date'))
      : '';
    const formattedDateTime = rowData.updated_at
      ? DateTime.fromISO(rowData.updated_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))
      : '';
    const id = `updated-at-${rowData.id}`;
    return (
      <>
        <Tooltip target={`#${id}`}>{formattedDateTime}</Tooltip>
        <span id={id}>{formattedDate}</span>
      </>
    );
  }

  deleteBodyTemplate(rowData: Draft) {
    return rowData.deleted ? 'Yes' : 'No';
  }

  handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputSwitchChangeParams
  ) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, 1000);
  };

  availableOwnerOptions() {
    return this.state.availableOwners
      ? this.state.availableOwners.map(owner => {
          const option: DropdownOption = {
            label: `${owner.first_name} ${owner.last_name}` ?? 'err',
            value: owner.user_id!,
          };
          return option;
        })
      : [];
  }

  async onFilterOwnerChange(e: DropdownChangeParams) {
    await this.setState({
      filters: {
        ...this.state.filters,
        owner_ids: e.value,
      },
    });
    this.loadData();
  }

  toggleShowDeleted() {
    this.setState(
      {
        showDeletedDrafts: !this.state.showDeletedDrafts,
      },
      () => {
        MessageService.sendMessage(messages.initTableMenuItems);
        this.loadData();
      }
    );
  }

  showAddEditDialog() {
    this.setState({showAddEditDialog: true});
  }

  hideAddEditDialog = () => {
    this.setState({showAddEditDialog: false});
  };

  initTableMenu(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    menuItems.push({
      label: 'Add New',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddEditDialog();
      },
    });

    menuItems.push(...this.initRowMenu());

    if (localStorage.getItem(localStorageAttributes.currentRole) === 'admin') {
      menuItems.push({
        label: `${this.state.showDeletedDrafts ? 'Hide Deleted' : 'Show Deleted'}`,
        faIcon: this.state.showDeletedDrafts ? faToggleOn : faToggleOff,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: async () => {
          this.toggleShowDeleted();
        },
      });
    }

    return menuItems;
  }

  initRowMenu(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    if (this.state.selectedItems.length > 0) {
      menuItems.push({
        label: 'Print',
        faIcon: faPrint,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.printSelectedDrafts();
        },
      });
      const notDeletedSelected = this.state.selectedItems.filter(draft => !draft.deleted);
      const notDeletedCount = notDeletedSelected.length;
      if (notDeletedCount === this.state.selectedItems.length) {
        menuItems.push({
          label: 'Delete',
          faIcon: faTrash,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.deleteDrafts();
          },
        });
      }
      if (notDeletedCount === 0) {
        menuItems.push({
          label: 'Undelete',
          faIcon: faTrashUndo,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.unDeleteDrafts();
          },
        });
      }
    }
    return menuItems;
  }

  async deleteDrafts() {
    this.setState({loading: true});
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!companyId) {
      this.setState({loading: false});
      this.toastService?.showError(this.toast, 'Sorry, something went wrong. Please refresh and try again.');
      return;
    }
    this.state.selectedItems.map(draft => {
      this.draftsService
        ?.deleteDraft(companyId, draft.id)
        .then(() => {
          this.toastService?.showSuccess(this.toast, `Draft ${draft.id} ${draft.reference} successfully deleted.`);
          this.loadData();
        })
        .catch(error => {
          console.error(error);
          this.toastService?.showError(this.toast, `Oh no, draft ${draft.id} ${draft.reference} failed to delete.`);
          this.setState({loading: false});
        });
    });
  }

  async unDeleteDrafts() {
    this.setState({loading: true});
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!companyId) {
      this.setState({loading: false});
      this.toastService?.showError(this.toast, 'Sorry, something went wrong. Please refresh and try again.');
      return;
    }
    this.state.selectedItems.map(draft => {
      const draftPatch: DraftPatch = {
        deleted: false,
      };
      this.draftsService
        ?.updateDraft(companyId, draft.id, draftPatch)
        .then(() => {
          this.toastService?.showSuccess(this.toast, `Draft ${draft.id} ${draft.reference} successfully un-deleted.`);
          this.loadData();
        })
        .catch(error => {
          console.error(error);
          this.toastService?.showError(this.toast, `Oh no, draft ${draft.id} ${draft.reference} failed to un-delete.`);
          this.setState({loading: false});
        });
    });
  }

  exportQuote() {
    MessageService.sendMessage(messages.quoteExport);
  }

  printSelectedDrafts() {
    if (!this.state.selectedItems.length) {
      return;
    }

    this.setState({
      loading: true,
    });

    const revisionIds: number[] = [];
    for (const selectedDraft of this.state.selectedItems) {
      if (selectedDraft.revision_id && !revisionIds.includes(selectedDraft.revision_id)) {
        revisionIds.push(selectedDraft.revision_id);
      }
    }
    const requests = [];
    for (const revisionId of revisionIds) {
      requests.push(
        this.productsService?.getProductsDefinitions(revisionId).then((data: ApiListResponse) => {
          this.state.productDefinitions.set(revisionId, data.records as PaProductDefinition[]);
        })
      );
    }
    Promise.all(requests)
      .then(() => {
        this.setState(
          {
            loading: false,
            printDrafts: true,
          },
          () => MessageService.sendMessage(messages.printDrafts)
        );
      })
      .catch(e => {
        this.toastService?.showError(this.toast, 'Loading product definitions failed, please try again.');
        this.setState({loading: false});
        console.log(e);
      });
  }

  onPrintDialogOpen() {
    this.setState({printDrafts: false});
  }

  render() {
    const codeFilter = (
      <InputText
        name="draft_code"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const orderBodyFilter = (
      <InputText
        name="order_id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedOwnerTemplate = (value: string) => {
      if (value) {
        const selectedRef = this.state.availableOwners.find(owner => owner.user_id! === value);
        if (selectedRef) {
          return <span>{selectedRef.first_name + ' ' + selectedRef.last_name}</span>;
        }
      }

      return <></>;
    };

    const ownerOptionTemplate = (option: DropdownOption) => {
      const optionRef = this.state.availableOwners.find(owner => owner.user_id! === option.value);
      if (optionRef) {
        return <span>{optionRef.first_name + ' ' + optionRef.last_name}</span>;
      }

      return <></>;
    };

    const ownerFilter = (
      <MultiSelect
        selectedItemTemplate={selectedOwnerTemplate}
        itemTemplate={ownerOptionTemplate}
        value={this.state.filters.owner_ids}
        options={this.availableOwnerOptions()}
        name="owner"
        className="form-filter"
        onChange={e => {
          this.onFilterOwnerChange(e);
        }}
        showClear
      />
    );

    const createdAtFilter = (
      <DateColumnFilter
        name="created_at"
        value={this.state.filters.created_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const updatedAtFilter = (
      <DateColumnFilter
        name="updated_at"
        value={this.state.filters.updated_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const summaryFilter = (
      <InputText
        name="summary"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="order_list_page_container" className={'page-container'}>
        <TwoDataTable
          id={'drafts-table'}
          pageSizeIdentifier={'order_list_page_container'}
          selectedItems={this.state.selectedItems}
          selectionMode="multiple"
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as Draft[])}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          initMenuItems={() => this.initTableMenu()}
          isMenuDynamic={true}
        >
          <Column
            header="Draft Code"
            field="draft_code"
            body={this.codeBodyTemplate}
            filter
            filterElement={codeFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Reference"
            field="reference"
            filter
            filterElement={referenceFilter}
            sortable
            style={{width: '360px'}}
            showFilterMenu={false}
          />
          <Column
            header="Owner"
            field="owner_contact_ref"
            body={this.ownerBodyTemplate}
            filter
            filterElement={ownerFilter}
            sortable
            style={{width: '160px'}}
            showFilterMenu={false}
          />

          <Column
            header="Summary"
            field="summary"
            filter
            filterElement={summaryFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Order"
            field="order_id"
            body={this.orderBodyTemplate}
            filter
            filterElement={orderBodyFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Created"
            field="created_at"
            body={this.createdAtBodyTemplate}
            filter
            filterElement={createdAtFilter}
            sortable
            style={{width: '90px'}}
            showFilterMenu={false}
          />
          <Column
            header="Last Update"
            field="updated_at"
            body={this.updatedAtBodyTemplate}
            filter
            filterElement={updatedAtFilter}
            sortable
            style={{width: '90px'}}
            showFilterMenu={false}
          />

          {this.state.showDeletedDrafts && (
            <Column header="Deleted" field="deleted" body={this.deleteBodyTemplate} sortable />
          )}
        </TwoDataTable>
        <Toast ref={this.toast} />

        <NewEditDraftDialog
          toast={this.toast}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideAddEditDialog}
        />
        {this.state.printDrafts && (
          <DraftsPrinterComponent
            drafts={this.state.selectedItems}
            productDefinitionRevisions={this.state.productDefinitions}
            triggerMessage={messages.printDrafts}
            onPrintDialogOpen={this.onPrintDialogOpen}
          />
        )}
      </div>
    );
  }
}

export default OrderListComponent;
