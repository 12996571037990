import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Subscription} from 'rxjs';
import {Toast} from 'primereact/toast';
import {
  MessageService,
  AppContext,
  TwoDataTable,
  ToastService,
  TwoMessage,
  AppMenuItem,
  AppMenuItemTemplate,
  AppColumnMenuBodyTemplate,
  DcmPrinterComponent,
  UsersService,
} from 'two-app-ui';
import {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {
  QueryParameter,
  Job,
  JobStage,
  ApiListResponse,
  AppointmentType,
  Appointment,
  JobAggregate,
  Company,
  AppointmentPatch,
  UserReference,
  TleContentAppointmentEvent,
  JobPatch,
  JobDocument,
  PaProductDefinition,
  DcmContent,
} from 'two-core';
import '../../scss/CustomTable.scss';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitchChangeParams} from 'primereact/inputswitch';
import {messages} from '../../config/messages';
import {appointmentStages, jobStages, requestedServices} from '../../config/values';
import JobsService from '../../services/JobsService';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import {JobAppointmentReference} from '../Reference/JobAppointmentReference/JobAppointmentReference';
import {MenuItemOptions} from 'primereact/menuitem';
import {
  faClipboardListCheck,
  faPlus,
  faPrint,
  faToggleOff,
  faToggleOn,
  faUserHelmetSafety,
} from '@fortawesome/pro-regular-svg-icons';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {JobOwnerColumn} from './JobOwnerColumn';
import JobOrderColumn from './JobOrderColumn';
import {RequestJobDialog} from '../Job/RequestJobDialog/RequestJobDialog';
import {NavLink} from 'react-router-dom';
import {FitterAssignmentDialog} from '../Job/FitterAssignmentDialog';

import {ReviewMeasureDialog} from '../Job/ReviewMeasureDialog/ReviewMeasureDialog';
import {ReviewInstallDialog} from '../Job/ReviewInstallDialog';
import {AddNoteDialog} from '../Job/AddNoteDialog';
import {BookAppointmentDialog} from '../Job/BookAppointmentDialog';
import AppointmentsService from '../../services/AppointmentsService';
import JobDocumentsService from '../../services/JobDocumentsService';
import ProductsService from '../../services/ProductsService';
import {DateTime} from 'luxon';
import config from '../../config/config';

interface DefinitionFilter {
  revisionId: number;
  companyId: string;
}

export type JobListMode =
  | 'Requested'
  | 'In Progress'
  | 'Measure Reviews'
  | 'In Production & Shipping'
  | 'Delivered'
  | 'Installs'
  | 'Install Reviews'
  | 'All'
  | 'Order Entity Panel';

interface Props {
  mode: JobListMode;
  orderId?: string;
}

interface State {
  loadingJobs: boolean;
  loadingPrintData: boolean;
  jobs: Job[];
  totalJobs: number;
  selectedJobs: Job[];
  activeFilters: {};
  filters: {
    title: string;
    suburb: string;
    order_id: string;
    owner: string;
    fitter: string;
    stage: string[];
    requested_services: string[];
    measure_stage: string[];
    install_stage: string[];
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  availableStages: JobStage[];
  hiddenColumns: Set<string>;
  showCompleted: boolean;
  showRequestJobDialog: boolean;
  showFitterAssignmentJobDialog: boolean;
  showBookAppointmentDialog: boolean;
  showReviewMeasureDialog: boolean;
  showReviewInstallDialog: boolean;
  showAddNoteDialog: boolean;
  hideFilters: boolean;
  disableSelect: boolean;
  disableMenu: boolean;
  disableLink: boolean;
  hideSorting: boolean;
  printDcms: boolean;
  dcmsMap: Map<string, JobDocument>;
  productDefinitionsMap: Map<number, PaProductDefinition[]>;
}

export default class JobListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  jobsService?: JobsService;
  jobDocumentsService?: JobDocumentsService;
  productsService?: ProductsService;
  toastService?: ToastService;
  appointmentsService?: AppointmentsService;
  usersService?: UsersService;

  subscription: Subscription = new Subscription();
  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      jobs: [],
      totalJobs: 0,
      loadingJobs: false,
      selectedJobs: [],
      activeFilters: {},
      filters: {
        title: '',
        suburb: '',
        order_id: '',
        owner: '',
        fitter: '',
        stage: [],
        requested_services: [],
        measure_stage: [],
        install_stage: [],
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,

      availableStages: jobStages,
      hiddenColumns: new Set<string>(),
      showCompleted: false,
      showRequestJobDialog: false,
      showFitterAssignmentJobDialog: false,
      showBookAppointmentDialog: false,
      showReviewMeasureDialog: false,
      showReviewInstallDialog: false,
      showAddNoteDialog: false,
      hideFilters: false,
      disableSelect: false,
      disableMenu: false,
      disableLink: false,
      hideSorting: false,
      printDcms: false,
      productDefinitionsMap: new Map<number, PaProductDefinition[]>(),
      dcmsMap: new Map<string, JobDocument>(),
      loadingPrintData: false,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleChangeSelectedItems = this.handleChangeSelectedItems.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.loadJobs = this.loadJobs.bind(this);
    this.titleColumnTemplate = this.titleColumnTemplate.bind(this);
    this.measureColumnTemplate = this.measureColumnTemplate.bind(this);
    this.installColumnTemplate = this.installColumnTemplate.bind(this);
    this.stageColumnTemplate = this.stageColumnTemplate.bind(this);
    this.initTableMenu = this.initTableMenu.bind(this);
    this.toggleCompleted = this.toggleCompleted.bind(this);
    this.titleFilter = this.titleFilter.bind(this);
    this.stageFilter = this.stageFilter.bind(this);
    this.suburbFilter = this.suburbFilter.bind(this);
    this.ownerFilter = this.ownerFilter.bind(this);
    this.orderFilter = this.orderFilter.bind(this);
    this.fitterFilter = this.fitterFilter.bind(this);
    this.requestedServicesFilter = this.requestedServicesFilter.bind(this);
    this.measureFilter = this.measureFilter.bind(this);
    this.installFilter = this.installFilter.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.onShowRequestJobDialog = this.onShowRequestJobDialog.bind(this);
    this.onShowFitterAssignmentDialog = this.onShowFitterAssignmentDialog.bind(this);
    this.onHideRequestJobDialog = this.onHideRequestJobDialog.bind(this);
    this.onHideFitterAssignmentDialog = this.onHideFitterAssignmentDialog.bind(this);
    this.onShowBookApplicationDialog = this.onShowBookApplicationDialog.bind(this);
    this.onHideBookApplicationDialog = this.onHideBookApplicationDialog.bind(this);
    this.onCancelAppointment = this.onCancelAppointment.bind(this);
    this.onShowReviewMeasureDialog = this.onShowReviewMeasureDialog.bind(this);
    this.onHideReviewMeasureDialog = this.onHideReviewMeasureDialog.bind(this);
    this.onShowReviewInstallDialog = this.onShowReviewInstallDialog.bind(this);
    this.onHideReviewInstallDialog = this.onHideReviewInstallDialog.bind(this);
    this.onShowAddNoteDialog = this.onShowAddNoteDialog.bind(this);
    this.onHideAddNoteDialog = this.onHideAddNoteDialog.bind(this);
    this.onBookAppointment = this.onBookAppointment.bind(this);
    this.loadDcms = this.loadDcms.bind(this);
    this.onPrintDcms = this.onPrintDcms.bind(this);
    this.loadProductDefinitionsMap = this.loadProductDefinitionsMap.bind(this);
  }

  async componentDidMount() {
    const {mode, orderId} = this.props;

    this.jobsService = this.context.jobsService;
    this.jobDocumentsService = this.context.jobDocumentsService;
    this.productsService = this.context.productsService;
    this.toastService = this.context.toastService;
    this.appointmentsService = this.context.appointmentsService;
    this.usersService = this.context.usersService;

    this.modeSetup(mode, orderId);

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.jobCreated || message === messages.jobUpdated) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name === messages.topSelectionChanged) {
          this.loadData();
        }
      }
    });
  }

  modeSetup(mode: JobListMode, orderId?: string) {
    let hideFilters = false;
    let hideSorting = false;
    let disableSelect = false;
    let disableMenu = false;
    let disableLink = false;
    let sortBy: {
      field: string;
      order: DataTableSortOrderType;
    } | null = null;
    let availableStages: JobStage[] = [
      'New',
      'Assigned',
      'Measure Planned',
      'Measure Booked',
      'Measure Started',
      'Measure Finished',
      'Measure Review',
      'Measure Approval Pending',
      'In Production',
      'In Shipping',
      'Install Planned',
      'Install Booked',
      'Install Started',
      'Install Finished',
      'Install Review',
      'Canceled',
    ];
    const hiddenColumns = new Set<string>();
    if (!this.usersService?.settings?.ux?.made2order?.jobs?.stages) {
      hiddenColumns.add('stage');
    }
    if (!this.usersService?.settings?.ux?.made2order?.jobs?.appointments) {
      hiddenColumns.add('measure');
      hiddenColumns.add('install');
    }
    if (!this.usersService?.settings?.ux?.made2order?.jobs?.age) {
      hiddenColumns.add('age');
    }

    const usJobs = config().system.filterJobsForUs;

    switch (mode) {
      case 'Requested':
        hiddenColumns.add('install');
        availableStages = usJobs ? ['New', 'Assigned', 'Measure Planned', 'Measure Booked'] : ['New'];
        break;
      case 'In Progress':
        hiddenColumns.add('install');
        availableStages = usJobs
          ? ['Measure Started', 'Measure Finished']
          : ['Assigned', 'Measure Planned', 'Measure Booked', 'Measure Started', 'Measure Finished'];
        break;
      case 'Measure Reviews':
        hiddenColumns.add('install');
        availableStages = ['Measure Review', 'Measure Approval Pending'];
        break;
      case 'In Production & Shipping':
        availableStages = ['In Production', 'In Shipping'];
        break;
      case 'Delivered':
        //todo add 'Delivered' into available stage once available
        availableStages = ['Install Planned'];
        break;
      case 'Installs':
        availableStages = ['Install Booked', 'Install Started', 'Install Finished'];
        break;
      case 'Install Reviews':
        availableStages = ['Install Review'];
        break;
      case 'Order Entity Panel':
        hiddenColumns.add('address.suburb');
        hiddenColumns.add('order_id');
        hideFilters = true;
        disableSelect = true;
        hideSorting = true;
        disableMenu = true;
        disableLink = true;
        sortBy = {field: 'created_at', order: 0};
        break;
    }
    const fitsForOthersString = localStorage.getItem(localStorageAttributes.fittsForOthers);
    if (fitsForOthersString !== 'true') {
      hiddenColumns.add('owner');
    }
    this.setState(
      state => {
        return {
          availableStages: availableStages,
          hiddenColumns: hiddenColumns,
          hideFilters: hideFilters,
          disableSelect: disableSelect,
          hideSorting: hideSorting,
          disableMenu: disableMenu,
          disableLink: disableLink,
          filters: {...state.filters, order_id: orderId ?? ''},
          sortBy: sortBy ?? state.sortBy,
        };
      },
      () => this.loadData()
    );
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {mode, orderId} = this.props;
    if (mode !== prevProps.mode) {
      this.modeSetup(mode, orderId);
    }
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    const promises: Promise<void | undefined>[] = [];
    promises.push(this.loadJobs());
    Promise.all(promises).catch(error => {
      console.error('Failed Loading Data:' + error);
      this.toastService?.showError(this.toast, 'Failed loading data, please refresh and try again.');
    });
  }

  async loadJobs() {
    this.setState({loadingJobs: true});
    const {filters, availableStages, pagination, showCompleted, sortBy} = this.state;
    const queryFilters: string[] = [];

    let stageFilterValue: JobStage[] = [];
    if (filters.stage?.length) {
      stageFilterValue = filters.stage as JobStage[];
    } else {
      if (availableStages.length) {
        stageFilterValue = Array.from(availableStages);
      }
    }
    if (showCompleted) {
      stageFilterValue.push('Completed', 'Completed With Repair');
    }
    if (stageFilterValue.length) {
      queryFilters.push(
        JSON.stringify({
          field: 'stage',
          value: stageFilterValue,
          condition: 'in',
        })
      );
    }

    if (filters.title) {
      queryFilters.push(
        JSON.stringify({
          field: 'title',
          value: filters.title,
          condition: 'iLike',
        })
      );
    }

    if (filters.suburb) {
      queryFilters.push(
        JSON.stringify({
          field: "address->>'suburb'",
          value: filters.suburb,
          condition: 'iLike',
        })
      );
    }

    if (filters.owner) {
      queryFilters.push(
        JSON.stringify({
          field: 'owner_company.account_number',
          value: filters.owner,
          condition: 'iLike',
          tempTable: true,
        })
      );
    }

    if (filters.fitter) {
      queryFilters.push(
        JSON.stringify({
          field: "lead_fitter->>'label'",
          value: filters.fitter,
          condition: 'iLike',
        })
      );
    }

    if (filters.order_id) {
      queryFilters.push(
        JSON.stringify({
          field: 'order_id',
          value: filters.order_id,
          condition: 'iLike',
        })
      );
    }

    if (filters.requested_services?.length) {
      queryFilters.push(
        JSON.stringify({
          field: 'requested_services',
          value: filters.requested_services,
          condition: 'in',
        })
      );
    }

    if (filters.measure_stage?.length) {
      const appointmentTypes: AppointmentType[] = ['Consultation', 'Check Measure', 'Service Call'];
      queryFilters.push(
        JSON.stringify({
          field: 'appointment.stage',
          value: filters.measure_stage,
          condition: 'in',
        }),
        JSON.stringify({
          field: 'appointment.type',
          value: appointmentTypes,
          condition: 'in',
        })
      );
    }

    if (filters.install_stage?.length) {
      const appointmentTypes: AppointmentType[] = ['Installation', 'Repair'];
      queryFilters.push(
        JSON.stringify({
          field: 'appointment.stage',
          value: filters.install_stage,
          condition: 'in',
        }),
        JSON.stringify({
          field: 'appointment.type',
          value: appointmentTypes,
          condition: 'in',
        })
      );
    }

    this.setState({activeFilters: {...queryFilters}});

    const orderBy = {
      field: sortBy?.field,
      direction: sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (orderBy.field) {
      case 'address.suburb':
        orderBy.field = "address->>'suburb'";
        break;
    }
    const queryOrderBys = [JSON.stringify(orderBy.field ? orderBy : {field: 'updated_at', direction: 'DESC'})];

    const aggregate: JobAggregate[] = ['owner', 'appointments', 'fitting_provider'];

    const params: QueryParameter = {
      offset: pagination.offset,
      page_size: pagination.pageSize,
      filters: queryFilters,
      orderBys: queryOrderBys,
      aggregate: aggregate,
    };

    return this.jobsService
      ?.getJobs(params)
      .then((data: ApiListResponse) => {
        const dataRecords = (data.records as Job[]) ?? [];
        this.handleSelectedItems(dataRecords);
        this.setState({
          jobs: dataRecords,
          totalJobs: data.total_records ?? 0,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Records load failed');
        console.log(error);
      })
      .finally(() => this.setState({loadingJobs: false}));
  }

  async loadDcms(jobIds: string[]) {
    const documentFilter: string[] = [
      JSON.stringify({
        field: 'job_id',
        value: jobIds,
        condition: 'in',
      }),
      JSON.stringify({
        field: 'type',
        value: 'DCM',
      }),
    ];
    const documentParams: QueryParameter = {
      filters: documentFilter,
      aggregate: false,
    };
    return this.jobDocumentsService!.getJobDocuments(documentParams)
      .then(data => {
        return (data.records as JobDocument[]) ?? [];
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Loading dcms failed. Please try again.');
        console.log(error);
      });
  }

  async loadProductDefinitionsMap(definitionFilters: DefinitionFilter[]) {
    const requests = [];
    for (const {revisionId, companyId} of definitionFilters) {
      requests.push(this.productsService?.getProductsDefinitions(revisionId, companyId));
    }
    return Promise.all(requests)
      .then(results => {
        const productDefinitionsMap = new Map<number, PaProductDefinition[]>();
        for (const result of results) {
          const productDefinitions = (result?.records as PaProductDefinition[]) ?? [];
          if (productDefinitions.length) {
            productDefinitionsMap.set(productDefinitions[0].revision_id, productDefinitions);
          }
        }
        return productDefinitionsMap;
      })
      .catch(e => {
        this.toastService?.showError(this.toast, 'Loading product definitions failed, please try again.');
        console.log(e);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    this.setState({pagination: {offset: e.first, pageSize: e.rows}}, () => {
      this.loadJobs();
    });
  }

  async onSort(e: DataTableSortParams) {
    this.setState({sortBy: {field: e.sortField, order: e.sortOrder}}, () => {
      this.loadJobs();
    });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    return currentUser?.uuid ?? '';
  }

  onBookAppointment(appointmentPatch: AppointmentPatch, newFittingProviderId?: string, newFitter?: UserReference) {
    const job = this.state.selectedJobs[0];
    const saves: Promise<void>[] = [];
    const userId = this.getCurrentUserId();

    if (appointmentPatch.id) {
      saves.push(this.updateAppointment(appointmentPatch.id, appointmentPatch, userId));
    } else {
      saves.push(this.createAppointment(appointmentPatch, userId));
    }
    if (job?.fitting_provider_id !== newFittingProviderId || job?.lead_fitter?.user_id !== newFitter?.user_id) {
      saves.push(this.updateFitter(job!, userId, newFittingProviderId, newFitter));
    }
    return Promise.all(saves).finally(() => {
      this.loadData();
    });
  }

  async createAppointment(appointmentPatch: AppointmentPatch, userId: string) {
    appointmentPatch.tles_success = [
      {
        event_type: 'appointment_event',
        entity_type: 'job',
        entity_id: this.state.selectedJobs[0].id!,
        recorded_at: new Date(),
        recorded_by: userId,
        content: {
          app_type: appointmentPatch.type,
          app_stage: appointmentPatch.stage,
          start: appointmentPatch.start_plan,
          end: appointmentPatch.end_plan,
        } as TleContentAppointmentEvent,
      },
    ];
    this.appointmentsService
      ?.createAppointment(appointmentPatch)
      .then(() => {
        this.toastService?.showSuccess(this.toast, `${appointmentPatch.type} ${appointmentPatch.stage} Successfully.`);
        this.setState({showBookAppointmentDialog: false});
        this.loadData();
      })
      .catch(error => {
        console.error('Saving appointment failed: ', error);
        this.toastService?.showError(this.toast, 'Sorry, appointment creation failed, please try again.');
      });
  }

  async updateAppointment(appointmentId: number, appointmentPatch: AppointmentPatch, userId: string) {
    appointmentPatch.tles_success = [
      {
        event_type: 'appointment_event',
        entity_type: 'job',
        entity_id: this.state.selectedJobs[0].id!,
        recorded_at: new Date(),
        recorded_by: userId,
        content: {
          app_type: appointmentPatch.type,
          app_stage: appointmentPatch.stage,
          start: appointmentPatch.start_plan,
          end: appointmentPatch.end_plan,
        } as TleContentAppointmentEvent,
      },
    ];

    this.appointmentsService
      ?.updateAppointment(appointmentId, appointmentPatch)
      .then(() => {
        this.toastService?.showSuccess(
          this.toast,
          `${appointmentPatch.type ?? 'Appointment'} ${appointmentPatch.stage ?? 'Updated'} Successfully.`
        );
        this.setState({showBookAppointmentDialog: false});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, appointment update failed, please try again.');
        console.error('error: ' + error);
      });
  }

  async updateFitter(job: Job, userId: string, newFittingProviderId?: string, newFitter?: UserReference) {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!currentCompanyId) {
      this.toastService?.showError(this.toast, 'Sorry, fitter update failed, please refresh and try again.');
      console.error('Current Company ID not found');
    }

    const jobPatch: JobPatch = {};
    if (newFittingProviderId) {
      jobPatch.fitting_provider_id = newFittingProviderId;
    }
    if (newFitter) {
      jobPatch.lead_fitter = newFitter;
    }
    this.jobsService
      ?.updateJob(job.id!, jobPatch, currentCompanyId!)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Fitter re-assigned successfully.');
        this.setState({showBookAppointmentDialog: false});
        MessageService.sendMessage(messages.jobUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, fitter re-assignment failed, please refresh and try again.');
        console.error('error: ' + error);
      });
  }

  async onFilterChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | DropdownChangeParams
      | InputSwitchChangeParams
      | DateColumnFilterChangeEvent
  ) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadJobs();
  }

  handleSelectedItems(allItems: Job[]) {
    const selectedJobs = [...this.state.selectedJobs];
    if (selectedJobs.length) {
      const jobs: Job[] = allItems.filter(item => {
        return selectedJobs.find(selectedItem => {
          return selectedItem.id === item.id;
        });
      });

      this.handleChangeSelectedItems(jobs);
    }
  }

  handleChangeSelectedItems(jobs: Job[]) {
    this.setState({selectedJobs: jobs});
  }

  async handleChangeSelectedItem(item: Job) {
    const jobs = [...this.state.selectedJobs];
    const existingItem = jobs.find(i => i.id === item.id);
    if (!existingItem) {
      jobs.push(item);
      await this.setState({selectedJobs: jobs});
    }
  }

  handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputSwitchChangeParams
  ) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, 1000);
  };

  toggleCompleted() {
    const {showCompleted} = this.state;
    this.setState({showCompleted: !showCompleted}, () => this.loadData());
  }

  initTableMenu(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    const {mode} = this.props;
    const {filters, selectedJobs} = this.state;

    if (mode === 'All') {
      const showCompleted = filters.stage.includes('Completed');
      menuItems.push({
        label: showCompleted ? 'Hide Completed' : 'Show Completed',
        faIcon: showCompleted ? faToggleOn : faToggleOff,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: this.toggleCompleted,
      });
    }
    if (!selectedJobs.length) {
      menuItems.push({
        label: 'Request New',
        faIcon: faPlus,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: this.onShowRequestJobDialog,
      });
    }
    menuItems.push(...this.initMenuItems());
    return menuItems;
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    const {mode} = this.props;
    const {selectedJobs} = this.state;

    if (selectedJobs.length === 1) {
      const selectedJob = selectedJobs[0];
      if (
        selectedJobs.length === 1 &&
        (selectedJob.stage === 'New' ||
          selectedJob.stage === 'Assigned' ||
          selectedJob.stage === 'Measure Planned' ||
          selectedJob.stage === 'In Production' ||
          selectedJob.stage === 'In Shipping' ||
          selectedJob.stage === 'Delivered' ||
          selectedJob.stage === 'Install Planned')
      ) {
        const currentCompId = localStorage.getItem(localStorageAttributes.currentCompanyId);
        if (selectedJob.fitting_provider_id === currentCompId) {
          // menuItems.push({
          //   label: 'Book',
          //   faIcon: faCalendar,
          //   template: (item: AppMenuItem, options: MenuItemOptions) => {
          //     return <AppMenuItemTemplate item={item} options={options} />;
          //   },
          //   command: this.onShowBookApplicationDialog,
          // });
        }
      }
    }
    if (selectedJobs.length > 0) {
      if (mode === 'Requested' || mode === 'All') {
        const isCompletedSelected = selectedJobs.some(
          job => job.stage === 'Completed' || job.stage === 'Completed With Repair'
        );
        if (!isCompletedSelected) {
          const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '[]';
          const currentCompany = JSON.parse(currentCompanyString) as Company;
          if (currentCompany.fitting_types !== 'made2fit') {
            const isAllFitterAssigned = selectedJobs.every(
              job => !job.fitting_provider_id || (job.fitting_provider_id === currentCompany.id && !job.lead_fitter)
            );
            const label = `${isAllFitterAssigned ? 'Assign' : 'Re-assign'} Fitter`;
            menuItems.push({
              label: label,
              faIcon: faUserHelmetSafety,
              template: (item: AppMenuItem, options: MenuItemOptions) => {
                return <AppMenuItemTemplate item={item} options={options} />;
              },
              command: this.onShowFitterAssignmentDialog,
            });
          }
        }
      }
      if (mode === 'Measure Reviews') {
        menuItems.push({
          label: 'Print',
          faIcon: faPrint,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.onPrintDcms();
          },
        });
      }
    }
    if (selectedJobs.length === 1) {
      const selectedJob = selectedJobs[0];
      if (selectedJob?.stage === 'Measure Booked' || selectedJob?.stage === 'Install Booked') {
        // menuItems.push({
        //   label: 'Re-Book',
        //   faIcon: faCalendar,
        //   template: (item: AppMenuItem, options: MenuItemOptions) => {
        //     return <AppMenuItemTemplate item={item} options={options} />;
        //   },
        //   command: this.onShowBookApplicationDialog,
        // });
      }
      if (selectedJob.stage === 'Measure Review' || selectedJob.stage === 'Measure Approval Pending') {
        menuItems.push({
          label: 'Measure Review',
          faIcon: faClipboardListCheck,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: this.onShowReviewMeasureDialog,
        });
      }
      if (selectedJob.stage === 'Install Review') {
        menuItems.push({
          label: 'Install Review',
          faIcon: faClipboardListCheck,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: this.onShowReviewInstallDialog,
        });
      }
    }
    if (selectedJobs.length > 0) {
      // menuItems.push({
      //   label: 'Add Note',
      //   faIcon: faNoteSticky,
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      //   command: this.onShowAddNoteDialog,
      // });
    }

    return menuItems;
  }

  stageTemplate(stage?: JobStage, fittingProviderId?: string) {
    if (stage) {
      const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';

      let stageClass: string;
      if (currentCompanyId === fittingProviderId || stage === 'Canceled' || stage === 'Completed') {
        stageClass = `job-stage-${stage!.toLowerCase().replaceAll(' ', '-').replaceAll(/[()]/g, '')}`;
      } else {
        stageClass = 'job-stage-grey';
      }

      return <span className={`stage-badge ${stageClass}`}>{stage}</span>;
    }
    return <></>;
  }

  stageFilterTemplate(stage?: JobStage) {
    if (stage) {
      const stageClass = `job-stage-${stage!.toLowerCase().replaceAll(' ', '-').replaceAll(/[()]/g, '')}`;
      return <span className={`stage-badge ${stageClass}`}>{stage}</span>;
    }
    return <></>;
  }

  titleColumnTemplate(job: Job) {
    const {selectedJobs, disableMenu, disableLink} = this.state;
    let content;
    if (disableLink) {
      content = job.title;
    } else {
      content = <NavLink to={'/job/' + job.id}>{job.title}</NavLink>;
    }
    if (disableMenu) {
      return content;
    }
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          rowItemIdentifier={job?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          initMenuItems={this.initMenuItems}
          selectedItems={selectedJobs}
          handleChangeSelectedItems={() => this.handleChangeSelectedItem(job)}
        >
          {content}
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  stageColumnTemplate(job: Job) {
    return this.stageTemplate(job.stage, job.fitting_provider_id);
  }

  fitterColumnTemplate(job: Job) {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    if (job.fitting_provider_id !== currentCompanyId) {
      const fittingProvider = job.fitting_provider;
      return fittingProvider?.trading_as
        ? `${fittingProvider.trading_as} (${fittingProvider.name})`
        : fittingProvider?.name;
    }
    return job.lead_fitter?.label;
  }

  appointmentReferencesColumnTemplate(types: AppointmentType[], appointments?: Appointment[]) {
    const visibleAppointments = appointments?.filter(appointment => types.includes(appointment.type)) ?? [];
    if (visibleAppointments.length) {
      return (
        <div>
          {visibleAppointments.map(appointment => {
            return (
              <JobAppointmentReference
                key={appointment.id}
                identifier={`${appointment.id}`}
                appointment={appointment}
              />
            );
          })}
        </div>
      );
    }
    return '';
  }

  measureColumnTemplate(job: Job) {
    return this.appointmentReferencesColumnTemplate(
      ['Check Measure', 'Consultation', 'Service Call'],
      job.appointments
    );
  }

  installColumnTemplate(job: Job) {
    return this.appointmentReferencesColumnTemplate(['Installation', 'Repair'], job.appointments);
  }

  ownerColumnTemplate(job: Job) {
    return <JobOwnerColumn job={job} />;
  }

  orderColumnTemplate(job: Job) {
    return <JobOrderColumn job={job} />;
  }

  ageColumnTemplate(job: Job) {
    const createdAt: DateTime = DateTime.fromJSDate(new Date(job.created_at));
    const createdDuration = DateTime.now().diff(createdAt, ['days', 'hours']);
    return `${createdDuration.toObject().days}d`;
  }

  titleFilter() {
    return <InputText name="title" className="form-filter" onChange={this.handleFilterChange} />;
  }

  suburbFilter() {
    return <InputText name="suburb" className="form-filter" onChange={this.handleFilterChange} />;
  }

  ownerFilter() {
    return <InputText name="owner" className="form-filter" onChange={this.handleFilterChange} />;
  }

  fitterFilter() {
    return <InputText name="fitter" className="form-filter" onChange={this.handleFilterChange} />;
  }

  orderFilter() {
    return <InputText name="order_id" className="form-filter" onChange={this.handleFilterChange} />;
  }

  stageFilter() {
    return (
      <MultiSelect
        selectedItemTemplate={this.stageFilterTemplate}
        itemTemplate={this.stageFilterTemplate}
        value={this.state.filters.stage}
        options={this.state.availableStages}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );
  }

  measureFilter() {
    return (
      <MultiSelect
        value={this.state.filters.measure_stage}
        options={appointmentStages}
        name="measure_stage"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );
  }

  installFilter() {
    return (
      <MultiSelect
        value={this.state.filters.install_stage}
        options={appointmentStages}
        name="install_stage"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );
  }

  requestedServicesFilter() {
    return (
      <MultiSelect
        value={this.state.filters.requested_services}
        options={requestedServices}
        name="requested_services"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );
  }

  async onPrintDcms() {
    const {selectedJobs, productDefinitionsMap} = this.state;
    if (!selectedJobs.length) {
      return;
    }

    this.setState({
      loadingPrintData: true,
    });

    const jobIds = selectedJobs.map(job => job.id!);
    const dcms = await this.loadDcms(jobIds);
    const dcmsMap = new Map<string, JobDocument>();
    const missingDefinitionFilters: DefinitionFilter[] = [];
    for (const dcm of dcms ?? []) {
      dcmsMap.set(dcm.job_id, dcm);
      const revisionId = (dcm.content as DcmContent).revision_id;
      if (
        !productDefinitionsMap.has(revisionId) &&
        !missingDefinitionFilters.some(defFilter => defFilter.revisionId === revisionId)
      ) {
        const job = selectedJobs.find(job => job.id === dcm.job_id)!;
        missingDefinitionFilters.push({revisionId: revisionId, companyId: job.owner_id!});
      }
    }
    const updatedProductDefinitionsMap = new Map<number, PaProductDefinition[]>(productDefinitionsMap);
    if (missingDefinitionFilters.length) {
      const missingProductDefinitionsMap = await this.loadProductDefinitionsMap(missingDefinitionFilters);
      if (missingProductDefinitionsMap) {
        for (const [revisionId, productDefinitions] of Array.from(missingProductDefinitionsMap)) {
          updatedProductDefinitionsMap.set(revisionId, productDefinitions);
        }
      }
    }
    this.setState(
      {
        productDefinitionsMap: updatedProductDefinitionsMap,
        loadingPrintData: false,
        printDcms: true,
        dcmsMap,
      },
      () => MessageService.sendMessage(messages.printDcms)
    );
  }

  onShowRequestJobDialog() {
    this.setState({showRequestJobDialog: true});
  }

  onHideRequestJobDialog() {
    this.setState({showRequestJobDialog: false});
  }

  onShowFitterAssignmentDialog() {
    this.setState({showFitterAssignmentJobDialog: true});
  }

  onHideFitterAssignmentDialog() {
    this.setState({showFitterAssignmentJobDialog: false});
  }

  onShowBookApplicationDialog() {
    this.setState({showBookAppointmentDialog: true});
  }

  onHideBookApplicationDialog() {
    this.setState({showBookAppointmentDialog: false});
  }

  onCancelAppointment() {
    this.setState({showBookAppointmentDialog: false});
  }

  onShowReviewMeasureDialog() {
    this.setState({showReviewMeasureDialog: true});
  }

  onHideReviewMeasureDialog() {
    this.setState({showReviewMeasureDialog: false});
  }

  onShowReviewInstallDialog() {
    this.setState({showReviewInstallDialog: true});
  }

  onHideReviewInstallDialog() {
    this.setState({showReviewInstallDialog: false});
  }

  onShowAddNoteDialog() {
    this.setState({showAddNoteDialog: true});
  }

  onHideAddNoteDialog() {
    this.setState({showAddNoteDialog: false});
  }

  render() {
    const {
      selectedJobs,
      pagination,
      sortBy,
      loadingJobs,
      jobs,
      totalJobs,
      activeFilters,
      hiddenColumns,
      showRequestJobDialog,
      showFitterAssignmentJobDialog,
      showBookAppointmentDialog,
      showReviewInstallDialog,
      showReviewMeasureDialog,
      showAddNoteDialog,
      disableSelect,
      hideSorting,
      hideFilters,
      printDcms,
      dcmsMap,
      productDefinitionsMap,
      loadingPrintData,
    } = this.state;

    return (
      <div id="job_list_page_container" className={'page-container'}>
        <TwoDataTable
          pageSizeIdentifier={'job_list_page_container'}
          sizeIdentifiers={[]}
          selectedItems={selectedJobs}
          selectionMode={!disableSelect ? 'multiple' : undefined}
          rows={pagination.pageSize}
          first={pagination.offset}
          sortField={sortBy?.field}
          sortOrder={sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          handleChangeSelectedItems={jobs => this.handleChangeSelectedItems(jobs as Job[])}
          loading={loadingJobs || loadingPrintData}
          value={jobs}
          totalRecords={totalJobs}
          activeFilters={activeFilters}
          isMenuDynamic={true}
        >
          <Column
            header="Job Code"
            field="title"
            body={this.titleColumnTemplate}
            filter={!hideFilters}
            filterElement={this.titleFilter}
            showFilterMenu={false}
            sortable={!hideSorting}
          />
          <Column
            header="Services"
            field="requested_services"
            filter={!hideFilters}
            filterElement={this.requestedServicesFilter}
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            field="stage"
            body={this.stageColumnTemplate}
            filter={!hideFilters}
            filterElement={this.stageFilter}
            showFilterMenu={false}
            hidden={hiddenColumns.has('stage')}
          />
          <Column
            header="Fitter"
            field="fitter"
            body={this.fitterColumnTemplate}
            filter={!hideFilters}
            filterElement={this.fitterFilter}
            showFilterMenu={false}
          />
          <Column
            header="Measure"
            field="measure"
            body={this.measureColumnTemplate}
            filter={!hideFilters}
            filterElement={this.measureFilter}
            showFilterMenu={false}
            hidden={hiddenColumns.has('measure')}
          />
          <Column
            header="Install"
            field="install"
            body={this.installColumnTemplate}
            filter={!hideFilters}
            filterElement={this.installFilter}
            showFilterMenu={false}
            hidden={hiddenColumns.has('install')}
          />
          {/*<Column*/}
          {/*  header="Suburb"*/}
          {/*  field="address.suburb"*/}
          {/*  filter={!hideFilters}*/}
          {/*  filterElement={this.suburbFilter}*/}
          {/*  showFilterMenu={false}*/}
          {/*  sortable*/}
          {/*  hidden={hiddenColumns.has('address.suburb')}*/}
          {/*/>*/}
          <Column
            header="Owner"
            field="owner"
            body={this.ownerColumnTemplate}
            filter={!hideFilters}
            filterElement={this.ownerFilter}
            showFilterMenu={false}
            hidden={hiddenColumns.has('owner')}
          />
          <Column
            header="Order"
            field="order_id"
            body={this.orderColumnTemplate}
            filter={!hideFilters}
            filterElement={this.orderFilter}
            showFilterMenu={false}
            sortable
            hidden={hiddenColumns.has('order_id')}
          />
          <Column header="Age" field="created_at" body={this.ageColumnTemplate} hidden={hiddenColumns.has('age')} />
        </TwoDataTable>
        <RequestJobDialog showDialog={showRequestJobDialog} onHide={this.onHideRequestJobDialog} toast={this.toast} />
        <FitterAssignmentDialog
          jobs={selectedJobs}
          showDialog={showFitterAssignmentJobDialog}
          onHide={this.onHideFitterAssignmentDialog}
          toast={this.toast}
        />
        <AddNoteDialog
          jobs={selectedJobs}
          showDialog={showAddNoteDialog}
          onHide={this.onHideAddNoteDialog}
          toast={this.toast}
        />
        {selectedJobs[0] && (
          <>
            <BookAppointmentDialog
              job={selectedJobs[0]}
              showDialog={showBookAppointmentDialog}
              onBook={this.onBookAppointment}
              onCancel={this.onCancelAppointment}
              toast={this.toast}
            />
            <ReviewMeasureDialog
              job={selectedJobs[0]}
              showDialog={showReviewMeasureDialog}
              onHide={this.onHideReviewMeasureDialog}
              toast={this.toast}
            />
            <ReviewInstallDialog
              job={selectedJobs[0]}
              showDialog={showReviewInstallDialog}
              onHide={this.onHideReviewInstallDialog}
            />
          </>
        )}

        <Toast ref={this.toast} />
        {printDcms && (
          <DcmPrinterComponent
            jobs={selectedJobs}
            dcmsMap={dcmsMap}
            productDefinitionsMap={productDefinitionsMap}
            triggerMessage={messages.printDcms}
            onPrintDialogOpen={() => this.setState({printDcms: false})}
          />
        )}
      </div>
    );
  }
}
