import React, {Component} from 'react';
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
  AutoCompleteProps,
} from 'primereact/autocomplete';
import {Address} from 'two-core';
import {AppContext, GoogleMapsService} from 'two-app-ui';
import {InputNumber, InputNumberChangeParams} from 'primereact/inputnumber';
import config from '../../../config/config';

interface Props {
  siteAddress?: Address;
  onChange: (siteAddress: Address) => void;
  disabled?: boolean;
}

interface Suggestions {
  siteAddress: google.maps.places.AutocompletePrediction[];
  state: google.maps.places.AutocompletePrediction[];
  suburb: google.maps.places.AutocompletePrediction[];
  street: google.maps.places.AutocompletePrediction[];
}

interface SearchValues {
  siteAddress: string;
  state: string;
}

interface State {
  searchValues: SearchValues;
  showAddressDetail: boolean;
  suggestions: Suggestions;
}

export class SiteAddressFields extends Component<Props, State> {
  static contextType = AppContext;
  googleMapsService: GoogleMapsService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      suggestions: {
        siteAddress: [],
        state: [],
        suburb: [],
        street: [],
      },
      searchValues: {
        siteAddress: '',
        state: '',
      },
      showAddressDetail: false,
    };
    this.searchSiteAddress = this.searchSiteAddress.bind(this);
    this.searchState = this.searchState.bind(this);
    this.searchSuburb = this.searchSuburb.bind(this);
    this.searchStreet = this.searchStreet.bind(this);
    this.searchPlaces = this.searchPlaces.bind(this);
    this.createAddress = this.createAddress.bind(this);
    this.onSiteAddressSelect = this.onSiteAddressSelect.bind(this);
    this.onStateSelect = this.onStateSelect.bind(this);
    this.onSiteAddressSearchValueChange = this.onSiteAddressSearchValueChange.bind(this);
    this.onSearchValueChange = this.onSearchValueChange.bind(this);
    this.onSiteAddressChange = this.onSiteAddressChange.bind(this);
    this.updateSiteAddressSearchValue = this.updateSiteAddressSearchValue.bind(this);
    this.onStreetChange = this.onStreetChange.bind(this);
    this.onStreetSelect = this.onStreetSelect.bind(this);
    this.onSuburbChange = this.onSuburbChange.bind(this);
    this.onSuburbSelect = this.onSuburbSelect.bind(this);
    this.onLatChange = this.onLatChange.bind(this);
    this.onLongChange = this.onLongChange.bind(this);
    this.onStateSearchValueChange = this.onStateSearchValueChange.bind(this);
    this.toggleAddressDetail = this.toggleAddressDetail.bind(this);
  }

  componentDidMount() {
    this.googleMapsService = this.context.googleMapsService;
    this.googleMapsService?.loadGoogleMapsScript();
  }

  searchSiteAddress(e: AutoCompleteCompleteMethodParams) {
    this.searchPlaces(e.query, ['address'], 'siteAddress');
  }

  searchState(e: AutoCompleteCompleteMethodParams) {
    this.searchPlaces(e.query, ['administrative_area_level_1'], 'state');
  }

  searchSuburb(e: AutoCompleteCompleteMethodParams) {
    this.searchPlaces(e.query, ['locality'], 'suburb');
  }

  searchStreet(e: AutoCompleteCompleteMethodParams) {
    this.searchPlaces(e.query, ['route'], 'street');
  }

  async searchPlaces(input: string, types: string[], attrName: keyof Suggestions) {
    const {suggestions} = this.state;
    const countryIsoAlpha2Code = config().countryIsoAlpha2Code ?? 'au';
    let language = 'en';
    if (countryIsoAlpha2Code === 'au') {
      language = 'en-AU';
    }
    const request: google.maps.places.AutocompletionRequest = {
      input: input,
      componentRestrictions: {country: countryIsoAlpha2Code},
      language: language,
      types: types,
    };

    const predictions = await this.googleMapsService?.getPlacePredictions(request);
    if (predictions) {
      this.setState({
        suggestions: {...suggestions, [attrName]: predictions ?? []},
      });
    }
  }

  createAddress(): Address {
    let country = '';
    if (config().countryIsoAlpha2Code === 'au') {
      country = 'Australia';
    }
    return {
      country: country,
      lat: 0,
      long: 0,
      phoneNumber: '',
      postCode: '',
      state: '',
      state_short: '',
      street: '',
      suburb: '',
    };
  }

  async onSiteAddressSelect(e: AutoCompleteProps) {
    const {onChange} = this.props;
    const selectedAddress: google.maps.places.AutocompletePrediction = e.value;
    const placeResult = await this.googleMapsService?.getPlaceDetails({
      placeId: selectedAddress.place_id,
      fields: ['address_components', 'geometry'],
    });
    if (placeResult) {
      const addressComponents: google.maps.GeocoderAddressComponent[] = placeResult?.address_components ?? [];
      const address: Address = this.createAddress();
      let streetNumber = undefined;
      let route = '';
      for (const addressComponent of addressComponents) {
        const types = addressComponent.types;
        if (types.includes('street_number')) {
          streetNumber = addressComponent.long_name;
        } else if (types.includes('route')) {
          route = addressComponent.long_name;
        } else if (types.includes('country')) {
          address.country = addressComponent.long_name;
        } else if (types.includes('postal_code')) {
          address.postCode = addressComponent.long_name;
        } else if (types.includes('administrative_area_level_1')) {
          address.state = addressComponent.long_name;
          address.state_short = addressComponent.short_name;
          this.setState({
            searchValues: {
              ...this.state.searchValues,
              state: addressComponent.long_name,
            },
          });
        } else if (types.includes('locality')) {
          address.suburb = addressComponent.long_name;
        }
      }
      address.street = streetNumber ? `${streetNumber} ${route}` : route;
      address.lat = placeResult?.geometry?.location?.lat() ?? 0;
      address.long = placeResult?.geometry?.location?.lat() ?? 0;

      onChange(address);
    }
  }

  async onStateSelect(e: AutoCompleteProps) {
    const selectedState: google.maps.places.AutocompletePrediction = e.value;
    const placeResult = await this.googleMapsService?.getPlaceDetails({
      placeId: selectedState.place_id,
      fields: ['address_components'],
    });
    if (placeResult) {
      const addressComponent = placeResult.address_components?.find(addressComponent =>
        addressComponent.types.includes('administrative_area_level_1')
      );
      if (addressComponent) {
        this.onSiteAddressChange('state', addressComponent.long_name);
        this.onSiteAddressChange('state_short', addressComponent.short_name);
        this.updateSiteAddressSearchValue({
          state: addressComponent.short_name,
        });
      }
    }
  }

  onSiteAddressSearchValueChange(e: AutoCompleteChangeParams) {
    this.onSearchValueChange(e.value, 'siteAddress');
  }

  onSearchValueChange(value: string, attrName: keyof SearchValues) {
    const {searchValues} = this.state;
    this.setState({
      searchValues: {...searchValues, [attrName]: value ?? []},
    });
  }

  onSiteAddressChange(name: keyof Address, value: string | number) {
    const {onChange} = this.props;
    const siteAddress = this.props.siteAddress ?? this.createAddress();
    onChange({...siteAddress, [name]: value});
  }

  updateSiteAddressSearchValue(updatedData: {street?: string; suburb?: string; state?: string}) {
    const {siteAddress} = this.props;
    let newValue = '';
    const street = updatedData.street ?? siteAddress?.street;
    if (street?.length) {
      newValue += `${street}, `;
    }
    const suburb = updatedData.suburb ?? siteAddress?.suburb;
    const state = updatedData.state ?? siteAddress?.state_short;
    if (suburb?.length) {
      newValue += `${suburb}${!state?.length ? ', ' : ' '}`;
    }
    if (state?.length) {
      newValue += `${state}, `;
    }
    if (siteAddress?.country?.length) {
      newValue += `${siteAddress.country}`;
    }
    this.onSearchValueChange(newValue, 'siteAddress');
  }

  onStreetChange(e: AutoCompleteChangeParams) {
    this.onSiteAddressChange('street', e.value);
    this.updateSiteAddressSearchValue({street: e.value});
  }

  async onStreetSelect(e: AutoCompleteProps) {
    const selectedState: google.maps.places.AutocompletePrediction = e.value;
    const placeResult = await this.googleMapsService?.getPlaceDetails({
      placeId: selectedState.place_id,
      fields: ['address_components'],
    });
    if (placeResult) {
      const addressComponent = placeResult.address_components?.find(addressComponent =>
        addressComponent.types.includes('route')
      );
      if (addressComponent) {
        this.onSiteAddressChange('street', addressComponent.long_name);
        this.updateSiteAddressSearchValue({
          street: addressComponent.long_name,
        });
      }
    }
  }

  onSuburbChange(e: AutoCompleteChangeParams) {
    this.onSiteAddressChange('suburb', e.value);
    this.updateSiteAddressSearchValue({suburb: e.value});
  }

  async onSuburbSelect(e: AutoCompleteProps) {
    const selectedState: google.maps.places.AutocompletePrediction = e.value;
    const placeResult = await this.googleMapsService?.getPlaceDetails({
      placeId: selectedState.place_id,
      fields: ['address_components'],
    });
    if (placeResult) {
      const addressComponent = placeResult.address_components?.find(addressComponent =>
        addressComponent.types.includes('locality')
      );
      if (addressComponent) {
        this.onSiteAddressChange('suburb', addressComponent.long_name);
        this.updateSiteAddressSearchValue({
          suburb: addressComponent.long_name,
        });
      }
    }
  }

  onLatChange(e: InputNumberChangeParams) {
    this.onSiteAddressChange('lat', e.value ?? 0);
  }

  onLongChange(e: InputNumberChangeParams) {
    this.onSiteAddressChange('long', e.value ?? 0);
  }

  onStateSearchValueChange(e: AutoCompleteChangeParams) {
    this.onSearchValueChange(e.value, 'state');
  }

  toggleAddressDetail() {
    this.setState({showAddressDetail: !this.state.showAddressDetail});
  }

  render() {
    const {searchValues, showAddressDetail, suggestions} = this.state;
    const {siteAddress} = this.props;
    return (
      <>
        {' '}
        <div className="p-field p-grid">
          <label className="p-col-1">site address</label>
          <div className="p-col-10">
            <AutoComplete
              value={searchValues.siteAddress}
              suggestions={suggestions.siteAddress}
              completeMethod={this.searchSiteAddress}
              field="description"
              onSelect={this.onSiteAddressSelect}
              onChange={this.onSiteAddressSearchValueChange}
              disabled={this.props.disabled ?? false}
            />
          </div>
          <div className="p-col-1 p-as-center">
            <button className="p-button p-button-text w-100" onClick={this.toggleAddressDetail}>
              detail
            </button>
          </div>
        </div>
        {showAddressDetail && (
          <>
            <div className="p-field p-grid">
              <label className="p-col-1">street</label>
              <div className="p-col-5">
                <AutoComplete
                  value={siteAddress?.street ?? ''}
                  suggestions={suggestions.street}
                  completeMethod={this.searchStreet}
                  field={'structured_formatting.main_text'}
                  onSelect={this.onStreetSelect}
                  onChange={this.onStreetChange}
                  disabled={this.props.disabled ?? false}
                />
              </div>
              <label className="p-col-1">suburb</label>
              <div className="p-col-5">
                <AutoComplete
                  value={siteAddress?.suburb ?? ''}
                  suggestions={suggestions.suburb}
                  completeMethod={this.searchSuburb}
                  field={'structured_formatting.main_text'}
                  onSelect={this.onSuburbSelect}
                  onChange={this.onSuburbChange}
                  disabled={this.props.disabled ?? false}
                />
              </div>
            </div>
            <div className="p-field p-grid">
              <label className="p-col-1">latitude</label>
              <div className="p-col-2">
                <InputNumber
                  value={siteAddress?.lat ?? 0}
                  onChange={this.onLatChange}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={8}
                  disabled={this.props.disabled ?? false}
                />
              </div>
              <label className="p-col-1">longitude</label>
              <div className="p-col-2">
                <InputNumber
                  value={siteAddress?.lat ?? 0}
                  onChange={this.onLongChange}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={8}
                  disabled={this.props.disabled ?? false}
                />
              </div>
              <label className="p-col-1">state</label>
              <div className="p-col-5">
                <AutoComplete
                  value={searchValues.state}
                  suggestions={suggestions.state}
                  completeMethod={this.searchState}
                  field={'structured_formatting.main_text'}
                  onSelect={this.onStateSelect}
                  onChange={this.onStateSearchValueChange}
                  disabled={this.props.disabled ?? false}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
