import React from 'react';
import {Accordion, AccordionTab} from 'primereact/accordion';
import ContainersListComponent from '../Containers/ContainersListComponent';
import DraftListComponent from '../Drafts/DraftListComponent';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import './OrdersOverviewComponent.scss';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import OrderListComponent from '../Orders/OrderListComponent';

class OrdersOverviewComponent extends React.Component<{}> {
  constructor(props: {}) {
    super(props);
  }

  onTabOpen() {
    // TwoDataTable needs to recalculate its height.
    setTimeout(() => {
      MessageService.sendMessage(messages.heightChanged);
    }, 50);
  }

  render() {
    const showDrafts = (localStorage.getItem(localStorageAttributes.doesQuotes) ?? 'false') === 'true';
    return (
      <div id="orders_overview" className="page-container">
        <Accordion
          className="overview-accordion"
          onTabOpen={this.onTabOpen}
          transitionOptions={{timeout: {enter: 0, exit: 0}}}
        >
          {showDrafts && (
            <AccordionTab header="Drafts">
              <DraftListComponent />
            </AccordionTab>
          )}
          <AccordionTab header="Estimate">
            <OrderListComponent mode={'Estimate'} />
          </AccordionTab>
          <AccordionTab header="Drawings">
            <OrderListComponent mode={'Drawings'} />
          </AccordionTab>
          <AccordionTab header="In Production">
            <OrderListComponent mode={'In Production'} />
          </AccordionTab>
          <AccordionTab header="In Shipping">
            <ContainersListComponent />
          </AccordionTab>
        </Accordion>
      </div>
    );
  }
}
export default OrdersOverviewComponent;
