import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  TwoAction,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  ToastService,
  TwoEntityComponent,
  MessageService,
  OrderItemsComponent,
  DraftsPrinterComponent,
} from 'two-app-ui';
import {Draft, DraftPatch, Field, OrderItem, PaProductDefinition, QueryParameter, TimeLineEvent} from 'two-core';
import TleService from '../../services/TleService';

import {
  faCalendarAlt,
  faPencil,
  faBarcodeRead,
  faTrashUndo,
  faTrash,
  faPrint,
} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import ProductsService from '../../services/ProductsService';
import DraftsService from '../../services/DraftsService';
import {NewEditDraftDialog} from './NewEditDraftDialog/NewEditDraftDialog';
import {DraftDetail} from './DraftDetail';
import {localStorageAttributes} from '../../config/localStorageAttributes';

interface RouteProps {
  id: string;
}

interface State {
  loading: boolean;
  draft?: Draft;
  timeLineItems: TwoTimelineItem[];
  showEditDialog: boolean;
  productDefinitions: PaProductDefinition[];
  printDraft: boolean;
}

class DraftComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  draftsService?: DraftsService;
  tleService?: TleService;
  toastService?: ToastService;
  productsService?: ProductsService;

  toast: React.RefObject<Toast>;
  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loading: false,
      timeLineItems: [],
      showEditDialog: false,
      productDefinitions: [],
      printDraft: false,
    };
    this.toast = React.createRef();

    this.loadDraft = this.loadDraft.bind(this);
    this.loadEvents = this.loadEvents.bind(this);
    this.loadProductDefinitions = this.loadProductDefinitions.bind(this);
    this.getActions = this.getActions.bind(this);
    this.onHideEditDialog = this.onHideEditDialog.bind(this);
    this.deleteDraft = this.deleteDraft.bind(this);
    this.unDeleteDraft = this.unDeleteDraft.bind(this);
  }

  componentDidMount() {
    this.draftsService = this.context.draftsService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;
    this.productsService = this.context.productsService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.orderCreated || messages.draftUpdated) {
        this.loadDraft();
      }
    });
    this.loadDraft();
  }

  loadDraft() {
    const id = this.props.match.params.id;
    this.setState({loading: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );
    filters.push(
      JSON.stringify({
        condition: 'includeDeleted',
        value: true,
      })
    );

    const params: QueryParameter = {
      filters: filters,
    };
    this.draftsService
      ?.getDrafts(params)
      .then(async data => {
        const draft = (data.records as Draft[])[0];
        const orderItems: OrderItem[] = draft.items.map(item => {
          const fields: Field[] = item.fields.map(field => {
            //we must convert field to class object
            const values = field.values.map(fieldValue => {
              if (fieldValue.sub_fields) {
                fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
                  //we must convert subfield to class object
                  return new Field(subField);
                });
              }
              return fieldValue;
            });
            return new Field({...field, values: values});
          });
          item.fields = fields;
          return new OrderItem(item);
        });
        draft.items = orderItems;
        this.setState({
          draft: draft,
        });
        await this.loadProductDefinitions(draft.revision_id!);
        await this.loadEvents(draft.id);
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, draft load failed, please try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  loadEvents(id: string) {
    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'draft',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    return this.tleService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const timeLineItems = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          timeLineItems: timeLineItems,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
      });
  }

  async loadProductDefinitions(revisionId: number) {
    return this.productsService
      ?.getProductsDefinitions(revisionId)
      .then(data => {
        const productDefinitions = (data.records as PaProductDefinition[]) ?? [];
        this.setState({
          productDefinitions: productDefinitions,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, product definition load failed, please try again.');
      });
  }

  getActions(): TwoAction[] {
    const actions: TwoAction[] = [];
    if (!this.state.draft?.deleted) {
      actions.push({
        icon: faPencil,
        label: 'Edit',
        main: true,
        action: () => {
          this.setState({showEditDialog: true});
        },
      });
      actions.push({
        icon: faTrash,
        label: 'Delete',
        main: false,
        action: () => {
          this.deleteDraft();
        },
      });
    } else {
      actions.push({
        icon: faTrashUndo,
        label: 'Undelete',
        main: true,
        action: () => {
          this.unDeleteDraft();
        },
      });
    }
    actions.push({
      icon: faPrint,
      label: 'Print',
      main: false,
      action: () => {
        this.setState(
          {
            printDraft: true,
          },
          () => MessageService.sendMessage(messages.printDraft)
        );
      },
    });

    return actions;
  }

  async deleteDraft() {
    this.setState({loading: true});
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!companyId) {
      this.setState({loading: false});
      this.toastService?.showError(this.toast, 'Sorry, something went wrong. Please refresh and try again.');
      return;
    }

    if (this.state.draft) {
      this.draftsService
        ?.deleteDraft(companyId, this.state.draft.id)
        .then(() => {
          this.toastService?.showSuccess(this.toast, 'Draft successfully deleted.');
          this.loadDraft();
        })
        .catch(error => {
          console.error(error);
          this.toastService?.showError(this.toast, 'Oh no, draft failed to delete.');
          this.setState({loading: false});
        });
    } else {
      console.error('No state.draft present.');
      this.toastService?.showError(this.toast, 'Oh no, draft failed to delete. Please, refresh and try again.');
      this.setState({loading: false});
    }
  }

  async unDeleteDraft() {
    this.setState({loading: true});
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!companyId || !this.state.draft) {
      this.setState({loading: false});
      this.toastService?.showError(this.toast, 'Sorry, something went wrong. Please, refresh and try again.');
      return;
    }
    const draftPatch: DraftPatch = {
      deleted: false,
    };
    this.draftsService
      ?.updateDraft(companyId, this.state.draft.id, draftPatch)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Draft successfully un-deleted.');
        this.loadDraft();
      })
      .catch(error => {
        console.error(error);
        this.toastService?.showError(this.toast, 'Oh no, draft failed to un-delete.');
        this.setState({loading: false});
      });
  }

  onHideEditDialog() {
    this.setState({showEditDialog: false});
  }

  render() {
    const {draft, timeLineItems, productDefinitions, loading, printDraft} = this.state;
    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }
    if (!draft) {
      return <></>;
    }

    const actions = this.getActions();
    const primaryPanelBody = (
      <div>
        <DraftDetail draft={draft} />
      </div>
    );
    const contentPanelBody = (
      <OrderItemsComponent
        mode={'readonly'}
        items={draft.items ?? []}
        productDefinitions={this.state.productDefinitions}
        onItemsChanged={() => {}}
      />
    );
    const timelinePanelBody = <TwoTimeline key={draft.id} items={timeLineItems ?? []} />;

    const title = draft.id + (draft.deleted ? '  [DELETED]' : '');
    const prodDefs: Map<number, PaProductDefinition[]> = new Map<number, PaProductDefinition[]>();
    prodDefs.set(draft.revision_id ?? -1, productDefinitions);

    return (
      <>
        <TwoEntityComponent title={title} actions={actions}>
          <TwoEntityPanel isPrimary={true}>{primaryPanelBody}</TwoEntityPanel>
          <TwoEntityPanel label="Content" icon={faBarcodeRead} tooltip="Content">
            {contentPanelBody}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {timelinePanelBody}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <NewEditDraftDialog
          toast={this.toast}
          showDialog={this.state.showEditDialog}
          onHide={this.onHideEditDialog}
          draft={draft}
          productDefinitions={productDefinitions}
        />
        <Toast ref={this.toast} />
        {printDraft && (
          <DraftsPrinterComponent
            drafts={[draft]}
            productDefinitionRevisions={prodDefs}
            triggerMessage={messages.printDraft}
            onPrintDialogOpen={() => {
              this.setState({printDraft: false});
            }}
          />
        )}
      </>
    );
  }
}

export default withRouter(DraftComponent);
