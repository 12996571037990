import React from 'react';
import {Tooltip} from 'primereact/tooltip';
import {Job, Order, QueryParameter} from 'two-core';
import {AppContext} from 'two-app-ui';
import OrdersService from '../../services/OrdersService';
import {ProgressSpinner} from 'primereact/progressspinner';

interface Props {
  job: Job;
}

interface State {
  loadingOrder: boolean;
  order?: Order;
}

export default class JobOrderColumn extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService?: OrdersService;

  constructor(props: Props) {
    super(props);
    this.state = {
      loadingOrder: false,
    };
  }

  async componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.loadOrder = this.loadOrder.bind(this);
  }

  loadOrder() {
    const {job} = this.props;
    const {order} = this.state;
    if (job.order_id && !order) {
      this.setState({loadingOrder: true});
      const filters: string[] = [];
      filters.push(
        JSON.stringify({
          field: 'id',
          value: job.order_id,
        })
      );

      const params: QueryParameter = {
        filters: filters,
        aggregate: true,
      };

      this.ordersService
        ?.getOrders(params)
        .then(data => {
          const orders = (data?.records as Order[]) ?? [];

          this.setState({order: orders[0]});
        })
        .finally(() => this.setState({loadingOrder: false}));
    }
  }

  renderTooltipContent(loadingOrder: boolean, order?: Order) {
    const labelClasses = 'p-col-4';
    const valueClasses = 'p-col-8';

    if (loadingOrder) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!order) {
      return <></>;
    }

    return (
      <>
        <div className={'p-d-flex p-flex-column'}>
          <div className="p-grid">
            <label className={labelClasses}>Code</label>
            <div className={valueClasses}>
              <span>{order?.id}</span>
            </div>
          </div>
        </div>
        <div className={'p-d-flex p-flex-column'}>
          <div className="p-grid">
            <label className={labelClasses}>Reference</label>
            <div className={valueClasses}>
              <span>{order?.reference}</span>
            </div>
          </div>
        </div>
        <div className={'p-d-flex p-flex-column'}>
          <div className="p-grid">
            <label className={labelClasses}>Owner Contact</label>
            <div className={valueClasses}>
              <span>{order?.owner_contact_ref.label}</span>
            </div>
          </div>
        </div>
        <div className={'p-d-flex p-flex-column'}>
          <div className="p-grid">
            <label className={labelClasses}>Stage</label>
            <div className={valueClasses}>
              <span>{order?.stage}</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {job} = this.props;
    const {order, loadingOrder} = this.state;
    return (
      <div id={`job_order_column_${job.id}`}>
        <Tooltip
          target={`#job_order_column_${job.id}`}
          position="left"
          style={{width: '500px'}}
          onShow={() => this.loadOrder()}
        >
          {this.renderTooltipContent(loadingOrder, order)}
        </Tooltip>
        {job?.order_id}
      </div>
    );
  }
}
