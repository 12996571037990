import {
  AppointmentStage,
  AppointmentType,
  FittingType,
  JobStage,
  OrderStage,
  PaContactRole,
  RequestedServices,
} from 'two-core';

export const companyStages: string[] = [
  'Prospective',
  'Pending Application',
  'Awaiting Approval',
  'Active',
  'Inactive',
];
export const companyStates: string[] = ['ACT', 'NSW', 'NT', 'NZ', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
export const orderFittingOptions: FittingType[] = ['none', 'internal', 'made2fit'];
export const businessEntityTypes: string[] = ['Company Pty Ltd', 'Partnership', 'Sole Trader', 'Trust Company'];
export const orderStages: OrderStage[] = [
  'Estimate',
  'Measure',
  'Repair Estimate',
  'New',
  'Drawing(s) Required',
  'Drawing Notes Awaiting Approval',
  'Drawing(s) Awaiting Approval',
  'Re-Draw',
  'Drawing(s) Approved',
  'Credit Check',
  'Scheduled For Production',
  'In Production',
  'Production Complete',
  'In Shipping',
  'Delivered',
  'Cancelled',
];

//todo add 'Delivered' into jobStages once available
export const jobStages: JobStage[] = [
  'New',
  'Measure Planned',
  'Measure Booked',
  'Measure Started',
  'Measure Finished',
  'Measure Review',
  'Measure Approval Pending',
  'In Production',
  'In Shipping',
  'Install Planned',
  'Install Booked',
  'Install Started',
  'Install Finished',
  'Install Review',
  'Completed',
  'Completed With Repair',
  'Canceled',
];

export const requestedServices: RequestedServices[] = [
  'Consult & Install',
  'Check Measure & Install',
  'Service Call',
  'Repair',
];

export const appointmentStages: AppointmentStage[] = [
  'Planned',
  'Invited',
  'Booked',
  'Started',
  'Finished',
  'Under Review',
  'Completed',
  'Cancelled',
];

export const values = {
  stopTypingDetection: 1000,
};
export const contactRoles: PaContactRole[] = ['admin', 'sales representative', 'fitter'];

export const appointmentTypes: AppointmentType[] = ['Check Measure', 'Consultation', 'Installation', 'Service Call'];
