import React, {Component} from 'react';
import {
  CheckMeasureOriginalContent,
  Company,
  ConsultOriginalContent,
  ConsultProduct,
  DcmContent,
  Draft,
  DropdownOption,
  FittingType,
  Job,
  JobContact,
  JobDocument,
  JobDocumentContent,
  OriginalContent,
  PaContact,
  PaOrder,
  PaProductDefinition,
  QueryParameter,
  TimeLineEvent,
  TleContentCreate,
  TleContentJobDocEvent,
  UserReference,
} from 'two-core';
import {Toast} from 'primereact/toast';
import {AppContext, AuthenticatedUser, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import OrdersService from '../../../services/OrdersService';
import ProductsService from '../../../services/ProductsService';
import NewJobDetailsComponent, {JobDetailInterface} from './NewJobDetailsComponent';
import JobsService from '../../../services/JobsService';
import JobDocumentsService from '../../../services/JobDocumentsService';
import {localStorageAttributes} from '../../../config/localStorageAttributes';
import DraftsService from '../../../services/DraftsService';
import ContactsService from '../../../services/ContactsService';
import {messages} from '../../../config/messages';
import {Dropdown} from 'primereact/dropdown';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  estimate?: PaOrder;
  draft?: Draft;
}

interface State {
  requestOnBehalf: boolean;
  contacts?: PaContact[];
  requestForExisting: boolean;
  newJobDetail: JobDetailInterface;
  loading: boolean;
  saving: boolean;
  estimates: PaOrder[];
  drafts: Draft[];
  productDefinitions: PaProductDefinition[];
  doesQuotes: boolean;
  selectedFittingType?: FittingType;
  selectedFittingProviderId?: string;
  companyFittingTypes: FittingType[];
  fittingProviderOptions: DropdownOption[];
  fittingProviderCompanies: Company[];
  fitterContacts: PaContact[];
}

export class RequestJobDialog extends Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  draftsService: DraftsService | null = null;
  toastService: ToastService | null = null;
  productsService: ProductsService | null = null;
  jobsService: JobsService | null = null;
  jobDocumentsService: JobDocumentsService | null = null;
  contactsService: ContactsService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      requestOnBehalf: true,
      requestForExisting: false,
      newJobDetail: {},
      loading: false,
      saving: false,
      estimates: [],
      drafts: [],
      productDefinitions: [],
      doesQuotes: false,
      selectedFittingType: undefined,
      selectedFittingProviderId: undefined,
      companyFittingTypes: [],
      fittingProviderOptions: [],
      fittingProviderCompanies: [],
      fitterContacts: [],
    };

    this.loadProductDefinitions = this.loadProductDefinitions.bind(this);
    this.loadDrafts = this.loadDrafts.bind(this);
    this.loadEstimates = this.loadEstimates.bind(this);
    this.onShow = this.onShow.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);
    this.validate = this.validate.bind(this);
    this.requestJob = this.requestJob.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onProductsChange = this.onProductsChange.bind(this);
    this.onInputFittingChange = this.onInputFittingChange.bind(this);
    this.onFittingProviderChange = this.onFittingProviderChange.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.draftsService = this.context.draftsService;
    this.toastService = this.context.toastService;
    this.productsService = this.context.productsService;
    this.jobsService = this.context.jobsService;
    this.jobDocumentsService = this.context.jobDocumentsService;
    this.contactsService = this.context.contactsService;
  }

  async loadProductDefinitions() {
    const revisionId = this.props.estimate?.revision_id ?? this.props.draft?.revision_id;

    return this.productsService?.getProductsDefinitions(revisionId).then(data => {
      const productDefinitions = (data.records as PaProductDefinition[]) ?? [];
      this.setState({
        productDefinitions: productDefinitions.sort((a, b) => {
          if (a.product_name < b.product_name) {
            return -1;
          }
          if (a.product_name > b.product_name) {
            return 1;
          }
          return 0;
        }),
        loading: false,
      });
    });
  }

  setEstimate(estimate: PaOrder) {
    const fittingType2Set = this.state.companyFittingTypes.find(ft => ft === estimate.fitting_options?.type);
    this.setState(
      {
        newJobDetail: {
          ...this.state.newJobDetail,
          selectedEstimate: estimate,
          requestedServices: 'Check Measure & Install',
          detailDepth: 'estimate',
        },
        estimates: [estimate],
      },
      () => {
        if (fittingType2Set && fittingType2Set !== 'made2fit') {
          this.setFitterOptions(fittingType2Set).then(() => {
            this.setState({
              selectedFittingProviderId: estimate.fitting_options?.reference.id,
            });
          });
        }
      }
    );
  }

  async loadEstimates() {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'stage',
        value: 'Estimate',
      }),
      JSON.stringify({
        field: 'fitting_options',
        condition: 'isNotNull',
      })
    );
    const orderBys = [JSON.stringify({field: 'updated_at', direction: 'DESC'})];
    const params: QueryParameter = {
      offset: 0,
      page_size: 50,
      filters: filters,
      aggregate: false,
      orderBys: orderBys,
    };

    return this.ordersService?.getOrders(params).then(data => {
      const estimates = (data.records as PaOrder[]) ?? [];
      this.setState({estimates: estimates});
    });
  }

  async loadContacts(): Promise<void> {
    return this.contactsService?.getContacts({}).then(response => {
      if (response.records) {
        const contacts = response.records as PaContact[];
        const fitters = contacts.filter(contact => contact.role === 'fitter');
        this.setState({contacts: contacts, fitterContacts: fitters}, () => {
          if (this.state.companyFittingTypes.length === 1) {
            this.setFitterOptions(this.state.companyFittingTypes[0]);
          }
        });
      }
    });
  }

  async loadDrafts() {
    const draft = this.props.draft;
    if (draft) {
      this.setState({
        newJobDetail: {
          ...this.state.newJobDetail,
          selectedDraft: draft,
          requestedServices: 'Consult & Install',
          detailDepth: 'draft',
        },
        drafts: [draft],
      });
    } else {
      const orderBys = [JSON.stringify({field: 'updated_at', direction: 'DESC'})];
      const params: QueryParameter = {
        offset: 0,
        page_size: 50,
        orderBys: orderBys,
      };
      return this.draftsService?.getDrafts(params).then(data => {
        const drafts = (data.records as Draft[]) ?? [];
        this.setState({drafts: drafts});
      });
    }
  }

  onShow() {
    this.loadData();
  }

  async loadData() {
    const {estimate, draft} = this.props;
    const currentCompany: Company = JSON.parse(localStorage.getItem(localStorageAttributes.currentCompany) ?? '{}');
    const contactString = localStorage.getItem(localStorageAttributes.myContact);
    const myContact: PaContact | undefined = contactString ? JSON.parse(contactString) : undefined;
    const myCurrentRole = localStorage.getItem(localStorageAttributes.currentRole);
    const requestForExisting = draft !== undefined || estimate !== undefined;
    const requestOnBehalf = myContact === undefined || myCurrentRole === 'admin';
    const companyFittingTypesText = currentCompany.fitting_types;
    const companyFittingOptions = companyFittingTypesText?.split(',') as FittingType[];
    this.setState(
      {
        loading: true,
        requestForExisting: requestForExisting,
        requestOnBehalf: requestOnBehalf,
        doesQuotes: currentCompany.does_quotes ?? false,
        newJobDetail: {
          ownerCompanyId: currentCompany.id,
          ownerCompanyName: currentCompany.name,
          dealerContact: myContact,
        },
        companyFittingTypes: companyFittingOptions,
        fittingProviderCompanies: currentCompany.fitting_providers ?? [],
      },
      () => {
        const promises = [];
        if (!requestForExisting) {
          promises.push(this.loadProductDefinitions());
          promises.push(this.loadEstimates());
          if (currentCompany.does_quotes) {
            promises.push(this.loadDrafts());
          }
        }
        if (requestOnBehalf || companyFittingOptions.includes('internal')) {
          promises.push(this.loadContacts());
        }
        Promise.all(promises)
          .then(() => {
            if (estimate) {
              this.setEstimate(estimate);
            }
          })
          .catch(error => {
            console.error('Failed Loading Data:' + error);
            this.toastService?.showError(this.props.toast, 'Failed loading data, please refresh and try again.');
          })
          .finally(() => {
            this.setState({loading: false});
          });
      }
    );
  }

  onHide() {
    this.setState({
      loading: false,
      newJobDetail: {},
      estimates: [],
      drafts: [],
      productDefinitions: [],
      requestForExisting: false,
      contacts: [],
      requestOnBehalf: false,
      doesQuotes: false,
      saving: false,
    });
    this.props.onHide();
  }

  onSave() {
    if (this.validate()) {
      this.requestJob();
    }
  }

  validate() {
    const {newJobDetail, selectedFittingType, fittingProviderCompanies, selectedFittingProviderId} = this.state;
    const errors: string[] = [];

    if (!newJobDetail.dealerContact) {
      errors.push('Dealer Contact not selected.');
    }
    if (!newJobDetail.clientFirstName) {
      errors.push('Client first name field is empty.');
    }
    if (!newJobDetail.clientLastName) {
      errors.push('Client last name field is empty.');
    }
    if (!newJobDetail.clientPhone) {
      errors.push('Client phone field is empty.');
    }
    if (!newJobDetail.clientEmail) {
      errors.push('Client email field is empty.');
    }
    const siteAddress = newJobDetail.siteAddress;
    if (
      !siteAddress ||
      !siteAddress.state_short?.length ||
      !siteAddress.street?.length ||
      !siteAddress.suburb?.length
    ) {
      errors.push('Site address is incorrect.');
    }
    if (!newJobDetail.requestedServices) {
      errors.push('Job type field is empty.');
    }
    if (newJobDetail.requestedServices === 'Consult & Install') {
      if (!newJobDetail.detailDepth) {
        errors.push('Based on option not chosen.');
      } else {
        if (newJobDetail.detailDepth === 'products') {
          if (!newJobDetail.products || newJobDetail.products.length === 0) {
            errors.push('At least one product has to be selected.');
          } else {
            let prodWithZero = false;
            let productCountSum = 0;
            if (newJobDetail.products) {
              for (const prod of newJobDetail.products) {
                if (prod.quantity === 0) {
                  prodWithZero = true;
                }
                productCountSum += prod.quantity;
              }
            }
            if (prodWithZero || productCountSum === 0) {
              errors.push('QTY for every selected product must be more than one.');
            }
          }
        } else if (newJobDetail.detailDepth === 'draft' && !newJobDetail.selectedDraft) {
          errors.push('Draft not selected.');
        } else if (newJobDetail.detailDepth === 'estimate' && !newJobDetail.selectedEstimate) {
          errors.push('Estimate not selected.');
        }
      }
    }
    if (newJobDetail.requestedServices === 'Check Measure & Install' && !newJobDetail.selectedEstimate?.id) {
      errors.push('Order field is empty.');
    }
    if (selectedFittingType === '3rd party') {
      const selectedCompany = fittingProviderCompanies.find(company => selectedFittingProviderId === company.id);
      if (!selectedCompany) {
        errors.push('Fitting provider not selected.');
      }
    }

    if (errors.length) {
      const errorText = (
        <div>
          Form is invalid:
          {errors.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </div>
      );
      this.toastService?.showError(this.props.toast, errorText);
      return false;
    }
    return true;
  }

  requestJob() {
    const {newJobDetail, selectedFittingType, fitterContacts, selectedFittingProviderId} = this.state;
    const {estimate} = this.props;

    //owner company
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';

    //my contact - created by
    const createBy: UserReference = {};
    const myContactString = localStorage.getItem(localStorageAttributes.myContact);
    if (myContactString) {
      const myContact: PaContact = JSON.parse(myContactString);
      createBy.contact_id = myContact.id;
      createBy.user_id = myContact.user_id;
      createBy.label = `${myContact.first_name} ${myContact.last_name}`;
    } else {
      const currentUser: AuthenticatedUser = JSON.parse(localStorage.getItem('user') ?? '');
      createBy.user_id = currentUser.uuid!;
      createBy.label = currentUser.username;
    }

    //dealer contact - requested by
    const requestedBy: UserReference = {
      user_id: newJobDetail.dealerContact?.user_id,
      contact_id: newJobDetail.dealerContact?.id,
      label: `${newJobDetail.dealerContact?.first_name} ${newJobDetail.dealerContact?.last_name}`,
    };

    //title
    const suffix = newJobDetail.selectedDraft
      ? newJobDetail.selectedDraft.id
      : newJobDetail.selectedEstimate
        ? newJobDetail.selectedEstimate.id
        : Math.floor(1000 + Math.random() * 9000);
    let root = `${newJobDetail.clientFirstName} ${newJobDetail.clientLastName}`;
    if (estimate?.fitting_options?.type !== 'made2fit') {
      if (newJobDetail.selectedDraft && newJobDetail.selectedDraft.reference) {
        root = newJobDetail.selectedDraft.reference;
      } else if (newJobDetail.selectedEstimate && newJobDetail.selectedEstimate.reference) {
        root = newJobDetail.selectedEstimate.reference;
      }
    }
    const title = `${root}${suffix ? ' ' + suffix : ''}`;

    //fitting provider:
    let fittingProviderId = 'internal';
    let leadFitterRef: UserReference | undefined;
    if (selectedFittingType === 'internal') {
      fittingProviderId = 'internal';
      const selectedContact = fitterContacts.find(contact => selectedFittingProviderId === contact.id)!;
      leadFitterRef = {
        user_id: selectedContact.user_id,
        contact_id: selectedContact.id,
        label: `${selectedContact.first_name} ${selectedContact.last_name}`,
      };
    } else if (selectedFittingType === '3rd party') {
      fittingProviderId = selectedFittingProviderId!;
    } else if (selectedFittingType === 'made2fit') {
      fittingProviderId = 'made2fit';
    }

    const endCustomer: JobContact = {
      email: newJobDetail.clientEmail!,
      first_name: newJobDetail.clientFirstName!,
      last_name: newJobDetail.clientLastName!,
      phone: newJobDetail.clientPhone!,
    };

    const newJob: Job = {
      id: '',
      title: title,
      stage: 'New',
      order_id: newJobDetail.selectedEstimate?.id,
      state_id: newJobDetail.siteAddress!.state_short ?? '',
      requested_services: newJobDetail.requestedServices!,
      address: newJobDetail.siteAddress!,
      owner_id: currentCompanyId,
      fitting_provider_id: fittingProviderId,
      lead_fitter: leadFitterRef,
      requested_by: requestedBy,
      end_customer: endCustomer,
      created_at: new Date(),
      created_by: createBy,
      last_updated_at: new Date(),
      tles_success: [
        {
          event_type: 'create',
          entity_type: 'job',
          entity_id: '<REPLACE_WITH_ENTITY_ID>',
          recorded_at: new Date(),
          recorded_by: createBy.user_id ?? '',
          content: {
            message: 'Job requested.',
          } as TleContentCreate,
        },
      ],
    };

    if (estimate?.fitting_options?.type === 'internal') {
      newJob.lead_fitter = {};
      newJob.lead_fitter.contact_id = estimate.fitting_options.reference.id;
      newJob.lead_fitter.label = estimate.fitting_options.reference.label;
    }

    this.setState({saving: true});
    this.jobsService
      ?.createJob(currentCompanyId, newJob)
      .then(async job => {
        //documents
        const originalDocContent = this.createOriginalDoc(newJobDetail);
        const originalDoc: JobDocument = {
          id: 'after_insert',
          type: 'Original',
          content: originalDocContent,
          created_at: new Date(),
          created_by: createBy,
          stage: 'Completed',
          job_id: job.id!,
          last_updated_at: new Date(),
          tles_success: [
            {
              event_type: 'job_doc_event',
              entity_type: 'job',
              entity_id: job.id,
              recorded_by: createBy.user_id,
              recorded_at: new Date(),
              content: {
                doc_type: 'Original',
                doc_event_type: 'create',
              } as TleContentJobDocEvent,
            } as TimeLineEvent,
          ],
        };
        await this.jobDocumentsService
          ?.createJobDocument(originalDoc)
          .then(() => console.log('Original Created.'))
          .catch(error => console.error('Failed to create Original Document: ' + error));

        const dcmDocContent = this.createDcmDoc(newJobDetail);
        const dcmDoc: JobDocument = {
          id: 'after_insert',
          type: 'DCM',
          content: dcmDocContent,
          created_at: new Date(),
          created_by: createBy,
          stage: 'New',
          job_id: job.id!,
          last_updated_at: new Date(),
          tles_success: [
            {
              event_type: 'job_doc_event',
              entity_type: 'job',
              entity_id: job.id,
              recorded_by: createBy.user_id,
              recorded_at: new Date(),
              content: {
                doc_type: 'DCM',
                doc_event_type: 'create',
              } as TleContentJobDocEvent,
            } as TimeLineEvent,
          ],
        };
        await this.jobDocumentsService
          ?.createJobDocument(dcmDoc)
          .then(() => console.log('DCM Created.'))
          .catch(error => console.error('Failed to create DCM: ' + error));

        this.toastService?.showSuccess(this.props.toast, 'Job created successfully.');
        MessageService.sendMessage(messages.jobCreated);
        this.onHide();
        MessageService.sendMessage(messages.orderUpdated);
      })
      .catch(error => {
        this.toastService?.showError(
          this.props.toast,
          'Sorry, requesting your job did not finish to satisfaction. Please, try again.'
        );
        console.error('error: ' + error);
      })
      .finally(() => {
        this.setState({saving: false});
      });
  }

  protected createOriginalDoc(jobDetail: JobDetailInterface): JobDocumentContent {
    let result: OriginalContent | undefined;
    const dealerInfo = {
      company_name: jobDetail.ownerCompanyName ?? 'Name MISSING',
      company_id: jobDetail.ownerCompanyId ?? 'Id MISSING',
      first_name: jobDetail.dealerContact?.first_name ?? 'First MISSING',
      last_name: jobDetail.dealerContact?.last_name ?? 'Last MISSING',
      email: jobDetail.dealerContact?.email ?? 'email@MISSING',
      phone_number: jobDetail.dealerContact?.phone_number ?? '+00 123 MISSING',
    };
    const clientInfo = {
      first_name: jobDetail.clientFirstName ?? 'First MISSING',
      last_name: jobDetail.clientLastName ?? 'Last MISSING',
      address: jobDetail.siteAddress ?? {},
      email: jobDetail.clientEmail,
      phone_number: jobDetail.clientEmail ?? 'First MISSING',
    };
    if (jobDetail.requestedServices === 'Consult & Install') {
      const consultDocContent: ConsultOriginalContent = {
        dealer_info: dealerInfo,
        client_info: clientInfo,
        requested_service: 'Consult & Install',
        detail_level: jobDetail.detailDepth!,
      };
      switch (jobDetail.detailDepth) {
        case 'products':
          consultDocContent.products = jobDetail.products;
          break;
        case 'draft':
          consultDocContent.draft_code = jobDetail.selectedDraft?.id;
          consultDocContent.revision_id = jobDetail.selectedDraft?.revision_id;
          consultDocContent.items = jobDetail.selectedDraft?.items;
          break;
        case 'estimate':
          consultDocContent.estimate_code = jobDetail.selectedEstimate?.id;
          consultDocContent.revision_id = jobDetail.selectedEstimate?.revision_id;
          consultDocContent.items = jobDetail.selectedEstimate?.items;
          break;
      }
      result = consultDocContent;
    } else if (jobDetail.requestedServices === 'Check Measure & Install') {
      result = {
        dealer_info: dealerInfo,
        client_info: clientInfo,
        requested_service: jobDetail.requestedServices,
        estimate_code: jobDetail.selectedEstimate!.id!,
        revision_id: jobDetail.selectedEstimate!.revision_id!,
        items: jobDetail.selectedEstimate!.items,
      } as CheckMeasureOriginalContent;
    }

    return result!;
  }

  protected createDcmDoc(jobDetail: JobDetailInterface): JobDocumentContent {
    const {productDefinitions} = this.state;
    const result: DcmContent = {
      revision_id: productDefinitions.length ? productDefinitions[0].revision_id : 0,
      items: [],
    };
    switch (jobDetail.requestedServices) {
      case 'Consult & Install':
        if (
          jobDetail.detailDepth === 'draft' &&
          jobDetail.selectedDraft?.items &&
          jobDetail.selectedDraft.revision_id
        ) {
          result.revision_id = jobDetail.selectedDraft.revision_id;
          result.items = jobDetail.selectedDraft.items;
        } else if (
          jobDetail.detailDepth === 'estimate' &&
          jobDetail.selectedEstimate?.items &&
          jobDetail.selectedEstimate.revision_id
        ) {
          result.revision_id = jobDetail.selectedEstimate.revision_id;
          result.items = jobDetail.selectedEstimate.items;
        }
        break;
      case 'Check Measure & Install':
        if (jobDetail.selectedEstimate?.items && jobDetail.selectedEstimate.revision_id) {
          result.revision_id = jobDetail.selectedEstimate.revision_id;
          result.items = jobDetail.selectedEstimate.items;
        }
        break;
    }
    //saving original indexes for comparison mode
    result.items = result.items.map(item => {
      item.original_index = item.index;
      return item;
    });
    return result;
  }

  onChange(jobDetail: JobDetailInterface) {
    this.setState({
      newJobDetail: jobDetail,
    });
  }

  onProductsChange(products: ConsultProduct[]) {
    const {newJobDetail} = this.state;
    this.onChange({...newJobDetail, products: products});
  }

  async setFitterOptions(fittingType?: FittingType) {
    let newFitterOptions: DropdownOption[] = [];
    if (fittingType === '3rd party') {
      newFitterOptions = this.state.fittingProviderCompanies?.map(company => {
        return {
          label: company.name,
          value: company.id as string,
        };
      });
    } else if (fittingType === 'internal') {
      newFitterOptions = this.state.fitterContacts.map(contact => {
        return {
          label: `${contact.first_name} ${contact.last_name}`,
          value: contact.id as string,
        };
      });
    }
    this.setState({
      fittingProviderOptions: newFitterOptions,
      selectedFittingType: fittingType,
    });
  }

  onInputFittingChange(fittingType?: FittingType) {
    this.setFitterOptions(fittingType);
  }

  onFittingProviderChange = (value: string) => {
    this.setState({
      selectedFittingProviderId: value,
    });
  };

  render() {
    const {showDialog} = this.props;
    const {
      loading,
      saving,
      newJobDetail,
      productDefinitions,
      estimates,
      drafts,
      contacts,
      doesQuotes,
      requestOnBehalf,
      requestForExisting,
      companyFittingTypes,
      selectedFittingType,
      selectedFittingProviderId,
      fittingProviderOptions,
    } = this.state;

    let dialogTitle = 'Request Fitting';
    if (newJobDetail.selectedEstimate) {
      dialogTitle += ` for ${newJobDetail.selectedEstimate.id} ${newJobDetail.selectedEstimate.reference}`;
    } else if (newJobDetail.selectedDraft) {
      dialogTitle += ` for ${newJobDetail.selectedDraft.id} ${newJobDetail.selectedDraft.reference}`;
    }

    return (
      <TwoDialog
        headerTitle={dialogTitle}
        showDialog={showDialog}
        onShow={this.onShow}
        onHide={this.onHide}
        loading={loading}
        onSave={this.onSave}
        saveButtonTitle="Request Job"
        saving={saving}
      >
        <div className="p-fluid w-100 p-mx-2">
          <NewJobDetailsComponent
            requestedOnBehalf={requestOnBehalf}
            contacts={contacts}
            requestForExisting={requestForExisting}
            jobDetails={newJobDetail}
            onChange={this.onChange}
            doesQuotes={doesQuotes}
            estimates={estimates}
            drafts={drafts}
            productDefinitions={productDefinitions}
            disableFields={saving}
          />
          <div className="p-field p-fluid p-grid p-mt-2">
            {companyFittingTypes.length > 1 && (
              <>
                <label htmlFor="fitting" className="p-col-1">
                  request fit
                </label>
                <div className="p-col-5">
                  <Dropdown
                    name="fitting_type"
                    value={selectedFittingType}
                    options={companyFittingTypes}
                    onChange={e => this.onInputFittingChange(e.value)}
                    disabled={saving ?? false}
                  />
                </div>
              </>
            )}
            {selectedFittingType && selectedFittingType !== 'made2fit' && (
              <>
                <label htmlFor="fitter" className="p-col-1">
                  {selectedFittingType === 'internal' ? 'fitter' : 'provider'}
                </label>
                <div className="p-col-5">
                  <Dropdown
                    name="fitter"
                    value={selectedFittingProviderId}
                    options={fittingProviderOptions}
                    onChange={e => this.onFittingProviderChange(e.value)}
                    disabled={saving ?? false}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </TwoDialog>
    );
  }
}
