import React from 'react';
import {AppContext} from 'two-app-ui';
import './InvoiceComponent.scss';

interface State {
  loading: boolean;
}
class InvoiceComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    return <div></div>;
  }
}
export default InvoiceComponent;
