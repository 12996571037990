import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Job, JobPatch, QueryParameter} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

class JobsService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async getJobs(params: QueryParameter): Promise<ApiListResponse> {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    return this.get(config(currentCompanyId).endpoints.jobs, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async createJob(companyId: string, data: Job): Promise<Job> {
    return this.post(`${config(companyId).endpoints.jobs}`, data)
      .then(data => {
        return Promise.resolve(data as Job);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateJob(jobId: string, data: JobPatch, companyId: string): Promise<Job> {
    return this.patch(`${config(companyId).endpoints.jobs}/${jobId}`, data)
      .then(data => {
        return Promise.resolve(data as Job);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default JobsService;
