import React from 'react';
import {Address, Company, InstallationInfo, Job, MapOf, User} from 'two-core';
import {DateTime, Interval} from 'luxon';
import {EntityDetailDivider, EntityDetailField} from 'two-app-ui';
import {JobAppointmentReference} from '../Reference/JobAppointmentReference/JobAppointmentReference';
import {NavLink} from 'react-router-dom';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import {Button} from 'primereact/button';

interface Props {
  job: Job;
  usersMap: MapOf<User>;
}

class JobDetail extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  onLinkClick = (url: string) => {
    window.open(`${url}`, '_empty');
  };

  renderAge(job: Job) {
    const now = DateTime.now();
    const lastActivityAt = job.last_tle?.recorded_at;
    const lastStageChangeAt = job.last_stage_change_at;
    let lastActivityInterval = 0;
    let lastStageChangeInterval = 0;
    if (lastActivityAt) {
      const lastActivityAtDate = DateTime.fromISO(lastActivityAt.toString());
      lastActivityInterval = Math.floor(Interval.fromDateTimes(lastActivityAtDate, now).length('days'));
    }
    if (lastStageChangeAt) {
      const lastStageChangeAtDate = DateTime.fromISO(lastStageChangeAt.toString());
      lastStageChangeInterval = Math.floor(Interval.fromDateTimes(lastStageChangeAtDate, now).length('days'));
    }
    return `${lastActivityInterval} / ${lastStageChangeInterval}`;
  }

  renderAddress(address: Address) {
    return `${address?.street ?? ''}, ${address?.suburb ?? ''}, ${address?.postCode ?? ''}, ${address?.state ?? ''}`;
  }

  renderArray(array: string[] | undefined) {
    if (array) {
      return array.join(', ');
    }
    return <></>;
  }

  renderTeamAndTime(info: InstallationInfo | undefined) {
    const resultArray: string[] = [];
    if (info) {
      if (info.time_required) {
        resultArray.push(info.time_required + ' hours');
      }
      if (info.fitters_required) {
        resultArray.push(info.fitters_required + ' fitter(s)');
      }
      if (info.offsiders_required) {
        resultArray.push(info.offsiders_required + ' off-sider(s)');
      }
    }
    return this.renderArray(resultArray);
  }

  renderFitter(currentCompanyId: string, job: Job) {
    if (job.fitting_provider_id !== currentCompanyId) {
      const fittingProvider = job.fitting_provider;
      return `${fittingProvider?.account_number} (${fittingProvider?.trading_as ?? fittingProvider?.name})`;
    }
    return job.lead_fitter?.label;
  }

  renderDealer(dealer: Company) {
    return (
      <div>
        <div className="p-mr-1 p-d-inline-flex">{dealer.name}</div>
        {dealer.trading_as && <div className="p-d-inline-flex">({dealer.trading_as})</div>}
      </div>
    );
  }

  render() {
    const {job} = this.props;
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';

    if (!job) {
      return <></>;
    }

    const endCustomer = job.end_customer;
    return (
      <>
        <EntityDetailField label="services">{job.requested_services}</EntityDetailField>
        <EntityDetailField label="stage">{job.stage}</EntityDetailField>
        {job.parent_job && (
          <EntityDetailField label="paren job">
            <NavLink to={'/job/' + job.parent_job.id}>{job.parent_job.title}</NavLink>
          </EntityDetailField>
        )}
        {job.order_id && <EntityDetailField label="order">{job.order_id}</EntityDetailField>}

        <EntityDetailDivider />

        <EntityDetailField label="appointments">
          {job.appointments?.map(appointment => {
            return (
              <JobAppointmentReference
                key={appointment.id}
                identifier={`${appointment.id}`}
                appointment={appointment}
              />
            );
          })}
        </EntityDetailField>
        <EntityDetailField label="fitter">{this.renderFitter(currentCompanyId, job)}</EntityDetailField>

        <EntityDetailDivider />

        <EntityDetailField label="end customer">{`${endCustomer.first_name ?? ''} ${
          endCustomer.last_name ?? ''
        }`}</EntityDetailField>
        {job.owner && <EntityDetailField label="dealer">{this.renderDealer(job.owner)}</EntityDetailField>}
        {job.other_contacts && (
          <EntityDetailField label="other contacts">
            {job.other_contacts?.map(contact => `${contact.first_name ?? ''} ${contact.last_name ?? ''}`)}
          </EntityDetailField>
        )}

        {job.installation_info && (
          <>
            <EntityDetailDivider />
            <EntityDetailField label="duration">
              {job.installation_info.time_required} {job.installation_info.time_required === 1 ? 'hour' : 'hours'}
            </EntityDetailField>
            <EntityDetailField label="#fitters">{job.installation_info.fitters_required}</EntityDetailField>
            {/*<EntityDetailField label="#helpers">{job.installation_info.offsiders_required}</EntityDetailField>*/}
            <EntityDetailField label="fixing">{job.installation_info.fixings_required?.join(', ')}</EntityDetailField>

            {!!job.installation_info.additional_items?.length && (
              <EntityDetailField label="tools">{job.installation_info.additional_items?.join(', ')}</EntityDetailField>
            )}
          </>
        )}

        {(job.folder ||
          job.external_refs?.base ||
          job.external_refs?.gdrive ||
          job.measured_by?.label ||
          job.installed_by?.label) && (
          <>
            <EntityDetailDivider />
            {job.folder && (
              <EntityDetailField label="folder">
                <Button
                  className="p-button-link p-button-info p-p-0"
                  label={job.folder}
                  onClick={() => this.onLinkClick(`https://drive.google.com/drive/folders/${job.folder}`)}
                />
              </EntityDetailField>
            )}
            {job.external_refs?.base && (
              <EntityDetailField label="base">
                <Button
                  className="p-button-link p-button-info p-p-0"
                  label={`${job.external_refs.base}`}
                  onClick={() =>
                    this.onLinkClick(`https://app.futuresimple.com/sales/deals/${job.external_refs!.base}`)
                  }
                />
              </EntityDetailField>
            )}
            {job.external_refs?.gdrive && (
              <EntityDetailField label="gdrive">
                <Button
                  className="p-button-link p-button-info p-p-0"
                  label={job.external_refs.gdrive}
                  onClick={() =>
                    this.onLinkClick(`https://drive.google.com/drive/folders/${job.external_refs!.gdrive}`)
                  }
                />
              </EntityDetailField>
            )}
            {job.measured_by?.label && (
              <EntityDetailField label="measured by">{job.measured_by.label}</EntityDetailField>
            )}
            {job.installed_by?.label && (
              <EntityDetailField label="installed by">{job.installed_by.label}</EntityDetailField>
            )}
          </>
        )}
      </>
    );
  }
}

export default JobDetail;
